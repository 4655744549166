<script setup>
import { useStore } from "vuex";
import { computed, onMounted, reactive, watch } from "vue";
import { user } from "../../../../api/auth.service";

const store = useStore();

const defaultCompanyLogo = require("../../../../assets/img/company/no-logo.png")
  .default;

const serviceData = reactive({
  isFavorite: false,
});

onMounted(() => {
  setTimeout(() => {
    loadedSlugCompany && companyDetails.value.companyGuid
      ? onCalculatedWidth()
      : "";
  }, 300);
});

const loadedSlugCompany = computed(() => {
  return store.state["customercompany"].loadedSlugCompany;
});
const isFavorite = computed(() => {
  return store.state["customercompany"].isFavorite;
});
const averageRating = computed(() => {
  return store.state["customercompany"].company.rating;
});
const userCookie = computed(() => {
  return user();
});
const companyDetails = computed(() => {
  return store.state["customercompany"].company;
});

watch(loadedSlugCompany, (newValue) => {
  if (newValue) {
    checkIsCompanyInFavorite(companyDetails.value.companyGuid);
    onCalculatedWidth();
  }
});

const checkIsCompanyInFavorite = async (companyGuid) => {
  serviceData.isFavorite =
    (
      await store.dispatch("customerfavorite/checkFavorite", {
        companyGuid: companyGuid,
      })
    ).length > 0;

  store.commit("customercompany/isFavorite", serviceData.isFavorite);
};
const onCalculatedWidth = () => {
  let starWidth = `${(averageRating.value / 5) * 100}%`;

  // let starContent = "\\f005 ".repeat(Math.round(averageRating.value));
  document.querySelector(".stars-inner").style.width = starWidth;
  document.querySelector(`.stars-outer .stars-inner`).innerHTML = averageRating;
};
const addFavorites = async () => {
  await store.dispatch("customerfavorite/updateFavorite", {
    id: 0,
    companyID: companyDetails.value.id,
  });
  checkIsCompanyInFavorite(companyDetails.value.companyGuid);
};
</script>

<template>
  <div class="col-2" v-if="companyDetails.logo">
    <div>
      <!-- <a href="javascript:void(0)" class="fav-  with-border"> -->
      <img
        class="logo-img"
        :src="
          companyDetails.logo == '' ? defaultCompanyLogo : companyDetails.logo
        "
        alt
        width="105"
        height="105"
      />
      <!-- </a> -->
    </div>
  </div>
  <!-- <div class="col-2 d-flex justify-content- ms- ps-0 pe-0">
    <div
      class="fav-btn fav-btn-big mt-"
      style="margin-left: 35px; margin-top: 10px;"
    >
      <a href="javascript:void(0)" class="fav-icon with-border">
        <img
          :src="companyDetails.logo ?? defaultCompanyLogo"
          alt
          width="110"
          height="110"
        />
      </a>
    </div>
  </div> -->

  <div class="col-10" :class="{ 'ps-0': companyDetails.logo }">
    <div class="d-flex align-items-center">
      <h1 class="color-2c3038 me-3" style="font-size: 26px;font-weight: 500;">
        {{ companyDetails.companyName }}
      </h1>
      <!-- <span class="color-2c3038 me-3" style="font-size: 28px;">{{
        companyDetails.companyName
      }}</span> -->
      <!-- <div class="fav-btn fav-btn-big">
              <a href="javascript:void(0)" class="fav-icon with-border">
                <i class="fas fa-heart"></i>
                <span class="material-icons-round">
                  verified
                </span>
              </a>
            </div> -->
      <img
        src="../../../../assets/img/ixirlocal/check-circle.svg"
        width="33"
        height="33"
        alt=""
        class="img-fluid"
      />
      <!-- <span
              class="material-icons-round color-light-blue"
              style="font-size: 35px;"
            >
              verified
            </span> -->
    </div>

    <div class="rating mapgridrating1 mt-2">
      <div>
        <!-- <i
                class="fas fa-star"
                v-for="index in 5"
                :class="{ filled: index <= companyDetails.rating }"
              ></i>
              <span
                class="d-inline-block average-rating"
                v-if="companyDetails.rating > 0"
              >
                ({{ companyDetails.rating }})
              </span> -->
        <div class="d-flex company-comment-text pb-0 pt-0">
          <div class="rating mapgridrating1">
            <i style="font-style: normal;">
              <div class="stars-outer">
                <div class="stars-inner" style="width: 100%;"></div>
              </div>
            </i>
          </div>
          <span> ({{ averageRating }})&nbsp;</span>
          <span class="company-header-comment">
            -
            <span class="ms-1 me-4 text-decoration-underline">
              {{ companyDetails.commentCount }} <span>Yorum</span>
            </span>
          </span>
        </div>
      </div>

      <div class="d-flex">
        <span
          @click="addFavorites"
          class="d-inline-flex align-items-center color-cc3370 cursor-pointer"
          v-if="userCookie"
        >
          <span class="text-decoration-underline">{{
            isFavorite ? "Favorilerden Kaldır" : "Favorilere Ekle"
          }}</span>
          <span
            class="material-icons-round ms-"
            :class="isFavorite ? 'color-red bg-red' : ''"
          >
            {{ isFavorite ? "favorite" : "favorite_border" }}
          </span>
        </span>
      </div>
    </div>

    <div class="service-cate inside-com mt-3">
      <!-- <router-link
        to="/search"
        class="company-cat-btn me-1"
        v-for="(category, index) in companyDetails.categoryList"
        :key="index"
      >
        {{ category }}
      </router-link> -->
      <div
        class="company-cat-btn me-1"
        v-for="(category, index) in companyDetails.categoryList"
        :key="index"
      >
        {{ category }}
      </div>
    </div>
  </div>
</template>
