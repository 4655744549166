<script setup>
import { useStore } from "vuex";
import { computed, defineAsyncComponent } from "vue";

import PopularServiceContent from "./popular-service/popular-service-content.vue";
const store = useStore();

const SlickCardSkeleton = defineAsyncComponent(() =>
  import("../../../../components/skeletons/slick-card-skeleton.vue")
);
// const PopularServiceContent = defineAsyncComponent(() =>
//   import("./popular-service/popular-service-content.vue")
// );

const loadedSC = computed(() => {
  return store.state["customercompany"].loadedSC;
});
</script>

<template>
  <div class="row similar-card-inside gx-0">
    <div class="col-12 similar-header">
      <span>Bölgedeki Diğer Salonlar</span>
    </div>

    <div v-if="loadedSC">
      <popular-service-content></popular-service-content>
    </div>

    <div v-else>
      <Suspense>
        <template #default>
          <slick-card-skeleton :count="2"></slick-card-skeleton>
        </template>
        <template #fallback>
          Loading...
        </template>
      </Suspense>

      <!-- <Suspense>
        <template #default>
          <popular-service-content></popular-service-content>
        </template>
        <template #fallback>
          Loading...
        </template>
      </Suspense> -->
    </div>
  </div>
</template>
