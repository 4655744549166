<script setup>
const slugify = require("slugify");
</script>

<template>
  <!-- Footer -->
  <footer class="footer">
    <!-- Footer Top -->
    <div class="footer-top aos" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Kurumsal</h2>
              <ul>
                <li>
                  <a
                    href="https://www.ixirlife.com/business/hakkimizda/"
                    target="blank"
                    class="text-white"
                    >Hakkımızda</a
                  >
                  <!-- <router-link to="/about-us">Hakkımızda</router-link> -->
                </li>
                <li>
                  <a
                    href="https://www.ixirlife.com/business/iletisim/"
                    target="blank"
                    class="text-white"
                    >Bize Ulaşın</a
                  >
                  <!-- <router-link to="/contact-us">Bize Ulaşın</router-link> -->
                </li>
                <li>
                  <a
                    href="https://www.ixirlife.com/business/sikca-sorulan-sorular/"
                    target="blank"
                    class="text-white"
                    >S.S.S</a
                  >
                  <!-- <router-link to="/faq">S.S.S</router-link> -->
                </li>
                <li>
                  <a
                    href="https://www.ixirlife.com/business/destek-merkezi/"
                    target="blank"
                    class="text-white"
                    >Yardım</a
                  >
                  <!-- <a href="javascript:void(0);">Yardım</a> -->
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <h2 class="footer-title">Kategoriler</h2>
            <div class="footer-widget footer-menu">
              <div class="row">
                <div class="footer-inside col-12 col-lg-auto">
                  <ol>
                    <li>
                      <router-link
                        :to="{
                          name: 'search-content',
                          params: {
                            search: slugify('Saç', {
                              lower: true,
                            }),
                          },
                        }"
                        >Saç</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{
                          name: 'search-content',
                          params: {
                            search: slugify('Yüz', {
                              lower: true,
                            }),
                          },
                        }"
                        >Yüz</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{
                          name: 'search-content',
                          params: {
                            search: slugify('Makyaj', {
                              lower: true,
                            }),
                          },
                        }"
                        >Makyaj</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{
                          name: 'search-content',
                          params: {
                            search: slugify('Tırnak', {
                              lower: true,
                            }),
                          },
                        }"
                        >Tırnak</router-link
                      >
                    </li>
                  </ol>
                </div>
                <div class="footer-inside col-12 col-lg-auto">
                  <ol>
                    <li>
                      <router-link
                        :to="{
                          name: 'search-content',
                          params: {
                            search: slugify('Lazer', {
                              lower: true,
                            }),
                          },
                        }"
                        >Lazer</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{
                          name: 'search-content',
                          params: {
                            search: slugify('Vücut', {
                              lower: true,
                            }),
                          },
                        }"
                        >Vücut</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{
                          name: 'search-content',
                          params: {
                            search: slugify('Kuaför', {
                              lower: true,
                            }),
                          },
                        }"
                        >Kuaför</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        :to="{
                          name: 'search-content',
                          params: {
                            search: slugify('Spa', {
                              lower: true,
                            }),
                          },
                        }"
                        >Spa</router-link
                      >
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-contact">
              <h2 class="footer-title">İletişim</h2>
              <div class="footer-contact-info">
                <p class="d-flex">
                  <!-- <i class="fas fa-headphones"></i> -->
                  <span class="material-icons-round ms-1">
                    call
                  </span>
                  <a href="tel:+902626447844" class="color-white"
                    >0262 644 78 44</a
                  >
                </p>
                <p class="d-flex">
                  <!-- <i class="fas fa-envelope"></i>  -->
                  <span class="material-icons-round ms-1">
                    email
                  </span>
                  <a href="mailto:info@ixirlife.com" class="color-white"
                    >info@ixirlife.com</a
                  >
                </p>
                <div class="footer-address">
                  <span class="d-flex align-items-center me-0">
                    <!-- <i class="far fa-building"></i> -->
                    <span class="material-icons-round color-white">
                      location_on
                    </span>
                  </span>
                  <p class="mb-0">
                    <a
                      href="https://goo.gl/maps/EWbFnhfNNc41jN9s7"
                      target="blank"
                      class="color-white cursor-pointer"
                      >Bilişim Vadisi Muallimköy Mh. Deniz Cad. No:143/5
                      Gebze/Kocaeli​</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <h2 class="footer-title">Bizi Takip Edin</h2>
              <div class="social-icon">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/guzellik.merkezi.yazilimi"
                      target="_blank"
                      ><i class="fab fa-facebook-f"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/ixirlifecom/"
                      target="_blank"
                      ><i class="fab fa-instagram"></i>
                    </a>
                  </li>

                  <li>
                    <a href="https://twitter.com/ixirlifeplus" target="_blank"
                      ><i class="fab fa-twitter"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.linkedin.com/company/ixirlife/"
                      target="_blank"
                      ><i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <!-- <li>
                    <a href="javascript:void(0);" target="_blank"
                      ><i class="fab fa-youtube"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" target="_blank"
                      ><i class="fab fa-google"></i
                    ></a>
                  </li> -->
                </ul>
              </div>

              <div style="margin-top: 16px;">
                <img
                  src="../../assets//img/home/iyzico-white.svg"
                  width="270"
                  alt="iyzico payment"
                />
              </div>

              <div class="subscribe-form">
                <input
                  type="email"
                  class="form-control"
                  placeholder="E-Posta girin"
                />
                <button type="submit" class="btn footer-btn">
                  <!-- <i class="fas fa-paper-plane"></i> -->
                  <span class="material-icons-outlined">
                    arrow_forward
                  </span>
                </button>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">
                  &copy; 2023 <router-link to="/">ixirlife.com</router-link>.
                  Tüm hakları saklıdır.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li>
                    <a
                      href="https://www.ixirlife.com/business/kullanim-kosullari-sozlesmemiz/"
                      target="blank"
                      class="text-white me-2"
                    >
                      Şartlar ve koşullar
                    </a>
                    <!-- <router-link to="/term-condition" class="me-2"
                      >Şartlar ve koşullar</router-link
                    > -->
                  </li>
                  <li>
                    <a
                      href="https://www.ixirlife.com/business/kvkk-aydinlatma-metnimiz/"
                      target="blank"
                      class="text-white"
                      >KVKK</a
                    >
                    <!-- <router-link to="/privacy-policy1">KVKK</router-link> -->
                  </li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>

<style scoped>
footer {
  left: 0%;
  right: 0%;
  bottom: 0%;
  position: relative;
}
</style>
