import Swal from "sweetalert2";
import { reactive } from "vue";
import { getCompanyComments } from "../../api/companycomment.service";

const defaultCompanyCommentState = () => {
  return {};
};

export const state = reactive({
  loaded: false,
  companyCommentOperationsModal: false,
  companyCommentSaved: false,
  companyComments: [],
  companyComment: defaultCompanyCommentState(),
});

export const actions = {
  async getCompanyComments({ commit }, data) {
    const responseObj = await getCompanyComments(data);

    commit("companyComments", responseObj);
    commit("loaded", responseObj.length >= 0);

    return responseObj;
  },
};

export const mutations = {
  loaded(state, data) {
    state.loaded = data;
  },
  companyCommentSaved(state, data) {
    state.companyCommentSaved = data;
  },
  companyCommentOperationsModal(state, data) {
    state.companyCommentOperationsModal = data;
  },
  companyComment(state, data) {
    state.companyComment = data;
  },
  companyComments(state, data) {
    state.companyComments = data;
  },
  resetCompany(state) {
    state.companyComment = defaultCompanyState();
  },
};
