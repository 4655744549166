import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getCustomerFavorites,
  checkFavorite,
  updateFavorite,
} from "../../api/customerfavorite.service";

const defaultState = () => {
  return {};
};

export const state = reactive({
  loadedFavorite: false,
  favoriteModal: false,
  favoriteSaved: false,
  favorite: [],
  favoriteCompanies: [],
});

export const actions = {
  async getCustomerFavorites({ commit }, data) {
    const responseObj = await getCustomerFavorites(data);
    const list = responseObj?.map((company) => {
      return {
        ...company,
        categoryList: company?.categoryString.split(","),
        mainImg: company?.pictureList?.find(
          (picture) => picture.mainPicture == true
        ),
      };
    });

    commit("favoriteCompanies", list);
    commit("loadedFavorite", responseObj.length >= 0);

    return responseObj;
  },

  async checkFavorite({ commit }, data) {
    const responseObj = await checkFavorite(data);

    const { success, message } = responseObj;

    // const { success, message } = response;

    // if (success) {
    //   commit("favoriteSaved", true);
    // }

    // Swal.fire({
    //   title: success ? "BİLGİ" : "UYARI",
    //   text: message,
    //   icon: success ? "success" : "warning",
    //   timer: success ? 2000 : null,
    // });

    return responseObj;
  },

  async updateFavorite({ commit }, data) {
    const responseObj = await updateFavorite(data);

    const { success, message } = responseObj;

    // const { success, message } = response;

    if (success) {
      commit("favoriteSaved", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      // timer: success ? 2000 : null,
      showCancelButton: false,
      confirmButtonText: "Tamam",
    });

    return responseObj;
  },
};

export const mutations = {
  loadedFavorite(state, data) {
    state.loadedFavorite = data;
  },
  favoriteSaved(state, data) {
    state.favoriteSaved = data;
  },
  favoriteModal(state, data) {
    state.favoriteModal = data;
  },
  favorite(state, data) {
    state.favorite = data;
  },
  favoriteCompanies(state, data) {
    state.favoriteCompanies = data;
  },
  resetCompanyProduct(state) {},
};
