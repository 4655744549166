const getTopOffset = (controlEl, offset) => {
  const labelOffset = offset;
  return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
};

const scrollToElement = (element, offset) => {
  window.scroll({
    top: getTopOffset(element, offset),
    left: 0,
    behavior: "smooth",
  });
};

export { scrollToElement };
