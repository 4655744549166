<template>

	<div class="main-wrapper">

	<layout-header></layout-header>
    
        <!-- Breadcrumb -->
		<breadcrumb1></breadcrumb1>
		<!-- /Breadcrumb -->
		
		<section class="about-us">
			<div class="content">
				<div class="container">
					<div class="row">
						<div class="col-6">
							<div class="about-blk-content">
								<h4>Experienced and Reliable</h4>
								<p>Nunc placerat mi id nisi interdum mollis. Praesent pharetra, justo ut scelerisque the mattis, leo quam aliquet congue placerat mi id nisi interdum mollis. Praesent pharetra, justo ut scel erisque the mattis, leo quam aliquet congue justo ut scelerisque. Praesent pharetra, justo ut scelerisque the mattis, leo quam aliquet congue justo ut scelerisque.</p>
								<p>Nunc placerat mi id nisi interdum mollis. Praesent pharetra, justo ut scelerisque the mattis, leo quam aliquet congue placerat mi id nisi interdum mollis. Praesent pharetra, justo ut scel erisque the mattis, leo quam aliquet congue justo ut scelerisque. Praesent pharetra, justo ut scelerisque the mattis, leo quam aliquet congue justo ut scelerisque.</p>
							</div>
						</div>
						<div class="col-6">
							<div class="about-blk-image">
								<img src="../../../../assets/img/about.jpg" class="img-fluid" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section class="call-us">
			<div class="container">
				<div class="row">
					<div class="col-6">
						<span>Free Consultation</span>
						<h2>Ready to start your dream project?</h2>
						<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod. Nunc placerat mi id nisi interdum mollis</p>
					</div>
					<div class="col-6 call-us-btn">
						<a href="javascript:void(0);" class="btn btn-call-us">Request A Free Consultation</a>
					</div>
				</div>
			</div>
		</section>
		
		<!-- How It Works -->
		<section class="how-work">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="heading howitworks">
							<h2>Reasons You Should Call Us</h2>
							<span>Our Advantages</span>
						</div>
						<work></work>
					</div>
				</div>
			</div>
		</section>
		<!-- /How It Works -->
	
	<layout-footer></layout-footer>

	</div>

</template>
<script>
	export default {
		mounted() {
		},
	}
</script>