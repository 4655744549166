<script setup>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { computed, onMounted, reactive } from "vue";
import { user } from "../../../api/auth.service";

const { t } = useI18n();
const store = useStore();

const defaultNoLogo = require("../../../assets/img/ixirlocal/no-logo.svg")
  .default;

onMounted(() => {});
const userCookie = computed(() => {
  return user();
});
const profileInfo = computed(() => {
  return store.state["customerprofile"].profileInformations;
});
</script>

<template>
  <div class="col-xl-3 col-md-4 theiaStickySidebar">
    <div class="card sidebar-card stickyside">
      <div class="mb-4">
        <div
          class="d-flex flex-row flex-wrap text-center text-sm-start align-items-center"
        >
          <img
            alt="profile image"
            :src="userCookie?.profilePicture ?? defaultNoLogo"
            class="avatar-lg rounded-circle"
          />
          <!-- <img
            alt="profile image"
            :src="profileInfo?.profilePicture ?? defaultNoLogo"
            class="avatar-lg rounded-circle"
          /> -->
          <div
            class="d-grid ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0"
          >
            <span class="mb-0">
              {{ userCookie.name }} {{ userCookie.surname }}
              <!-- {{ profileInfo.name }} {{ profileInfo.surname }} -->
            </span>
            <span class="text-muted mb-0">
              {{ userCookie.phone }}
              <!-- {{ profileInfo.phone }} -->
            </span>

            <span class="d-flex align-items-center text-muted mb-0">
              <span class="material-icons-round me-2" style="font-size: 16px;">
                location_on
              </span>
              {{ userCookie?.city }}
              <!-- {{ profileInfo.city }} -->
            </span>
          </div>
        </div>
        <hr />
      </div>
      <div class="widget settings-menu">
        <ul role="tablist" class="nav nav-tabs">
          <li class="nav-item current">
            <router-link
              to="/user-appointments"
              class="nav-link d-flex align-items-center"
            >
              <!-- <i class="fas fa-chart-line"></i> -->
              <span class="material-icons-round">
                insert_invitation
              </span>
              <span>{{ t("menu.myAppointments") }}</span>
            </router-link>
          </li>

          <!-- <li class="nav-item current">
            <router-link to="/user-dashboard" class="nav-link">
              <i class="fas fa-chart-line"></i> <span>Dashboard</span>
            </router-link>
          </li> -->

          <li class="nav-item current">
            <router-link
              to="/user-favourites"
              class="nav-link d-flex align-items-center"
            >
              <!-- <i class="fas fa-heart"></i> -->
              <span class="material-icons-round">
                favorite_border
              </span>
              <span>{{ t("menu.myFavourites") }}</span>
            </router-link>
          </li>

          <!-- <li class="nav-item current">
            <router-link to="/user-bookings" class="nav-link">
              <i class="far fa-calendar-check"></i> <span>My Bookings</span>
            </router-link>
          </li> -->

          <li class="nav-item">
            <router-link
              to="/user-comments"
              class="nav-link d-flex align-items-center"
            >
              <!-- <i class="far fa-user"></i>  -->
              <span class="material-icons-round">
                star_border
              </span>
              <span>{{ t("menu.myComments") }}</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              to="/user-installments"
              class="nav-link d-flex align-items-center"
            >
              <!-- <i class="far fa-user"></i>  -->
              <span class="material-icons-round">
                credit_card
              </span>
              <span>{{ t("menu.myInstallments") }}</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              to="/user-sessions"
              class="nav-link d-flex align-items-center"
            >
              <!-- <i class="far fa-user"></i>  -->
              <span class="material-icons-round">
                list_alt
              </span>
              <span>{{ t("menu.mySessions") }}</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              to="/profile-settings"
              class="nav-link d-flex align-items-center"
            >
              <!-- <i class="far fa-user"></i> -->
              <span class="material-icons-outlined">
                account_circle
              </span>
              <span>{{ t("menu.myProfileSettings") }}</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              to="/user-notifications"
              class="nav-link d-flex align-items-center"
            >
              <!-- <i class="far fa-user"></i> -->
              <span class="material-icons-outlined">
                notifications_none
              </span>
              <span>{{ t("menu.myNotifications") }}</span>
            </router-link>
          </li>

          <!-- <li class="nav-item">
            <router-link to="/user-wallet" class="nav-link">
              <i class="far fa-money-bill-alt"></i> <span>Wallet</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/user-reviews" class="nav-link">
              <i class="far fa-star"></i> <span>Reviews</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/user-payment" class="nav-link">
              <i class="fas fa-hashtag"></i> <span>Payment</span>
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
