import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getCustomerComments,
  getCustomerCommentsV2,
  getUnloggedCustomerCommentInfos,
  makeCommentWithGuid,
  commentDelete,
  commentSave,
} from "../../api/customercomment.service";

const defaultState = () => {
  return {};
};

export const state = reactive({
  loadedComment: false,
  loadedAnsweredComments: false,
  loadedUnansweredComments: false,
  loadedUnloggedCustomerComment: false,
  commentAddModal: false,
  commentRemoveModal: false,
  commentSaved: false,
  comment: [],
  unloggedCommentInfos: [],
  customerComments: [],
  answeredComments: [],
  unansweredComments: [],
});

export const actions = {
  async getCustomerComments({ commit }, data) {
    const responseObj = await getCustomerComments(data);

    commit("customerComments", responseObj);
    commit("loadedComment", responseObj.length >= 0);

    return responseObj;
  },

  async getCustomerCommentsV2({ commit }, data) {
    const responseObj = await getCustomerCommentsV2(data);

    commit("answeredComments", responseObj.answered);
    commit("unansweredComments", responseObj.waiting);
    commit("loadedAnsweredComments", responseObj.answered.length >= 0);
    commit("loadedUnansweredComments", responseObj.waiting.length >= 0);

    return responseObj;
  },

  async getUnloggedCustomerCommentInfos({ commit }, data) {
    const responseObj = await getUnloggedCustomerCommentInfos(data);

    commit("unloggedCommentInfos", responseObj);
    commit("loadedUnloggedCustomerComment", responseObj.length >= 0);

    return responseObj;
  },

  async makeCommentWithGuid({ commit }, data) {
    const responseObj = await makeCommentWithGuid(data);

    const { success, message } = responseObj;

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });
    return responseObj;
  },

  async commentSave({ commit }, data) {
    const responseObj = await commentSave(data);

    const { success, message } = responseObj;

    // const { success, message } = response;

    if (success) {
      commit("commentSaved", true);
      commit("commentAddModal", false);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },

  async commentDelete({ commit }, data) {
    const responseObj = await commentDelete(data);

    const { response } = responseObj;

    const { success, message } = response;

    if (success) {
      commit("commentSaved", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },
};

export const mutations = {
  loadedComment(state, data) {
    state.loadedComment = data;
  },
  loadedAnsweredComments(state, data) {
    state.loadedAnsweredComments = data;
  },
  loadedUnansweredComments(state, data) {
    state.loadedUnansweredComments = data;
  },
  loadedUnloggedCustomerComment(state, data) {
    state.loadedUnloggedCustomerComment = data;
  },
  commentSaved(state, data) {
    state.commentSaved = data;
  },
  commentAddModal(state, data) {
    state.commentAddModal = data;
  },
  commentRemoveModal(state, data) {
    state.commentRemoveModal = data;
  },
  comment(state, data) {
    state.comment = data;
  },
  unloggedCommentInfos(state, data) {
    state.unloggedCommentInfos = data;
  },
  customerComments(state, data) {
    state.customerComments = data;
  },
  answeredComments(state, data) {
    state.answeredComments = data;
  },
  unansweredComments(state, data) {
    state.unansweredComments = data;
  },
  resetCompanyProduct(state) {},
};
