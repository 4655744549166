<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted, reactive, watch } from "vue";
// import { user } from "../../../../api/auth.service";

import CompanyAbout from "./service-view-company-about.vue";
import CompanyComment from "./service-view-company-comment.vue";
import CompanyPersonnel from "./service-view-company-personnel.vue";
import CompanyService from "./service-view-company-service.vue";
import HeaderSkeleton from "./service-view-header-skeleton.vue";
import ServiceHeader from "./service-view-header.vue";

const store = useStore();
const router = useRouter();

const serviceData = reactive({
  isFavorite: false,
  showOnlyMobile: false,
});

onMounted(() => {
  serviceData.showOnlyMobile = window.screen.width < 1366;
  const $jq = jQuery.noConflict();
  if ($(".service-carousel-view").length > 0) {
    $jq(document).ready(function() {
      $jq(".service-carousel-view").slick({
        dots: true,
        autoplay: false,
        infinite: false,
        // prevArrow: false,
        // nextArrow: false,
        // arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  }

  onPageLoaded();
});

const loadedSlugCompany = computed(() => {
  return store.state["customercompany"].loadedSlugCompany;
});
const companyDetails = computed(() => {
  return store.state["customercompany"].company;
});

watch(loadedSlugCompany, (newValue) => {
  if (newValue) {
    checkIsCompanyInFavorite(companyDetails.value.companyGuid);
  }
});

const checkIsCompanyInFavorite = async (companyGuid) => {
  serviceData.isFavorite =
    (
      await store.dispatch("customerfavorite/checkFavorite", {
        companyGuid: companyGuid,
      })
    ).length > 0;

  store.commit("customercompany/isFavorite", serviceData.isFavorite);
};
// const averageRating = computed(() => {
//   return store.state["customercompany"].company.rating;
// });
// const userCookie = computed(() => {
//   return user();
// });
// const companyDetails = computed(() => {
//   return store.state["customercompany"].company;
// });

// const checkIsCompanyInFavorite = async (companyGuid) => {
//   serviceData.isFavorite =
//     (
//       await store.dispatch("customerfavorite/checkFavorite", {
//         companyGuid: companyGuid,
//       })
//     ).length > 0;
//   console.log(serviceData.isFavorite);
// };
// const onCalcultedWidth = () => {
//   let starWidth = `${(averageRating.value / 5) * 100}%`;

//   // let starContent = "\\f005 ".repeat(Math.round(averageRating.value));
//   const container = document.querySelector("#company-page");
//   console.log("container>> ", container);

//   document.querySelector(".stars-inner").style.width = starWidth;
//   document.querySelector(`.stars-outer .stars-inner`).innerHTML = averageRating;
// };
// const addFavorites = async () => {
//   await store.dispatch("customerfavorite/updateFavorite", {
//     id: 0,
//     companyID: companyDetails.value.id,
//   });
//   checkIsCompanyInFavorite(companyDetails.value.companyGuid);
// };
const onPageLoaded = async () => {
  const { guid, slug } = router.currentRoute.value.params;

  const model = {
    slug: slug,
    // companyGuid: guid,
  };
  if (slug !== "") {
    await store.dispatch("customercompany/getCustomerCompanyWithSlug", model);
    // await store.dispatch("customercompany/getCustomerCompanyWithGuid", model);
  }
};

// const fetchCompanyComments = async () => {
//   const { companyGuid } = store.state["customercompany"].company;

//   const model = {
//     companyGuid,
//   };

//   if (companyGuid !== "") {
//     store.dispatch("companycomment/getCompanyComments", model);
//   }
// };
</script>

<template>
  <div class="service-view">
    <div
      class="service-header"
      :style="!loadedSlugCompany ? 'margin-bottom: 12px' : ''"
    >
      <div class="row" v-if="!loadedSlugCompany">
        <header-skeleton></header-skeleton>
      </div>

      <div class="row" v-else>
        <service-header></service-header>
      </div>
    </div>

    <div>
      <vue-carousel></vue-carousel>
      <!-- <Suspense>
        <template #default>
          <vue-carousel></vue-carousel>
        </template>
        <template #fallback>
          loading...
        </template>
      </Suspense> -->
    </div>

    <div class="service-details mt-0">
      <company-about></company-about>
    </div>

    <div class="card">
      <div
        class="col-12 company-header"
        style="padding-left: 24px; padding-right: 24px;"
      >
        <span>Hizmetler</span>
      </div>
      <div :class="{ 'px-5': serviceData.showOnlyMobile }">
        <company-service></company-service>
      </div>
    </div>

    <div class="card">
      <company-personnel></company-personnel>
    </div>

    <div class="card">
      <company-comment></company-comment>
    </div>
  </div>
</template>
