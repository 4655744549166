<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const loadedSlugCompany = computed(() => {
  return store.state["customercompany"].loadedSlugCompany;
});
const companyDetails = computed(() => {
  return store.state["customercompany"].company;
});
</script>

<template>
  <div class="tab-content">
    <div
      class="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div class="card service-description">
        <div class="card-body">
          <span class="card-title company-header">Hakkında</span>
          <hr class="color-hr mt-2" />
          <div class="loading-skeleton border-0" v-if="!loadedSlugCompany">
            <p>Firma hakkında</p>
            <p>Firma hakkında</p>
          </div>
          <div v-else>
            <div v-if="!companyDetails.companyAbout">
              <span class="color-858585"
                >Firma hakkında bilgisi bulunmamaktadır.</span
              >
            </div>
            <p class="mb-0">
              {{ companyDetails.companyAbout }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
