import Cookie from "../../helpers/cookie";
import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  sendLoginRequest,
  sendSmsForUserLogin,
  sendSmsForUserLoginV2,
  sendQrForUserLogin,
  sendSmsForUpdatePhoneV2,
  validateSmsForUpdatePhoneV2,
  sendSmsForUserRegister,
  validateSmsForUserLogin,
  sendRegisterRequest,
} from "../../api/auth.service";

const defaultLoginUserState = () => {
  return {};
};

export const state = reactive({
  loaded: false,
  conditionsOfUseModal: false,
  isUserRegistered: false,
  isUserLoginPhoneValidated: false,
  isUserLoginSmsValidated: false,
  userLoginModal: false,
  userRegisterModal: false,
  userRegisterSaved: false,
  loginUserInformation: [],
});

export const actions = {
  async sendLoginRequest({ commit }, data) {
    const responseObj = await sendLoginRequest(data);

    const {
      response: { success, message },
    } = responseObj;

    // const { success, message } = response;

    if (success) {
      commit("", true);
    } else {
      Swal.fire({
        title: success ? "BİLGİ" : "UYARI",
        text: message,
        icon: success ? "success" : "warning",
        timer: success ? 2000 : null,
      });
    }

    return responseObj;
  },

  async sendSmsForUserLogin({ commit }, data) {
    const responseObj = await sendSmsForUserLogin(data);

    const { success, message } = responseObj;

    // const { success, message } = response;

    if (success) {
      commit("isUserLoginPhoneValidated", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },

  async sendSmsForUserLoginV2({ commit }, data) {
    const responseObj = await sendSmsForUserLoginV2(data);

    const { success, message, registered } = responseObj;

    // const { success, message } = response;

    // if (success) {
    commit("isUserLoginPhoneValidated", registered);
    // }
    !registered
      ? Swal.fire({
          title: success ? "BİLGİ" : "UYARI",
          text:
            "Girmiş olduğunuz  telefon sistemde kayıtlı değildir. Kayıt olmak için tıklayın.",
          icon: success ? "success" : "warning",
          // timer: success ? 2000 : null,
          cancelButtonText: "İptal",
          confirmButtonText: "Kayıt Ol",
        }).then((response) => {
          if (response.value) {
            commit("userLoginModal", false);
            commit("userRegisterModal", true);
          }
        })
      : Swal.fire({
          title: success ? "BİLGİ" : "UYARI",
          text: message,
          icon: success ? "success" : "warning",
          timer: success ? 2000 : null,
        });

    return responseObj;
  },

  async sendQrForUserLogin({ commit }, data) {
    const responseObj = await sendQrForUserLogin(data);

    const { success, message, registered, result } = responseObj;

    // const { success, message } = response;

    // if (success) {
    // }
    if (success) {
      commit("isUserLoginSmsValidated", true);
      commit("loginUserInformation", result);
      const {
        adress,
        birthday,
        city,
        cityID,
        email,
        genderID,
        id,
        name,
        notificationToken,
        phone,
        profilePicture,
        surname,
        token,
        townID,
      } = result;

      const identityObject = JSON.stringify({
        adress,
        birthday,
        city,
        cityID,
        email,
        genderID,
        id,
        name,
        notificationToken,
        phone,
        profilePicture,
        surname,
        token,
        townID,
      });

      Cookie.set("ixirlifeBireyselPanelUser", identityObject, { days: 30 });
    }

    return responseObj;
  },

  async sendSmsForUpdatePhoneV2({ commit }, data) {
    const responseObj = await sendSmsForUpdatePhoneV2(data);

    const { success, message, registered } = responseObj;

    // const { success, message } = response;

    // // if (success) {
    // commit("isUserLoginPhoneValidated", registered);
    // // }
    !success
      ? Swal.fire({
          title: success ? "BİLGİ" : "UYARI",
          text: message,
          icon: success ? "success" : "warning",
          cancelButtonText: "Tamam",
          // timer: success ? 2000 : null,
        })
      : "";

    return responseObj;
  },

  async validateSmsForUserLogin({ commit }, data) {
    try {
      const responseObj = await validateSmsForUserLogin(data);

      const { success, message, result } = responseObj;

      // const { success, message } = response;

      if (success) {
        commit("isUserLoginSmsValidated", true);
        commit("loginUserInformation", result);
        const {
          adress,
          birthday,
          city,
          cityID,
          email,
          genderID,
          id,
          name,
          notificationToken,
          phone,
          profilePicture,
          surname,
          token,
          townID,
        } = result;

        const identityObject = JSON.stringify({
          adress,
          birthday,
          city,
          cityID,
          email,
          genderID,
          id,
          name,
          notificationToken,
          phone,
          profilePicture,
          surname,
          token,
          townID,
        });

        Cookie.set("ixirlifeBireyselPanelUser", identityObject, { days: 30 });
      }

      if (!success) {
        Swal.fire({
          title: success ? "BİLGİ" : "UYARI",
          text: message,
          icon: success ? "success" : "warning",
          timer: success ? 2000 : 2000,
        });
      }

      return responseObj;
    } catch (error) {
      // const authError = "İstek gönderilirken bir hata oluştu";

      commit("loginFailure", error);
      // dispatch("notification/error", authError, { root: true });

      return { success: false, message: "İstek gönderilirken bir hata oluştu" };
    }
  },

  async validateSmsForUpdatePhoneV2({ commit }, data) {
    try {
      const responseObj = await validateSmsForUpdatePhoneV2(data);

      const { success, message, result } = responseObj;

      // const { success, message } = response;

      if (success) {
        commit("isUserLoginSmsValidated", true);
        commit("loginUserInformation", result);
        const {
          adress,
          birthday,
          city,
          cityID,
          email,
          genderID,
          id,
          name,
          notificationToken,
          phone,
          profilePicture,
          surname,
          token,
          townID,
        } = result;

        const identityObject = JSON.stringify({
          adress,
          birthday,
          city,
          cityID,
          email,
          genderID,
          id,
          name,
          notificationToken,
          phone,
          profilePicture,
          surname,
          token,
          townID,
        });

        Cookie.set("ixirlifeBireyselPanelUser", identityObject, { days: 30 });
      }

      if (!success) {
        Swal.fire({
          title: success ? "BİLGİ" : "UYARI",
          text: message,
          icon: success ? "success" : "warning",
          timer: success ? 2000 : 2000,
        });
      }

      return responseObj;
    } catch (error) {
      // const authError = "İstek gönderilirken bir hata oluştu";

      commit("loginFailure", error);
      // dispatch("notification/error", authError, { root: true });

      return { success: false, message: "İstek gönderilirken bir hata oluştu" };
    }
  },

  async sendSmsForUserRegister({ commit }, data) {
    const responseObj = await sendSmsForUserRegister(data);

    const { success, message, registered } = responseObj;

    // const { success, message } = response;

    if (success) {
      commit("isUserLoginPhoneValidated", success);
    }
    if (registered) {
      commit("userRegisterModal", false);
    }

    Swal.fire({
      title: registered ? "UYARI" : "BİLGİ",
      text: message,
      icon: registered ? "success" : "success",
      timer: registered ? 5000 : 5000,
      // showCancelButton: true,
      // confirmButtonText: "Tamam",
      // cancelButtonText: "İptal",
    });

    return responseObj;
  },

  async sendRegisterRequest({ commit }, data) {
    const responseObj = await sendRegisterRequest(data);

    const { success, message, registered } = responseObj;

    // const { success, message } = response;

    if (success) {
      commit("isUserRegistered", success);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2500 : 2500,
    });

    return responseObj;
  },
};

export const getters = {
  loggedIn: (state) => state.isUserLoginSmsValidated,
};

export const mutations = {
  loaded(state, data) {
    state.loaded = data;
  },
  conditionsOfUseModal(state, data) {
    state.conditionsOfUseModal = data;
  },
  isUserRegistered(state, data) {
    state.isUserRegistered = data;
  },
  isUserLoginPhoneValidated(state, data) {
    state.isUserLoginPhoneValidated = data;
  },
  isUserLoginSmsValidated(state, data) {
    state.isUserLoginSmsValidated = data;
  },
  userRegisterSaved(state, data) {
    state.userRegisterSaved = data;
  },
  userLoginModal(state, data) {
    state.userLoginModal = data;
  },
  userRegisterModal(state, data) {
    state.userRegisterModal = data;
  },
  loginUserInformation(state, data) {
    state.loginUserInformation = data;
  },
  resetAppointmentInformation(state) {
    state.appointmentInformation = defaultInformationState();
  },
  resetModal(state) {
    state.isUserRegistered = false;
    state.isUserLoginPhoneValidated = false;
    state.isUserLoginSmsValidated = false;
  },
};
