import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getAllCategories,
  getCompanyCategories,
  getCompanySubCategories,
  getPopularCategories,
} from "../../api/companycategory.service";

const defaultCompanyCategoryState = () => {
  return {};
};
const defaultCompanySubCategoryState = () => {
  return {};
};

/**
 * @param loadedAC => loadedAllCagetory
 * @param loadedSC => loadedSubCagetory
 * @param loadedPC => loadedPopularCagetory
 */
export const state = reactive({
  loaded: false,
  loadedAC: false,
  loadedPC: false,
  loadedSC: false,
  companyCategoryOperationsModal: false,
  companyCategorySaved: false,
  allCategories: [],
  companyCategories: [],
  companySubCategories: [],
  popularCategories: [],
  companyCategory: defaultCompanyCategoryState(),
  companySubCategory: defaultCompanySubCategoryState(),
});

export const actions = {
  async getAllCategories({ commit }, data) {
    const responseObj = await getAllCategories(data);

    commit("allCategories", responseObj);
    commit("loadedAC", responseObj.length >= 0);

    return responseObj;
  },

  async getCompanyCategories({ commit }, data) {
    const responseObj = await getCompanyCategories(data);

    commit("companyCategories", responseObj);
    commit("loaded", responseObj.length >= 0);

    return responseObj;
  },

  async getPopularCategories({ commit }, data) {
    const responseObj = await getPopularCategories(data);

    commit("popularCategories", responseObj);
    commit("loadedPC", responseObj.length >= 0);

    return responseObj;
  },

  async getCompanySubCategories({ commit }, data) {
    const responseObj = await getCompanySubCategories(data);

    commit("companySubCategories", responseObj);
    commit("loadedSC", responseObj.length >= 0);

    return responseObj;
  },
};

export const mutations = {
  loaded(state, data) {
    state.loaded = data;
  },
  loadedAC(state, data) {
    state.loadedAC = data;
  },
  loadedPC(state, data) {
    state.loadedPC = data;
  },
  loadedSC(state, data) {
    state.loadedSC = data;
  },
  companyCategorySaved(state, data) {
    state.companyCategorySaved = data;
  },
  companyCategoryOperationsModal(state, data) {
    state.companyCategoryOperationsModal = data;
  },
  companyCategory(state, data) {
    state.companyCategory = data;
  },
  companySubCategory(state, data) {
    state.companySubCategory = data;
  },
  allCategories(state, data) {
    state.allCategories = data;
  },
  companyCategories(state, data) {
    state.companyCategories = data;
  },
  companySubCategories(state, data) {
    state.companySubCategories = data;
  },
  popularCategories(state, data) {
    state.popularCategories = data;
  },
  resetCompany(state) {
    state.companyCategory = defaultCompanyState();
  },
};
