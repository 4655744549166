import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getCompanyMainProducts,
  getCompanySubProducts,
} from "../../api/companyproduct.service";

const defaultCompanyProductState = () => {
  return {};
};
const defaultCompanySubProductState = () => {
  return {};
};

/**
 * @param loadedSP => loadedSubProduct
 */
export const state = reactive({
  loadedMainProduct: false,
  loadedSP: false,
  companyMainProductOperationsModal: false,
  companyMainProductSaved: false,
  companyMainProducts: [],
  companySubProducts: [],
  companyMainProduct: defaultCompanyProductState(),
  companySubProduct: defaultCompanySubProductState(),
});

export const actions = {
  async getCompanyMainProducts({ commit }, data) {
    const responseObj = await getCompanyMainProducts(data);

    commit("companyMainProducts", responseObj);
    commit("loadedMainProduct", responseObj.length >= 0);

    return responseObj;
  },

  async getCompanySubProducts({ commit }, data) {
    const responseObj = await getCompanySubProducts(data);

    commit("companySubProducts", responseObj);
    commit("loadedSP", responseObj.length >= 0);

    return responseObj;
  },
};

export const mutations = {
  loadedMainProduct(state, data) {
    state.loadedMainProduct = data;
  },
  loadedSP(state, data) {
    state.loadedSP = data;
  },
  companyMainProductSaved(state, data) {
    state.companyMainProductSaved = data;
  },
  companyMainProductOperationsModal(state, data) {
    state.companyMainProductOperationsModal = data;
  },
  companyMainProduct(state, data) {
    state.companyMainProduct = data;
  },
  companySubProduct(state, data) {
    state.companySubProduct = data;
  },
  companyMainProducts(state, data) {
    state.companyMainProducts = data;
  },
  companySubProducts(state, data) {
    state.companySubProducts = data;
  },
  resetCompanyProduct(state) {
    state.companyMainProduct = defaultCompanyProductState();
    state.companySubProduct = defaultCompanySubProductState();
  },
};
