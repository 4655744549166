import { baseUrl, tokenString } from "./base.js";
import { serialize } from "../helpers/formatters.js";

const getCustomerFavorites = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-favourite`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: tokenString(),
    },
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const checkFavorite = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/randevu/customer-favourite-company?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const updateFavorite = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-favourite`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

export { getCustomerFavorites, checkFavorite, updateFavorite };
