<script setup>
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import PopularCategorySkeleton from "../../../../components/skeletons/popular-category-skeleton.vue";

const store = useStore();
const slugify = require("slugify");

const defaultNoImgWebp =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/NoImage.webp";

const loadedPC = computed(() => {
  return store.state["companycategory"].loadedPC;
});
const popularCategorylist = computed(() => {
  return store.state["companycategory"].popularCategories;
});
</script>

<template>
  <!-- Category Section -->
  <section class="category-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-md-6">
              <div class="heading aos" data-aos="fade-up">
                <h2>Popüler Kategoriler</h2>
                <span>Hangi tür hizmet arıyorsunuz?</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="viewall aos" data-aos="fade-up">
                <h4>
                  <router-link to="/categories"
                    >Tümü <i class="fas fa-angle-right"></i
                  ></router-link>
                </h4>
                <!-- <span>Kategoriler</span> -->
              </div>
            </div>
          </div>

          <div v-if="!loadedPC">
            <popular-category-skeleton></popular-category-skeleton>
          </div>
          <div class="catsec" v-else>
            <div class="row">
              <div
                class="col-lg-4 col-md-6"
                v-for="popularCategory in popularCategorylist"
                :key="popularCategory.id"
              >
                <router-link
                  :to="{
                    name: 'optional-search',
                    params: {
                      category: slugify(popularCategory.title, { lower: true }),
                      categoryID: popularCategory.id,
                    },
                  }"
                >
                  <div class="cate-widget aos" data-aos="fade-up">
                    <img
                      :src="popularCategory?.picture ?? defaultNoImgWebp"
                      alt=""
                    />
                    <div class="cate-title px-3 py-2">
                      <h3>
                        <span class="d-flex align-items-center">
                          <!-- <i class="fas fa-circle"></i> -->
                          <span class="material-icons-outlined p-0 me-2">
                            spa
                          </span>
                          {{ popularCategory.title }}</span
                        >
                      </h3>
                    </div>

                    <!-- <div class="cate-count"> 
                      {{ popularCategory.companyCount }} 
                    </div> -->
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Category Section -->
</template>
