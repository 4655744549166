<template>
  <div class="main-wrapper">
    <layout-headercustomer></layout-headercustomer>

    <div class="content">
      <div class="container">
        <div class="row">
          <customersidebar></customersidebar>
          <div class="col-xl-9 col-md-8">
            <div class="row align-items-center mb-4">
              <div class="col">
                <h4 class="widget-title mb-0">My Bookings</h4>
              </div>
              <div class="col-auto">
                <div class="sort-by">
                  <select class="form-control-sm custom-select">
                    <option>All</option>
                    <option>Pending</option>
                    <option>Inprogress</option>
                    <option>Complete Request</option>
                    <option>Rejected</option>
                    <option>Cancelled</option>
                    <option>Completed</option>
                  </select>
                </div>
              </div>
            </div>

            <bookinglist></bookinglist>
            <div class="pagination">
              <ul>
                <li class="active me-1">
                  <a href="javascript:void(0);">1</a>
                </li>
                <li class="me-1">
                  <a href="javascript:void(0);">2</a>
                </li>
                <li class="me-1">
                  <a href="javascript:void(0);">3</a>
                </li>
                <li class="me-1">
                  <a href="javascript:void(0);">4</a>
                </li>
                <li class="arrow">
                  <a href="javascript:void(0);"
                    ><i class="fas fa-angle-right"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <layout-footer></layout-footer>
  </div>
</template>
