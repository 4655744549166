<script setup>
import { useStore } from "vuex";
import { computed, onMounted, onUnmounted, reactive, watch } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";

const emits = defineEmits(["remove:invalid-class"]);
const store = useStore();
const router = useRouter();

const serviceModel = reactive({
  showOnlyWeb: false,
  mainCategoryID: 0,
  subCategoryID: 0,
});
const selectedCategory = reactive({
  mainCategory: {},
  subCategory: {},
  mainCategoryID: -1,
  subCategoryID: -1,
});

// onBeforeRouteLeave((to, from, next) => {
//   store.commit("companyproduct/companyMainProducts", []);
//   store.commit("companyproduct/companySubProducts", []);
//   next();
// });
onMounted(() => {
  store.commit("companyproduct/companyMainProducts", []);
  store.commit("companyproduct/companySubProducts", []);

  window.screen.width < 1366
    ? (serviceModel.showOnlyWeb = false)
    : (serviceModel.showOnlyWeb = true);

  selectedCategory.mainCategoryID =
    appointmentModel.value.mainProductID || mainProductList.value[0]?.id || -1;

  selectedCategory.subCategoryID = appointmentModel.value.productID || -1;
  // loadedSlugCompany
  // ?
  // setTimeout(() => {
  loadedSlugCompany && companyInfo.value.companyGuid
    ? fetchCompanyMainProducts()
    : "";
  // }, 400)
  // : "";
});
onUnmounted(() => {
  serviceModel.mainCategoryID = -1;
  selectedCategory.mainCategoryID = -1;
  appointmentModel.value.mainProductID = null;
});

const loadedCompany = computed(() => {
  return store.state["customercompany"].loadedCompany;
});
const loadedSlugCompany = computed(() => {
  return store.state["customercompany"].loadedSlugCompany;
});
const companyInfo = computed(() => {
  return store.state["customercompany"].company;
});
const totalMainProducts = computed(() => {
  return store.state["companyproduct"].companyMainProducts.length || 0;
});
const mainProductList = computed(() => {
  return store.state["companyproduct"].companyMainProducts;
});
const subProductList = computed(() => {
  return store.state["companyproduct"].companySubProducts;
});
const appointmentModel = computed(() => {
  return store.state["appointment"].appointmentInformation;
});

watch(loadedCompany, (newVal) => {
  newVal ? fetchCompanyMainProducts() : "";
});
watch(loadedSlugCompany, (newVal) => {
  newVal ? fetchCompanyMainProducts() : "";
});

const routeWithSelectedProduct = (subProduct) => {
  selectedCategory.subCategoryID = subProduct.id;
  store.commit("appointment/setSubService", subProduct);

  // const { slug } = router.currentRoute.value.params;
  // router.push({ name: "appointment-view-slug", params: { slug } });

  const { companyGuid } = companyInfo.value;
  router.push({ name: "appointment-view-guid", params: { guid: companyGuid } });
};
const onMainProductSelect = (mainProduct) => {
  selectedCategory.mainCategoryID = mainProduct.id;

  store.commit("appointment/setMainService", mainProduct);
  fetchCompanySubProducts(mainProduct.mainProductGuid);
};
const onSubProductSelect = (subProduct) => {
  selectedCategory.subCategoryID = subProduct.id;
  emits("remove:invalid-class");

  store.commit("appointment/setSubService", subProduct);
};
const fetchCompanyMainProducts = async () => {
  const response = await store.dispatch(
    "companyproduct/getCompanyMainProducts",
    {
      companyID: companyInfo.value.id,
    }
  );

  if (router.currentRoute.value.name == "service-details-slug") {
    serviceModel.mainCategoryID = mainProductList.value[0]?.id || -1;
    selectedCategory.mainCategoryID = mainProductList.value[0]?.id || -1;
  }

  if (router.currentRoute.value.name == "appointment-view-guid") {
    serviceModel.mainCategoryID =
      appointmentModel.value.mainProductID || mainProductList.value[0]?.id;
    selectedCategory.mainCategoryID =
      appointmentModel.value.mainProductID || mainProductList.value[0]?.id;
  }

  response.length > 0
    ? fetchCompanySubProducts(
        response.filter?.(
          (product) => product.id == serviceModel.mainCategoryID
        )[0].mainProductGuid
      )
    : "";
};
const fetchCompanySubProducts = async (guid) => {
  await store.dispatch("companyproduct/getCompanySubProducts", {
    mainProductGuid: guid,
  });
};
const selectCompanySubCategory = (event) => {
  const subProduct = subProductList.value?.filter(
    (subProduct) => subProduct.id == event.target.value
  )[0];

  store.commit("appointment/informationSubProduct", subProduct);
};
const fetchCompanySubCategories = async (event) => {
  const mainProduct = mainProductList.value?.filter(
    (mainProduct) => mainProduct.id == event.target.value
  )[0];
  serviceModel.subCategoryID = 0;

  await store.dispatch("companyproduct/getCompanySubProducts", {
    mainProductGuid: mainProduct.mainProductGuid,
  });
};
</script>

<template>
  <div class="row company-card-inside gx-0" v-if="serviceModel.showOnlyWeb">
    <div v-if="totalMainProducts == 0">
      <!-- <hr class="color-hr mt-0" /> -->
      <span class="ms-4 color-858585"
        >Firmanın hizmet bilgisi bulunmamaktadır.
      </span>
    </div>
    <div v-else class="d-flex">
      <div class="col-4 custom-scroll">
        <div class="list-group pe-2">
          <div
            class="list-group-item border-0"
            @click="onMainProductSelect(mainProduct)"
            v-for="(mainProduct, index) in mainProductList"
            :key="index"
            :class="
              mainProduct.id != selectedCategory.mainCategoryID
                ? 'unselected-list-item'
                : 'selected-list-item'
            "
          >
            {{ mainProduct.mainProductTitle }}
          </div>
        </div>
      </div>
      <div class="col-8 sub-category custom-scroll">
        <div
          class="pe-2"
          v-for="(subProduct, index) in subProductList"
          :key="index"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <span>{{ subProduct?.productTitle }}</span>
              <!-- <span>{{ subProduct?.productTitle.toLocaleUpperCase("TR") }}</span> -->
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center me-4">
                <span
                  class="material-icons-round"
                  v-if="subProduct.implementationTime ?? ''"
                >
                  access_time
                </span>
                <span>{{ subProduct.implementationTime }}</span>
              </div>
              <div class="app-btn-position">
                <a
                  href="javascript:void(0);"
                  class="sub-cat- btn"
                  v-if="
                    router.currentRoute.value.name == 'service-details-slug'
                  "
                  @click.prevent="routeWithSelectedProduct(subProduct)"
                >
                  Randevu Al
                  <!-- <span class="sub-cat-text">Randevu Al</span> -->
                </a>
                <!-- <button
                class="sub-cat-btn"
                v-if="router.currentRoute.value.name == 'service-details-guid'"
                @click="routeWithSelectedProduct(subProduct)"
              >
                <span class="sub-cat-text">Randevu Al</span>
              </button> -->
                <!-- <button
                v-if="router.currentRoute.value.name == 'appointment-view-guid'"
                class=""
                :class="
                  selectedCategory.subCategoryID == subProduct.id
                    ? 'selected-sub-cat'
                    : 'unselected-sub-cat'
                "
                @click="onSubProductSelect(subProduct)"
              >
                <span class="app-text">Seçim</span>
              </button> -->
                <button
                  v-if="
                    router.currentRoute.value.name == 'appointment-view-guid'
                  "
                  data-bs-toggle=""
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  :class="
                    selectedCategory.subCategoryID == subProduct.id
                      ? 'selected-sub-cat'
                      : 'unselected-sub-cat'
                  "
                  @click="onSubProductSelect(subProduct)"
                >
                  <span class="app-text">Seçim</span>
                </button>
              </div>
            </div>
          </div>
          <hr class="mt-2 mb-2" />
        </div>
      </div>
    </div>
  </div>

  <div
    class="row company-card-inside gx-0 px-0"
    style="background: transparent;"
    v-else
  >
    <div class="filter-widget" style="background: transparent;">
      <div class="filter-list">
        <label class="filter-title"
          >Ana Hizmetler
          <span class="me-1" style="color: #DC3545;">*</span></label
        >
        <select
          class="form-control selectbox select form-select"
          v-model="serviceModel.mainCategoryID"
          @change="fetchCompanySubCategories"
        >
          <!-- <template #first> -->
          <option :value="0" style="color: rgba(177, 187, 196, 1)">{{
            serviceModel.mainCategoryID == 0
              ? "Ana Hizmet Seçin"
              : "Ana Hizmet Değiştirin"
          }}</option>
          <!-- </template> -->
          <option
            v-for="mainProduct in mainProductList"
            :value="mainProduct.id"
            >{{ mainProduct.mainProductTitle }}</option
          >
        </select>
      </div>

      <div class="filter-list">
        <label class="filter-title"
          >Alt Hizmetler
          <span class="me-1" style="color: #DC3545;">*</span></label
        >
        <select
          class="form-control selectbox select form-select"
          v-model="serviceModel.subCategoryID"
          @change="selectCompanySubCategory"
        >
          <option :value="0" style="color: rgba(177, 187, 196, 1)">{{
            serviceModel.subCategoryID == 0
              ? "Alt Hizmet Seçin"
              : "Alt Hizmet Değiştirin"
          }}</option>
          <option v-for="subProduct in subProductList" :value="subProduct.id">{{
            subProduct.productTitle
          }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<style scoped>
list-group {
  background: #fff;
}
</style>
