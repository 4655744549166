import Swal from "sweetalert2";
import { reactive } from "vue";
import { checkIxirlifeBannerStatus } from "../../api/cdn.service";

export const state = reactive({
  ixirlifeBannerInfos: [],
  advertBannerInfos: [],
});

export const actions = {
  async checkIxirlifeBannerStatus({ commit }, data) {
    const responseObj = await checkIxirlifeBannerStatus(data);

    if (responseObj.length > 0) {
      switch (responseObj[0].type) {
        case 1:
          commit("ixirlifeBannerInfos", responseObj[0]);
          break;
        case 2:
        case 3:
          commit("advertBannerInfos", responseObj[0]);
          break;

        default:
          break;
      }
    }

    return responseObj;
  },
};

export const mutations = {
  ixirlifeBannerInfos(state, data) {
    state.ixirlifeBannerInfos = data;
  },
  advertBannerInfos(state, data) {
    state.advertBannerInfos = data;
  },
  resetBanners(state) {
    state.ixirlifeBannerInfos = false;
  },
  reseAdvertBanner(state) {
    state.advertBannerInfos = [];
  },
};
