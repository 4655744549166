import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getCustomerInstallments,
  installmentDelete,
  installmentSave,
} from "../../api/customerinstallment.service";

const defaultState = () => {
  return {};
};

export const state = reactive({
  loadedInstallments: false,
  installmentSaved: false,
  installment: [],
  customerInstallments: [],
});

export const actions = {
  async getCustomerInstallments({ commit }, data) {
    commit("loadedInstallments", false);

    const responseObj = await getCustomerInstallments(data);

    commit("customerInstallments", responseObj.data);
    commit("loadedInstallments", responseObj.data.length >= 0);

    return responseObj;
  },

  async installmentSave({ commit }, data) {
    const responseObj = await installmentSave(data);

    const { success, message } = responseObj;

    if (success) {
      commit("installmentSaved", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },

  async installmentDelete({ commit }, data) {
    const responseObj = await installmentDelete(data);

    const { response } = responseObj;

    const { success, message } = response;

    if (success) {
      commit("installmentSaved", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },
};

export const mutations = {
  loadedInstallments(state, data) {
    state.loadedInstallments = data;
  },
  installmentSaved(state, data) {
    state.installmentSaved = data;
  },
  installment(state, data) {
    state.installment = data;
  },
  customerInstallments(state, data) {
    state.customerInstallments = data;
  },
  resetCompanyProduct(state) {},
};
