<script setup>
import { useStore } from "vuex";
import { computed, onMounted, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const baseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:8082"
    : "https://ixirlife.com";
const defaultNoImgWebp =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/NoImage.webp";

onMounted(() => {
  const $jq = jQuery.noConflict();
  // if (totalSimilar > 0) {

  setTimeout(() => {
    if ($jq(".popular-sliderservice").length > 0) {
      $jq(document).ready(function() {
        $jq(".popular-sliderservice").slick({
          dots: true,
          autoplay: false,
          infinite: false,
          prevArrow: false,
          nextArrow: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
      });
    }
  }, 1500);
  // setTimeout(() => {
  //   loadedSC ? onCalculatedWidth() : "";
  // }, 300);
});

const totalSimilar = computed(() => {
  return store.state["customercompany"].similarCompanies.length || 0;
});
const loadedSC = computed(() => {
  return store.state["customercompany"].loadedSC;
});
const similarCompanylist = computed(() => {
  const list = store.state["customercompany"].similarCompanies;

  return list;
});

watch(loadedSC, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      onCalculatedWidth();
    }, 1500);
  }
});

watchEffect(() => {
  if (loadedSC) {
    setTimeout(() => {
      onCalculatedWidth();
    }, 1500);
  }
});
const onChangeCompany = (similarCompany) => {
  store.commit("customercompany/company", similarCompany);
  router.push({
    name: "service-details-slug",
    params: { slug: similarCompany.slug },
  });
  // window.location.reload();
};
const onCalculatedWidth = () => {
  let starWidth = [];

  starWidth = similarCompanylist.value.map((rating) => {
    return {
      width: `${(rating.rating / 5) * 100}%`,
    };
  });

  const container = document.querySelector(".popular-sliderservice");

  const starInners = container.querySelectorAll(".stars-inner");
  const starOuters = container.querySelectorAll(".stars-outer .stars-inner");

  starInners.forEach((inner, index) => {
    inner.style.width = starWidth[index].width;
  });
  starOuters.forEach((outer, index) => {
    outer.innerHTML = similarCompanylist.value[index].rating;
  });
};
</script>

<template>
  <div class="service-carousel2 popular-sliderservice slider">
    <div
      class="service-widget"
      v-for="similarCompany in similarCompanylist"
      :key="`sc-${similarCompany.id}`"
    >
      <div class="service-img">
        <div>
          <a
            :href="`${baseUrl}/company/${similarCompany.slug}`"
            class="cursor-pointer"
          >
            <img
              class="img-fluid serv-img"
              alt="Service Image"
              :src="similarCompany.mainImg?.picture ?? defaultNoImgWebp"
            />
          </a>
        </div>

        <div class="loc-btn">
          <div class="loc-icon px-2">
            <span
              class="material-icons-outlined color-white pe-2"
              style="font-size: 20px;"
            >
              place
            </span>
            <span class="pe-1" style="font-size: 16px;">
              {{ similarCompany.cityName }}
            </span>
          </div>
        </div>

        <div class="item-info">
          <div class="cate-list">
            <div
              class="bg-light-blue me-2"
              v-for="(category, index) in similarCompany.categoryList"
              :key="index"
            >
              {{ category }}
            </div>
          </div>
        </div>
      </div>

      <div class="service-content">
        <h3 class="title">
          <!-- <router-link
            :to="{
              name: 'service-details-slug',
              params: { slug: similarCompany.slug },
            }"
          >
            {{ similarCompany.companyName }}
          </router-link> -->

          <a
            :href="`${baseUrl}/company/${similarCompany.slug}`"
            class="cursor-pointer"
          >
            {{ similarCompany.companyName }}
          </a>
        </h3>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex company-comment-text pb-0 pt-0 me-4">
            <div class="rating mapgridrating1">
              <i style="font-style: normal;" class="me-2">
                <div class="stars-outer">
                  <div class="stars-inner" style="width: 100%;"></div>
                </div>
              </i>
            </div>
            <span>{{ similarCompany.rating }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
