import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
/***********Frontend ***************/
const UnloggedCustomerComment = () => import("../components/make-comment.vue");
// import Index from "../views/frontend/dashboard/home/Index";
const Index = () => import("../views/frontend/dashboard/home/Index");
// import AppointmentView from "../views/frontend/pages/servicedetail/appointment-view.vue";
const AppointmentView = () =>
  import("../views/frontend/pages/servicedetail/appointment-view.vue");
const AppointmentOperations = () =>
  import(
    "../views/frontend/pages/servicedetail/pages/appointment-operations.vue"
  );
import Aboutus from "../views/frontend/pages/aboutus/about";
import Addservice from "../views/frontend/pages/service/addservice";
import Bookservice from "../views/frontend/pages/service/bookservice";
// import HomeCategories from "../views/frontend/pages/home-categories";
const HomeCategories = () => import("../views/frontend/pages/home-categories");
import Chat from "../views/frontend/pages/chat/chat";
import Contactus from "../views/frontend/pages/contactus";
import Editservice from "../views/frontend/pages/service/editservice";
import Faq from "../views/frontend/pages/faq";
import Myservice from "../views/frontend/pages/service/myservice";
import Myserviceinactive from "../views/frontend/pages/service/myserviceinactive";

const UserNotifications = () =>
  import("../views/frontend/pages/user-notification");
import Privacypolicy from "../views/frontend/pages/privacypolicy";
const CompanySearch = () =>
  import("../views/frontend/pages/search/company-search");
const ServiceDetail = () =>
  import("../views/frontend/pages/servicedetail/service-detail");
import Termcondition from "../views/frontend/pages/termcondition";
import Booking from "../views/frontend/customer/booking/booking";
import CustomerDashboard from "../views/frontend/customer/dashboard/user-dashboard";
const CustomerCategories = () =>
  import(
    "../views/frontend/customer/category/customer-categories.vue" /* webpackChunkName: "customer-categories" */
  );
const CustomerAppointments = () =>
  import("../views/frontend/customer/appointment/customer-appointments.vue");
import CustomerFavourites from "../views/frontend/customer/favourite/user-favourite";
const CustomerComments = () =>
  import("../views/frontend/customer/comment/user-comments.vue");
const CustomerInstallments = () =>
  import("../views/frontend/customer/installment/user-installment.vue");
const CustomerSessions = () =>
  import("../views/frontend/customer/session/user-session.vue");
import Userpayment from "../views/frontend/customer/payment/userpayment";
import Userreview from "../views/frontend/customer/reviews/Userreview";
// import UserSetting from "../views/frontend/customer/setting/user-setting";
const ProfileSettings = () =>
  import("../views/frontend/customer/setting/profile-settings.vue");
import Userwallet from "../views/frontend/customer/wallet/userwallet";

// import store from "../state/store.js";
import { user, setIdentity } from "../api/auth.service.js";

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (user()) {
          // Redirect to the home page instead
          // next(routeFrom.path);
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/index",
    name: "indexx",
    component: Index,
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (user()) {
          // Redirect to the home page instead
          next(routeFrom.path);
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Index,
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom) {
        setIdentity(null);

        router.push({ name: "index" });
      },
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: Aboutus,
  },
  {
    path: "/add-service",
    name: "add-service",
    component: Addservice,
  },
  {
    path: "/book-service",
    name: "book-service",
    component: Bookservice,
  },
  {
    path: "/categories",
    name: "categories",
    component: HomeCategories,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/chat",
    name: "chat",
    component: Chat,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: Contactus,
  },
  {
    path: "/edit-service",
    name: "edit-service",
    component: Editservice,
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
  },
  {
    path: "/my-services",
    name: "my-services",
    component: Myservice,
  },
  {
    path: "/my-services-inactive",
    name: "my-services-inactive",
    component: Myserviceinactive,
  },
  {
    path: "/privacy-policy1",
    name: "privacy-policy",
    component: Privacypolicy,
  },
  {
    path: "/search",
    name: "search",
    component: CompanySearch,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/search/:search",
    name: "search-content",
    component: CompanySearch,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/:category/:province?/:district?",
    name: "optional-search",
    component: CompanySearch,
    meta: {
      authRequired: false,
    },
  },
  // {
  //   path: "/search-category/:category",
  //   name: "search-category",
  //   component: CompanySearch,
  //   meta: {
  //     authRequired: false,
  //   },
  // },
  {
    path: "/service-details",
    name: "service-details",
    component: ServiceDetail,
  },
  {
    path: "/company-details/:guid",
    name: "service-details-guid",
    component: ServiceDetail,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/make-comment/:guid",
    name: "make-comment-guid",
    component: UnloggedCustomerComment,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/company/:slug",
    name: "service-details-slug",
    component: ServiceDetail,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/appointment-view/:guid",
    name: "appointment-view-guid",
    component: AppointmentView,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/confirmation-page",
    name: "confirmation-page",
    component: AppointmentOperations,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/term-condition",
    name: "term-condition",
    component: Termcondition,
  },
  {
    path: "/user-categories",
    name: "user-categories",
    // component: CustomerCategories,
    component: CustomerCategories,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/user-appointments",
    name: "user-appointments",
    meta: {
      authRequired: true,
    },
    component: CustomerAppointments,
  },
  {
    path: "/user-dashboard",
    name: "user-dashboard",
    meta: {
      authRequired: true,
    },
    component: CustomerDashboard,
  },
  {
    path: "/user-favourites",
    name: "user-favourites",
    meta: {
      authRequired: true,
    },
    component: CustomerFavourites,
  },
  {
    path: "/user-comments",
    name: "user-comments",
    meta: {
      authRequired: true,
    },
    component: CustomerComments,
  },
  {
    path: "/user-installments",
    name: "user-installments",
    meta: {
      authRequired: true,
    },
    component: CustomerInstallments,
  },
  {
    path: "/user-sessions",
    name: "user-sessions",
    meta: {
      authRequired: true,
    },
    component: CustomerSessions,
  },
  {
    path: "/profile-settings",
    name: "profile-settings",
    meta: {
      authRequired: true,
    },
    component: ProfileSettings,
  },
  {
    path: "/user-notifications",
    name: "user-notifications",
    component: UserNotifications,
  },
  {
    path: "/user-wallet",
    name: "user-wallet",
    component: Userwallet,
  },
  {
    path: "/user-bookings",
    name: "user-bookings",
    meta: {
      authRequired: true,
    },
    component: Booking,
  },
  {
    path: "/user-payment",
    name: "user-payment",
    component: Userpayment,
  },
  {
    path: "/user-reviews",
    name: "user-reviews",
    component: Userreview,
  },
];
const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ["/", "/index"];
  const authpage = publicPages.includes(routeTo.path);
  const loggeduser = user();
  const { authRequired } = routeTo.meta;

  if (!authRequired && !loggeduser) {
    return next();
  } else if (authRequired && !loggeduser) {
    return next(routeFrom.path);
  } else if (!authRequired && loggeduser) {
    // if (!authpage) {
    //   console.log("go");
    //   return next();
    // } else if (authpage) {
    //   console.log("stay");
    //   return next(routeFrom.path);
    // }
  } else if (authRequired && loggeduser) {
    if (!authpage) {
      return next();
    } else if (authpage) {
      return next(routeFrom.path);
    }
    // return next({ name: "user-dashboard" });
  }
  next();
  // if (!authpage && !loggeduser) {
  //   return next();
  // } else if (authpage && loggeduser) {
  //   return next({ name: "user-dashboard" });
  // }
  // next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    // console.log("index.js >> ", routeTo);
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

export { router };
