<script setup>
import { useStore } from "vuex";
import { computed, onMounted, watch } from "vue";

const store = useStore();

const defaultNoImgWebp =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/NoImage.webp";

onMounted(() => {
  fetchCustomerFavorites();

  $(".fav-btn .fav-icon").on("click", function() {
    $(this).toggleClass("favourite");
  });
  setTimeout(() => {
    loadedFavorite ? onCalculatedWidth() : "";
  }, 300);
});

const loadedFavorite = computed(() => {
  return store.state["customerfavorite"].loadedFavorite;
});
const favoriteList = computed(() => {
  return store.state["customerfavorite"].favoriteCompanies;
});

watch(loadedFavorite, (newVal) => {
  newVal
    ? setTimeout(() => {
        onCalculatedWidth();
      }, 300)
    : "";
});

const favoriteUpdate = async (favorite) => {
  const model = {
    companyID: favorite.id,
  };
  await store.dispatch("customerfavorite/updateFavorite", model);
};

const fetchCustomerFavorites = async () => {
  await store.dispatch("customerfavorite/getCustomerFavorites");
};
const onCalculatedWidth = () => {
  let starWidth = [];
  starWidth = favoriteList.value.map((rating) => {
    return {
      width: `${(rating.rating / 5) * 100}%`,
    };
  });

  const starInners = document.querySelectorAll(".stars-inner");
  const starOuters = document.querySelectorAll(".stars-outer .stars-inner");

  starInners.forEach((inner, index) => {
    inner.style.width = starWidth[index].width;
  });
  starOuters.forEach((outer, index) => {
    outer.innerHTML = favoriteList.value[index].rating;
  });
};
</script>

<template>
  <div class="row">
    <div
      class="col-lg-4 col-md-6"
      v-for="favorite in favoriteList"
      :key="favorite.id"
    >
      <div class="service-widget flex-fill">
        <div class="service-img">
          <router-link
            :to="{
              name: 'service-details-slug',
              params: { slug: favorite.slug },
              // params: { guid: favorite.companyGuid },
            }"
            @click="store.commit('customerfavorite/favorite', favorite)"
          >
            <img
              class="img-fluid serv-img"
              alt="Service Image"
              :src="favorite.mainImg?.picture ?? defaultNoImgWebp"
            />
          </router-link>
          <div class="dashboard fav-btn" @click="favoriteUpdate(favorite)">
            <a href="javascript:void(0)" class="user fav-icon">
              <i class="fas fa-heart"></i>
            </a>
          </div>
          <div class="loc-btn">
            <!-- <a href="javascript:void(0)" class="loc-icon"> -->
            <div class="loc-icon px-2">
              <!-- <i class="fas fa-heart"></i> -->
              <span
                class="material-icons-outlined color-white pe-2"
                style="font-size: 20px;"
              >
                place
              </span>
              <span class="pe-1" style="font-size: 16px;">
                {{ favorite.cityName }}
              </span>
            </div>
          </div>
          <div class="item-info">
            <div class="cate-list">
              <div
                v-for="(category, index) in favorite.categoryList"
                :key="index"
                class="bg-light-blue me-2"
              >
                {{ category }}
              </div>
              <!-- <router-link
                          v-for="(category, index) in favorite.categoryList"
                          :key="index"
                          class="bg-light-blue me-2"
                          :to="{
                            name: 'service-details-guid',
                            params: { guid: favorite.companyGuid },
                          }"
                        >
                          {{ category }}
                        </router-link> -->
            </div>
          </div>
        </div>
        <div class="service-content">
          <h3 class="title">
            <router-link
              :to="{
                name: 'service-details-slug',
                params: { slug: favorite.slug },
                // params: { guid: favorite.companyGuid },
              }"
            >
              {{ favorite.companyName }}
            </router-link>
          </h3>
          <div class="d-flex justify-content-between align-items-center">
            <!-- <div class="rating mapgridrating1">
                        <i
                          class="fas fa-star"
                          v-for="index in 5"
                          :class="{ filled: index <= favorite.rating }"
                        ></i>
                        <span class="d-inline-block average-rating">{{
                          favorite.rating
                        }}</span>
                      </div> -->

            <div class="d-flex company-comment-text pb-0 pt-0 me-4">
              <div class="rating mapgridrating1">
                <i style="font-style: normal;" class="me-2">
                  <div class="stars-outer">
                    <div class="stars-inner" style="width: 100%;"></div>
                  </div>
                </i>
              </div>
              <span>{{ favorite.rating }}</span>
            </div>

            <div class="user-info">
              <div class="row">
                <!-- <span class="col-auto ser-contact"> 
                            <span>{{ favorite.phoneList[0]?.phone }}</span>
                            <span
                              class="material-icons-round color-black ms-2"
                              v-if="favorite.phoneList.length"
                            >
                              phone_enabled
                            </span>
                          </span> -->

                <!-- <span class="col ser-location">
                          <span>{{ favorite.name2 }}</span>
                            <i class="fas fa-map-marker-alt ms-1"></i>
                          </span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
