import { baseUrl } from "./base.js";
import { serialize } from "../helpers/formatters.js";

const getAllCategories = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/category-all?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getCompanyCategories = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/categorys?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getPopularCategories = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/category-popular?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getCompanySubCategories = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/sub-categorys?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

export {
  getAllCategories,
  getCompanyCategories,
  getCompanySubCategories,
  getPopularCategories,
};
