<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
import { useStore } from "vuex";

const store = useStore();

const thumbnailsCarousel = ref(null);
const galleryCarousel = ref(null);
const currentSlide = ref(0);
const carouselData = reactive({
  pictureList: null,
  totalItems: 0,
});
const settings = reactive({
  itemsToShow: 1,
  snapAlign: "center",
});
const breakpoints = reactive({
  // 700px and up
  700: {
    itemsToShow: 1,
    snapAlign: "center",
  },
  // 1024 and up
  1024: {
    itemsToShow: 1,
    snapAlign: "center",
  },
});

const defaultNoImgWebp =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/NoImage.webp";

onBeforeRouteLeave((to, from, next) => {
  store.commit("customercompany/loadedSlugCompany", false);
  next();
});
onMounted(async () => {
  window.addEventListener("load", async function() {
    const thumbnails = document.querySelectorAll(
      ".carousel__viewport ol li > div"
    );

    thumbnails[1].addEventListener("click", await changeShowedImg(1));
    setTimeout(() => {
      changeShowedImg(0);
    }, 500);
  });
});

const loadedSlugCompany = computed(() => {
  return store.state["customercompany"].loadedSlugCompany;
});
// const pictureList = computed(() => {
//   console.log(store.state["customercompany"].company.pictureList);
//   return store.state["customercompany"].company.pictureList;
// });

watch(loadedSlugCompany, (newValue) => {
  if (newValue) {
    carouselData.pictureList =
      store.state["customercompany"].company.pictureList;
    carouselData.totalItems = carouselData.pictureList.length || 0;

    carouselLoad();
  }
});

const carouselLoad = async () => {
  const thumbnails = document.querySelectorAll(
    ".carousel__viewport ol li > div"
  );

  setTimeout(async () => {
    thumbnails[0].addEventListener("click", await changeShowedImg(0));
  }, 100);
};
const getPicture = (picture) => {
  return picture ?? defaultNoImgWebp;
};
const changeShowedImg = (data) => {
  currentSlide.value = data;
};
</script>

<template>
  <div class="row" v-if="!loadedSlugCompany">
    <div class="loading-skeleton border-0">
      <div class="col-12 d-flex justify-content-start pe-0">
        <Carousel
          id="gallery"
          :items-to-show="1"
          ref="galleryCarousel"
          v-model="currentSlide"
        >
          <Slide>
            <div class="carousel__item me-0">
              <img
                :src="defaultNoImgWebp"
                width="642"
                height=""
                alt=""
                class="carousel-img bg-black"
              />
            </div>
          </Slide>
        </Carousel>
      </div>
      <div class="col-12">
        <carousel
          ref="thumbnailsCarousel"
          id="thumbnails"
          :items-to-show="4"
          :wrap-around="false"
          v-model="currentSlide"
        >
          <slide v-for="index in 5" :key="`tpl-${index}`">
            <div class="carousel__item">
              <img
                :src="defaultNoImgWebp"
                width="150"
                height=""
                alt=""
                class="carousel-img bg-black"
              />
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>

  <div class="row" v-if="carouselData.totalItems > 0 && loadedSlugCompany">
    <div class="col-12 d-flex justify-content-start pe-0">
      <Carousel
        id="gallery"
        :items-to-show="1"
        :wrap-around="true"
        :settings="settings"
        :breakpoints="breakpoints"
        v-model="currentSlide"
        ref="galleryCarousel"
      >
        <Slide
          v-for="picture in carouselData.pictureList"
          :key="`gp-${picture.id}`"
        >
          <div class="carousel__item" :ref="`gc-${picture.id}`">
            <img
              :src="getPicture(picture.picture)"
              width="642"
              height=""
              alt=""
              class="carousel-img"
            />
          </div>
        </Slide>
        <template #addons>
          <Navigation class="">
            <template #next>
              <span class="material-icons-round" style="font-size: 40px;">
                arrow_forward_ios
              </span>
            </template>
            <template #prev>
              <span class="material-icons-round" style="font-size: 40px;">
                arrow_back_ios
              </span>
            </template>
          </Navigation>
        </template>
      </Carousel>
    </div>

    <div class="col-12">
      <Carousel
        id="thumbnails"
        :items-to-show="4"
        :wrap-around="false"
        v-model="currentSlide"
        ref="thumbnailsCarousel"
      >
        <Slide
          v-for="(picture, index) in carouselData.pictureList"
          :key="`tp-${picture.id}`"
        >
          <div class="carousel__item" @click="changeShowedImg(index)">
            <img
              :src="getPicture(picture.picture)"
              width="150"
              height=""
              alt=""
              class="carousel-img"
            />
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<style>
img.carousel-img {
  aspect-ratio: 16 /9;
}
#gallery {
  width: 100%;
}
</style>
