<script setup>
import Cookie from "../../../../helpers/cookie";
import { useStore } from "vuex";
import { computed, inject, onMounted, reactive, watch } from "vue";

import { user } from "../../../../api/auth.service";

const store = useStore();
const $pv = inject("$v");

// const props = defineProps(["infoModel"]);
const props = defineProps({ infoModel: { type: Object, required: true } });

const response = reactive({
  model: {},
  currentPhone: "",
});
const loaded = computed(() => {
  return store.state["customerprofile"].loaded;
});
// watch(loaded, (newValue, oldValue) => {
//   if (newValue) {
//     response.model = store.state["customerprofile"].profile;
//     response.currentPhone = store.state["customerprofile"].profile.phone;
//     fetchTowns();
//   }
// });
// const adressModel = reactive({
//   cityID: user().cityID ?? 0,
//   townID: user().townID ?? 0,
//   adress: user().adress ?? "",
//   adressAdditional: "",
// });

onMounted(() => {
  fetchCities();

  // if (response.model.cityID != 0 && response.model.cityID != undefined) {
  //   fetchTowns();
  // }
});

const cityList = computed(() => {
  const list = store.state["companycity"].allCities?.map((city) => {
    return {
      title: city.title,
      id: city.id,
    };
  });
  return [{ title: "Seçiniz..", id: 0 }, ...list];
});

const townList = computed(() => {
  const list = store.state["companycity"].allCounties?.map((town) => {
    return {
      title: town.title,
      id: town.id,
    };
  });
  return [{ title: "Seçiniz..", id: 0 }, ...(list || [])];
});

watch(
  () => $pv.value.cityID.$model,
  (newValue /*oldValue*/) => {
    if (newValue != undefined) {
      fetchTowns({ cityID: $pv.value.cityID.$model });
    }
  }
);

const fetchCities = async () => {
  await store.dispatch("companycity/getCities");
};
const fetchTowns = async () => {
  await store.dispatch("companycity/getTowns", {
    cityID: $pv.value.cityID.$model,
  });
};

const onInputHandle = (event) => {
  // addressData.districtId = 0;
  $pv.value.cityID.$model = event.target.value;
  $pv.value.townID.$model = 0;
};
const saveProfileInformations = async () => {
  const model = {
    // ...props.infoModel,
    ...response?.model,
    file: response?.model.file ?? "",
    notificationToken: user().notificationToken ?? "",
    token: user().token ?? "",
  };
  if (response.model.phone != response.currentPhone) {
    store.commit("customerprofile/phoneValidateModal");
    return;
  }
  const responseObj = await store.dispatch(
    "customerprofile/updateProfileInformations",
    model
  );

  const resProfile = await store.dispatch(
    "customerprofile/getCustomerProfileInformations"
  );

  if (responseObj.success) {
    const identityObject = JSON.stringify({
      // adress,
      // birthday,
      // city,
      // cityID,
      // email,
      // genderID,
      // id,
      // name,
      // notificationToken,
      // phone,
      // profilePicture,
      // surname,
      // token,
      // townID,
      ...model,
      profilePicture: resProfile[0].profilePicture,
    });

    Cookie.set("ixirlifeBireyselPanelUser", identityObject, { days: 30 });
  }
};
</script>

<template>
  <!-- <div class="address-area d-flex"> -->
  <div class="row">
    <div class="address-area col-xl-12 mb-4">
      <h5 class="form-title">Adres Bilgileri</h5>
      <hr />
    </div>

    <div class="form-group col-xl-6">
      <label class="me-sm-2">İl <span class="color-req-red">*</span></label>
      <div class="filter-list">
        <select
          class="form-control form-select"
          :class="{ 'is-invalid': $pv.cityID.$error }"
          v-model="$pv.cityID.$model"
          @change="fetchTowns"
          @input="onInputHandle"
        >
          <option v-for="city in cityList" :value="city.id">{{
            city.title
          }}</option>
        </select>
        <span class="error-message" v-if="$pv.cityID.$error">
          {{ $pv.cityID.$errors[0].$message }}
        </span>
      </div>
    </div>

    <div class="form-group col-xl-6">
      <label class="me-sm-2">İlçe <span class="color-req-red">*</span></label>
      <div class="filter-list">
        <select
          class="form-control form-select"
          :class="{ 'is-invalid': $pv.townID.$error }"
          v-model="$pv.townID.$model"
        >
          <option v-for="town in townList" :value="town.id">{{
            town.title
          }}</option>
        </select>
        <span class="error-message" v-if="$pv.townID.$error">
          {{ $pv.townID.$errors[0].$message }}
        </span>
      </div>
    </div>

    <div class="form-group col-xl-12">
      <label class="me-sm-2">Adres</label>
      <textarea
        name="address"
        id="user-app-comment"
        v-model="$pv.adress.$model"
        :maxlength="250"
      ></textarea>
      <!-- <input
      type="text"
      class="form-control"
      name="address"
      v-model="response.model.adress"
    /> -->
    </div>
    <!-- <div class="form-group col-xl-6">
    <label class="me-sm-2">State</label>
    <select
      class="form-control form-select"
      name="state_id"
      id="state_id"
    ></select>
  </div>
  <div class="form-group col-xl-6">
    <label class="me-sm-2">City</label>
    <select
      class="form-control form-select"
      name="city_id"
      id="city_id"
    ></select>
  </div>
  <div class="form-group col-xl-6">
    <label class="me-sm-2">Postal Code</label>
    <input type="text" class="form-control" name="pincode" value="665487" />
  </div> -->
    <!-- <div class="alignment-content form-group col-xl-12 mt-4">
    <button
      id="form_submit"
      class="btn"
      @click.prevent="saveProfileInformations"
    >
      Kaydet
    </button>
  </div> -->
    <!-- </div> -->
  </div>
</template>
