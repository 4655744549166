<script setup></script>
<template>
  <!-- /our app -->
  <section class="app-set">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-12">
          <div class="appimg-set aos" data-aos="fade-up">
            <!-- src="../../../../assets/img/ixirlocal/mobile-app.svg" -->
            <img
              src="https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/phones.webp"
              width="516"
              height="626"
              alt="img"
            />
            <!-- <img src="../../../../assets/img/app.png" alt="img" /> -->
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="col-md-12">
            <div class="heading aos" data-aos="fade-">
              <h2>Bireysel Mobil İndirin</h2>
              <span
                >Dilediğiniz yerden 7/24 randevu alabileceğiniz Ixirlife
                Bireysel mobil uygulaması ile konum bazlı hizmet, salon türü ve
                salon bilgileri ile arama yapabilir, randevu takviminizi
                yönetebilir, hizmet ve ödeme geçmişinizi görüntüleyebilirsiniz.
                Hemen Ücretsiz İndirin!
              </span>
            </div>
          </div>
          <div class="downlaod-set aos" data-aos="fade-up">
            <ul>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ixirlifeplusbireysel"
                  target="_blank"
                >
                  <img
                    src="../../../../assets/img/ixirlocal/android-play.svg"
                    alt="img"
                  />
                  <!-- <img src="../../../../assets/img/gp.png" alt="img" /> -->
                </a>
              </li>
              <li class="ms-3">
                <a
                  href="https://apps.apple.com/us/app/ixirlife-bireysel/id1658570530"
                  target="_blank"
                >
                  <img
                    src="../../../../assets/img/ixirlocal/ios-play.svg"
                    alt="img"
                  />
                  <!-- <img src="../../../../assets/img/ap.png" alt="img" /> -->
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /our app -->
</template>
