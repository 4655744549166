import Cookie from "../helpers/cookie";
import { serialize } from "../helpers/formatters.js";
import { baseUrl, user, tokenString } from "./base.js";

const sendLoginRequest = async (data) => {
  const endPoint = `${baseUrl}/genel/login`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      // Authorization: `Bearer ${data.token}`,
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const sendSmsForUserLogin = async (data) => {
  const endPoint = `${baseUrl}/genel/send-sms-login`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      // Authorization: `Bearer ${data.token}`,
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const sendSmsForUserLoginV2 = async (data) => {
  const endPoint = `${baseUrl}/genel/send-sms-login-v2`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      // Authorization: `Bearer ${data.token}`,
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const sendQrForUserLogin = async (data) => {
  // const qs = serialize(data);
  // const endPoint = `${baseUrl}/genel/qrcode?${qs}`;
  const endPoint = `${baseUrl}/randevu/login-with-qr`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {},
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const sendSmsForUpdatePhoneV2 = async (data) => {
  const endPoint = `${baseUrl}/randevu/send-sms-login-v2`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const validateSmsForUserLogin = async (data) => {
  const endPoint = `${baseUrl}/genel/verify-sms-login`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      // Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const validateSmsForUpdatePhoneV2 = async (data) => {
  const endPoint = `${baseUrl}/randevu/verify-sms-login`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const sendSmsForUserRegister = async (data) => {
  const endPoint = `${baseUrl}/genel/send-sms-register`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      // Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const sendRegisterRequest = async (data) => {
  const endPoint = `${baseUrl}/genel/customer-register`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      // Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const setIdentity = (token) => {
  if (token != null) {
    const storage = user();

    storage.token = token;

    // sessionStorage.setItem("ixirlifeBireyselPanelUser", JSON.stringify(storage));

    Cookie.set("ixirlifeBireyselPanelUser", JSON.stringify(storage), {
      days: 30,
    });
  } else {
    // sessionStorage.removeItem("ixirlifeBireyselPanelUser");

    Cookie.delete("ixirlifeBireyselPanelUser", { days: -1 });
  }
};

export {
  sendLoginRequest,
  sendSmsForUserLogin,
  sendSmsForUserLoginV2,
  sendSmsForUpdatePhoneV2,
  sendQrForUserLogin,
  validateSmsForUpdatePhoneV2,
  sendSmsForUserRegister,
  validateSmsForUserLogin,
  sendRegisterRequest,
  user,
  setIdentity,
};
