<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted, watch } from "vue";

const store = useStore();
const router = useRouter();

const defaultPersonnelImage = require("../../../../assets/img/ixirlocal/default-avatar.svg")
  .default;

onMounted(() => {
  // fetchAppointmentTitles();
});

const loadedSlugCompany = computed(() => {
  return store.state["customercompany"].loadedSlugCompany;
});
const totalPersonnels = computed(() => {
  return store.state["appointment"].appointmentTitles.length || 0;
});
const companyInfo = computed(() => {
  return store.state["customercompany"].company;
});
const titleList = computed(() => {
  let list = store.state["appointment"].appointmentTitles;
  list = list?.map((item) => {
    return {
      ...item,
      firstName: item.name.split(" ")[0],
      lastName: item.name.split(" ")[1],
    };
  });

  return list;
});

watch(loadedSlugCompany, (newVal) => {
  newVal ? fetchAppointmentTitles() : "";
});

const fetchAppointmentTitles = async () => {
  // const { guid } = router.currentRoute.value.params;
  const model = {
    companyGuid: store.state["customercompany"].company.companyGuid,
    // companyGuid: guid,
  };

  await store.dispatch("appointment/getAppointmentTitles", model);
};
const routeWithSelectedProduct = (personnel) => {
  // selectedCategory.subCategoryID = personnel.id;
  store.commit("appointment/informationPersonnel", personnel);

  // const { slug } = router.currentRoute.value.params;
  // router.push({ name: "appointment-view-slug", params: { slug } });

  const { companyGuid } = companyInfo.value;
  router.push({ name: "appointment-view-guid", params: { guid: companyGuid } });
};
</script>

<template>
  <div class="row company-card-inside gx-0">
    <div class="col-12 company-header">
      <span>Personeller</span>
    </div>

    <div v-if="totalPersonnels == 0">
      <span class="color-858585">Firma personeli bulunmamaktadır.</span>
    </div>
    <!-- <div class="personnel-row">
      <div
        class="per-img d-block justify-content-center"
        v-for="title in titleList"
        :key="title.id"
      >
        <img
          v-if="
            title.image !=
              'https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/avatar.png' &&
              title.image != null
          "
          :src="title.image ?? defaultPersonnelImage"
          width="82"
          height="82"
          alt="personnel"
        />
        <div
          v-else
          class="d-flex justify-content-center align-items-center"
          style="width: 82px;height: 82px;border: 1px solid #a5b3c6;
          border-radius: 50%;color: #A5B3C6;font-size: 32px;"
        >
          <span>{{
            title.firstName.slice(0, 1).toUpperCase() +
              (title.lastName?.slice(0, 1).toUpperCase() ?? "")
          }}</span>
        </div>
        <img
          src="../../../../assets/img/ixirlocal/default-avatar.svg"
          width="82"
          height="82"
          alt="personnel"
        />
        <div class="d-block justify-content-center mt-3">
          <div class="alignment-content">
            <span>{{ title.firstName }}</span>
          </div>
          <div class="alignment-content">
            <span>{{ title?.lastName }}</span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="overflow-auto" style="display: contents;">
      <div class="d-flex" style="width: max-content;"></div>
      <div
        class="card mb-0 cursor-pointer p-card mb-3"
        style=""
        v-for="title in titleList"
        :key="title.id"
        @click="routeWithSelectedProduct(title)"
      >
        <img
          v-if="
            title.image !=
              'https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/avatar.png' &&
              title.image != null
          "
          :src="title.image ?? defaultPersonnelImage"
          width="82"
          height="82"
          alt="personnel"
        />
        <div
          v-else
          class="d-flex justify-content-center align-items-center"
          style="width: 82px;height: 82px;border: 1px solid #a5b3c6;
          border-radius: 50%;color: #A5B3C6;font-size: 32px;margin-top: 12px;"
        >
          <span>{{
            title.firstName.slice(0, 1).toUpperCase() +
              (title.lastName?.slice(0, 1).toUpperCase() ?? "")
          }}</span>
        </div>
        <!-- <img
          src="../../../../assets/img/ixirlocal/default-avatar.svg"
          width="82"
          height="82"
          alt="personnel"
        /> -->
        <div
          class="d-block justify-content-center mt-"
          style="padding: 4px 0 8px;font-size: 13px;"
        >
          <div class="alignment-content">
            <span>{{ title.firstName }}</span>
          </div>
          <div class="alignment-content">
            <span>{{ title?.lastName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.p-card {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 102px;
  height: 148px;
}
.p-card:not(:first-child) {
  margin-left: 16px;
}
.p-card img {
  margin-top: 12px;
  border-radius: 50%;
  border: 1px solid #a5b3c6;
}
.p-card:hover {
  border: 1px solid #0fbad6 !important;
  box-shadow: 0px 1px 10px #0fbad6 !important;
}
</style>
