<script setup>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { computed, onMounted, watch } from "vue";
import { convertToLowerCase, convertToUpperCase } from "../../../../api/base";
import { scrollToElement } from "../../../../composables/scroll";

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const { currentRoute } = useRouter();

// const defaultNoImg = require("../../../../assets/img/ixirlocal/no-img.svg")
//   .default;
const defaultNoImgWebp =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/NoImage.webp";

const imagesTemp = require.context(
  "../../../../assets/img/ixirlocal",
  false,
  /\.svg$/
);

onMounted(() => {
  setTimeout(() => {
    loaded ? onCalculatedWidth() : "";
  }, 300);
});

const loaded = computed(() => {
  return store.state["customercompany"].loaded;
});
const companyList = computed(() => {
  return store.state["customercompany"].companies;
});
const categorySlug = computed(() => {
  return t(`slug.${currentRoute.value.fullPath.split("/")[1]}`);
});
const searchModel = computed(() => {
  return store.state["customercompany"].searchModel;
});

watch(loaded, (newVal) => {
  newVal
    ? setTimeout(() => {
        onCalculatedWidth();
      }, 300)
    : "";
});

const scopeUrl = (rangeFinishIndex) => {
  return `${currentRoute.value.fullPath
    .split("/")
    .slice(1, rangeFinishIndex)
    .join("/")}`;
};
const additionalUrl = (index, province, district) => {
  return index == 0
    ? `${currentRoute.value.fullPath.split("/")[1]}/${convertToLowerCase(
        convertToUpperCase(province)
      )}`
    : `${currentRoute.value.fullPath.split("/")[1]}/${convertToLowerCase(
        convertToUpperCase(province)
      )}/${convertToLowerCase(convertToUpperCase(district))}`;
};
const onCalculatedWidth = () => {
  let starWidth = [];
  starWidth = companyList.value.map((rating) => {
    return {
      width: `${(rating.rating / 5) * 100}%`,
    };
  });

  const starInners = document.querySelectorAll(".stars-inner");
  const starOuters = document.querySelectorAll(".stars-outer .stars-inner");

  starInners.forEach((inner, index) => {
    inner.style.width = starWidth[index].width;
  });
  starOuters.forEach((outer, index) => {
    outer.innerHTML = companyList.value[index].rating;
  });
};
const goToSteps = (company) => {
  router.push({
    // name: "appointment-view-slug",
    // params: { slug: company.slug },
    name: "appointment-view-guid",
    params: { guid: company.companyGuid },
  });
};
const loadTempImg = (path) => {
  // return imagesTemp("./user-01.jpg").default;
  return imagesTemp(path).default;
};

const fetchProvinceDistrict = async () => {
  await store.dispatch("companycity/getTowns", {
    cityID: searchModel.value.cityID,
  });
};
const fetchCompaniesWithLocation = async (provinceID, districtID) => {
  scrollToElement(document.querySelector("#search-list"), 120);

  setTimeout(async () => {
    store.commit("customercompany/updateSearchModel", {
      ...searchModel.value,
      cityID: provinceID,
      townID: districtID,
    });
    fetchProvinceDistrict();

    store.commit("customercompany/loaded", false);
    await store.dispatch(
      "customercompany/getCustomerCompanies",
      searchModel.value
    );
  }, 500);
};
</script>

<template>
  <div class="row" v-if="!loaded">
    <div class="col-md-12 col-lg-12 mb-4" v-for="index in 3" :key="index">
      <div class="loading-skeleton">
        <div class="row">
          <div class="col-3">
            <img
              :src="defaultNoImgWebp"
              class="card-img-top"
              style="max-width: 100%; height: 100%"
              alt="..."
            />
          </div>
          <div class="col-7">
            <div class="service-content mt-3">
              <h3 class="title">
                Company Name
              </h3>
              <div
                class="d-inline-flex justify-content- align-items-center mt-"
                style="margin-top: 12px;"
              >
                <div class="rating mapgridrating1 me-4">
                  <i class="fas fa-star" v-for="index in 5"></i>
                  <span class="d-inline-block average-rating"> Rating </span>
                </div>

                <!-- <div class="company-loc mt- d-flex align-items-center">
                  <span style="min-width: 100%;">Konum / Konum</span>
                </div> -->

                <!-- <div class="user-info">
                  <div class="row">
                    <span class="col-auto ser-contact">
                      <span>Phone Number</span>
                      <span class="material-icons-round color-black ms-2">
                        phone_enabled
                      </span>
                    </span>
                  </div>
                </div> -->
              </div>

              <div class="mt-3 w-100">
                <span class="d-flex ">Category > Province > District</span>
              </div>

              <div class="d-flex justify-content-start mt-3">
                <div class="cate-list">
                  <router-link
                    v-for="index in 3"
                    :key="index"
                    class="bg-light-blue me-2"
                    :to="{}"
                  >
                    Category Name
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-else>
    <div
      id="list-table"
      class="col-md-12 col-lg-12 mt- mb-"
      v-for="company in companyList"
      :key="company.id"
    >
      <div class="service-widget">
        <div class="row">
          <div class="col-4">
            <div class="service-img h-100">
              <router-link
                :to="{
                  name: 'service-details-slug',
                  params: { slug: company.slug },
                  // params: { guid: company.companyGuid },
                }"
              >
                <img
                  class="img-fluid serv-img h-100"
                  alt="Service Image"
                  :src="company.mainImg?.picture ?? defaultNoImgWebp"
                />
              </router-link>
              <!-- <div class="fav-btn">
            <a href="javascript:void(0)" class="fav-icon">
              <i class="fas fa-heart"></i>
            </a>
            </div> -->
              <!-- <div class="loc-btn">
                <a href="javascript:void(0)" class="loc-icon">
                <div class="loc-icon px-2">
                  <i class="fas fa-heart"></i>
                  <span
                    class="material-icons-outlined color-white pe-2"
                    style="font-size: 20px;"
                  >
                    place
                  </span>
                  <span class="pe-1" style="font-size: 16px;">{{
                    "İstanbul İstanbul"
                  }}</span>
                </div>
              </div> -->
              <div class="item-info">
                <!-- <div class="service-user">
              <a href="javascript:void(0);">
                <img :src="loadImg1(company.img1)" alt="" />
              </a>
              <span class="service-price ms-1">{{ company.rate }}</span>
            </div> -->
              </div>
            </div>
          </div>

          <div class="col-5">
            <div class="service-content mt-3">
              <h3 class="title">
                <router-link
                  :to="{
                    name: 'service-details-slug',
                    params: { slug: company.slug },
                    // params: { guid: company.companyGuid },
                  }"
                  >{{ company.companyName }}</router-link
                >
              </h3>
              <div
                class="d-inline-flex justify-content- align-items-center mt-2"
              >
                <!-- <div class="rating mapgridrating1 me-4">
                  <i
                    class="fas fa-star"
                    v-for="index in 5"
                    :class="{ filled: index <= company.rating }"
                  ></i>
                  <span class="d-inline-block average-rating">{{
                    company.rating
                  }}</span>
                </div> -->

                <div class="d-flex company-comment-text pb-0 pt-0 me-4">
                  <div class="rating mapgridrating1">
                    <i style="font-style: normal;" class="me-2">
                      <div class="stars-outer">
                        <div class="stars-inner" style="width: 100%;"></div>
                      </div>
                    </i>
                  </div>
                  <span>{{ company.rating }}</span>
                </div>

                <!-- <div class="company-loc mt- d-flex align-items-center">
                  <span
                    class="material-icons-outlined color-purple pe-2"
                    style="font-size: 16px;padding-bottom: 2px;"
                  >
                    place
                  </span>
                  <span>
                    {{ company.districtName }} / {{ company.cityName }}
                  </span>
                </div> -->

                <div class="user-info">
                  <div class="row">
                    <!-- <span class="col-auto ser-contact"> 
                      <span>{{ company.phoneList[0]?.phone }}</span>
                      <span
                        class="material-icons-round color-black ms-2"
                        v-if="company.phoneList.length"
                      >
                        phone_enabled
                      </span>
                    </span> -->

                    <!-- <span class="col ser-location">
                      <span>{{ company.name2 }}</span>
                      <i class="fas fa-map-marker-alt ms-1"></i>
                    </span> -->
                  </div>
                </div>
              </div>

              <div class="mt-3">
                <ul class="d-flex cat-url">
                  <!-- <li>
                    <a class="test" :href="scopeUrl(2)">
                      {{ categorySlug }}
                      <span class="test-1"> > </span>
                    </a>
                  </li> -->
                  <li v-if="currentRoute.params?.province">
                    <a class="test" :href="scopeUrl(3)">
                      <span>
                        {{
                          company.cityName
                            ?.charAt(0)
                            .toLocaleUpperCase("tr-TR") +
                            company.cityName
                              ?.slice(1)
                              .toLocaleLowerCase("tr-TR")
                        }}
                        {{ categorySlug }}
                      </span>
                    </a>
                  </li>
                  <li v-else-if="currentRoute.name == 'optional-search'">
                    <a
                      class="test"
                      :href="additionalUrl(0, company.cityName, '')"
                    >
                      <span>
                        {{
                          company.cityName
                            ?.charAt(0)
                            .toLocaleUpperCase("tr-TR") +
                            company.cityName
                              ?.slice(1)
                              .toLocaleLowerCase("tr-TR")
                        }}
                        {{ categorySlug }}
                      </span>
                    </a>
                  </li>
                  <li v-else>
                    <a
                      class="test"
                      href="javascript:void(0);"
                      @click="fetchCompaniesWithLocation(company.cityId, 0)"
                    >
                      {{
                        company.cityName?.charAt(0).toLocaleUpperCase("tr-TR") +
                          company.cityName?.slice(1).toLocaleLowerCase("tr-TR")
                      }}
                    </a>
                  </li>

                  <!-- <span class="test-1 mx-1" v-if="currentRoute?.params.district"
                    >></span
                  > -->
                  <span class="test-1 mx-1">></span>

                  <li v-if="currentRoute.params?.district">
                    <a class="test" :href="scopeUrl(4)">
                      {{ company.districtName }} {{ categorySlug }}
                    </a>
                  </li>
                  <li v-else-if="currentRoute.name == 'optional-search'">
                    <a
                      class="test"
                      :href="
                        additionalUrl(1, company.cityName, company.districtName)
                      "
                    >
                      {{
                        company.districtName
                          ?.charAt(0)
                          .toLocaleUpperCase("tr-TR") +
                          company.districtName
                            ?.slice(1)
                            .toLocaleLowerCase("tr-TR")
                      }}
                      {{ categorySlug }}
                    </a>
                  </li>
                  <li v-else>
                    <a
                      class="test"
                      href="javascript:void(0);"
                      @click="
                        fetchCompaniesWithLocation(
                          company.cityId,
                          company.districtId
                        )
                      "
                    >
                      {{
                        company.districtName
                          ?.charAt(0)
                          .toLocaleUpperCase("tr-TR") +
                          company.districtName
                            ?.slice(1)
                            .toLocaleLowerCase("tr-TR")
                      }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="d-flex justify-content-start mt-4">
                <div class="cate-list" v-if="company.categoryList?.length > 0">
                  <div
                    v-for="(category, index) in company.categoryList"
                    :key="index"
                    class="outlined-light-blue me-2"
                  >
                    {{ category }}
                  </div>
                  <!-- <router-link
                    v-for="(category, index) in company.categoryList"
                    :key="index"
                    class="bg-light-blue me-2"
                    :to="{
                      name: 'service-details-slug',
                      params: { slug: company.slug },
                      // params: { guid: company.companyGuid },
                    }"
                  >
                    {{ category }}
                  </router-link> -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-3" style="border-radius: 15px;">
            <div class="bg-three">
              <div class="lw-btn-position">
                <a
                  href="javascript:void(0);"
                  class="btn"
                  @click="goToSteps(company)"
                  >Randevu Al</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.cat-url li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.test {
  color: rgba(0, 145, 234, 0.95);

  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 168.75% */
}
.test-1 {
  color: rgba(0, 0, 0, 0.95);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
</style>
<style scoped>
/* .img-fluid {
  max-width: 330px;
  height: 250px;
} */

.service-img::before {
  border-radius: 0px 0px 0px 15px;
}
.service-img {
  border-radius: 15px 0px 0px 15px;
}
.service-img .serv-img {
  border-radius: 15px 0px 0px 15px;
}

/* // Codepen presentation */
.container {
  margin-top: 4em;
  margin-bottom: 4em;
}

/* @-webkit-keyframes moveSlideshow {
  0% {
    left: 0;
  }
  100% {
    left: -1684px;
  }
}
@-moz-keyframes moveSlideshow {
  0% {
    left: 0;
  }
  100% {
    left: -1684px;
  }
}
@-o-keyframes moveSlideshow {
  0% {
    left: 0;
  }
  100% {
    left: -200%;
  }
}
@keyframes moveSlideshow {
  0% {
    left: 0;
  }
  100% {
    left: -200%;
  }
} */
</style>
