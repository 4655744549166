import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getCustomerCompanies,
  getCustomerCompanyWithGuid,
  getCustomerCompanyWithSlug,
  getPopularCompanies,
  getSimilarCompanies,
  getSearchParamsValues,
} from "../../api/customercompany.service";

const defaultCompanyState = () => {
  return {
    // deleted: null,
    // companyaAdi: "",
    // companyaID: 0,
    active: true,
    adress: "",
    authorizedName: "",
    categoryString: "",
    cellPhone: "",
    cityId: 0,
    cityName: "",
    commentCount: 0,
    companyGuid: "",
    companyName: "",
    customerNumber: 0,
    districtId: 0,
    districtName: "",
    email: "",
    id: 0,
    location: "",
    logo: "",
    note: "",
    phoneList: [],
    pictureList: [],
    products: "",
    rank: 1,
    rating: 4,
    sectorId: 1,
    socialMediaList: [],
    categoryString: "",
    tax: "",
    taxNumber: "",
  };
};

const defaultSearchModelState = () => {
  return {
    search: "",
    cityID: 0,
    townID: 0,
    categoryID: 0,
    subCategoryID: 0,
    // companyID: 0,
    popular: 0,
    path: "",
  };
};
/**
 * @param loadedPC -> loadedPopularCompanies
 * @param loadedSC -> loadedSimilarCompanies
 */
export const state = reactive({
  loaded: false,
  loadedCompany: false,
  loadedSlugCompany: false,
  loadedPC: false,
  loadedSC: false,
  isFavorite: false,
  companyOperationsModal: false,
  companySaved: false,
  companies: [],
  popularCompanies: [],
  similarCompanies: [],
  // company: defaultCompanyState(),
  company: [],
  paramsValues: [],
  searchModel: defaultSearchModelState(),
});

export const getters = {
  companyValues(state) {
    return state.company;
  },
};

export const actions = {
  async getCustomerCompanies({ commit }, data) {
    const responseObj = await getCustomerCompanies(data);

    if (responseObj) {
      const list = responseObj.data?.map((company) => {
        return {
          ...company,
          categoryList:
            company?.categoryString == ""
              ? []
              : company?.categoryString.split(","),
          mainImg: company?.pictureList?.find(
            (picture) => picture.mainPicture == true
          ),
          // slug: company.companyName
          //   .split(" ")
          //   .join("-")
          //   .replace("&", "-")
          //   .toLocaleLowerCase("en-EN"),
        };
      });

      commit("companies", list);
      commit("loaded", responseObj.data.length >= 0);

      return responseObj.data;
    }
  },

  async getPopularCompanies({ commit }, data) {
    const responseObj = await getPopularCompanies(data);
    const list = responseObj?.map((company) => {
      return {
        ...company,
        categoryList: company?.categoryString.split(","),
        mainImg: company?.pictureList?.find(
          (picture) => picture.mainPicture == true
        ),
      };
    });

    commit("popularCompanies", list);
    commit("loadedPC", responseObj.length >= 0);

    return responseObj;
  },

  async getSimilarCompanies({ commit }, data) {
    const responseObj = await getSimilarCompanies(data);
    const list = responseObj?.map((company) => {
      return {
        ...company,
        categoryList: company?.categoryString.split(","),
        mainImg: company?.pictureList?.find(
          (picture) => picture.mainPicture == true
        ),
      };
    });

    commit("similarCompanies", list);
    commit("loadedSC", responseObj.length >= 0);

    return responseObj;
  },

  async getCustomerCompanyWithGuid({ commit }, data) {
    const responseObj = await getCustomerCompanyWithGuid(data);
    const list = responseObj?.map((company) => {
      return {
        ...company,
        categoryList: company?.categoryString.split(","),
      };
    });

    commit("company", list[0]);
    commit("loadedCompany", responseObj.length >= 0);

    return responseObj;
  },

  async getCustomerCompanyWithSlug({ commit }, data) {
    const responseObj = await getCustomerCompanyWithSlug(data);
    const list = responseObj?.map((company) => {
      return {
        ...company,
        categoryList: company?.categoryString.split(","),
      };
    });

    commit("company", list[0]);
    commit("loadedSlugCompany", responseObj.length >= 0);

    return responseObj;
  },

  async getSearchParamsValues({ commit }, data) {
    commit("loadedSearchPrams", false);
    const responseObj = await getSearchParamsValues(data);

    commit("paramsValues", responseObj.data);
    commit("loadedSearchPrams", Object.keys(responseObj.data).length >= 0);

    return responseObj.data;
  },
};

export const mutations = {
  loadedSearchPrams(state, data) {
    state.loadedSearchPrams = data;
  },
  loaded(state, data) {
    state.loaded = data;
  },
  loadedCompany(state, data) {
    state.loadedCompany = data;
  },
  loadedSlugCompany(state, data) {
    state.loadedSlugCompany = data;
  },
  loadedPC(state, data) {
    state.loadedPC = data;
  },
  loadedSC(state, data) {
    state.loadedSC = data;
  },
  isFavorite(state, data) {
    state.isFavorite = data;
  },
  companySaved(state, data) {
    state.companySaved = data;
  },
  companyOperationsModal(state, data) {
    state.companyOperationsModal = data;
  },
  company(state, data) {
    state.company = data;
  },
  companies(state, data) {
    state.companies = data;
  },
  paramsValues(state, data) {
    state.paramsValues = data;
  },
  popularCompanies(state, data) {
    state.popularCompanies = data;
  },
  similarCompanies(state, data) {
    state.similarCompanies = data;
  },
  updateSearchModel(state, updateSearchModel) {
    state.searchModel = updateSearchModel;
  },
  resetCompany(state) {
    state.company = defaultCompanyState();
  },
  resetSearchModel(state) {
    state.company = defaultSearchModelState();
  },
};
