<script setup>
const workList = [
  {
    id: 1,
    img: "./randevu.svg",
    name: "Kolay Randevu Yönetimi",
    description:
      "Güzellik merkezleri için online randevu programını kullanarak kolayca randevu planlayabilirler. Müşterilerin istedikleri tarih ve saatte online randevu alabilmeleri sağlanır. Müşterilere randevularının hatırlatılması için bildirimler gönderir. Bu sayede, müşterilerin randevularını kaçırmaları önler ve zaman tasarrufu kazanırlar.",
  },
  {
    id: 2,
    img: "./memnuniyet.svg",
    name: "Müşteri Memnuniyet",
    description:
      "Müşterilerin kişisel bilgilerini kaydeder ve daha önce aldıkları hizmetleri takip eder. Bu sayede, müşterilere daha kişiselleştirilmiş bir hizmet sunulur. Müşterileriniz seanslarını, cari hareketlerini, geçmiş hizmetlerini ve daha bir çok işlemi kontrol edebilir. Randevu oluşturmak için daha hızlı ve kolay bir yol sunar.",
  },
  {
    id: 3,
    img: "./zaman.svg",
    name: "Zaman Tasarruf",
    description:
      "ixirlife online randevu programı, güzellik merkezleri, klinikler, kuaför salonları, diyetisyenler vb. için özel olarak tasarlanmış bir yazılımdır. Online olarak randevu alarak, bekleme sürelerini minimuma indirirler. Hem danışan hem de çalışanlar açısından birçok avantaj sağlayar. Randevu almak için harcanan zamanı azaltarak verimliliği arttırır.",
  },
  {
    id: 4,
    img: "./esneklik.svg",
    name: "Esneklik",
    description:
      "Online randevu programı, müşterilerin randevularını değiştirmelerine veya iptal etmelerine olanak tanıyan bir sistemdir. Bu şekilde, müşterilerin randevularını değiştirmeleri veya iptal etmeleri gerektiğinde, güzellik merkezleri ile iletişime geçmek zorunda kalmazlar. Yapılan tüm işlemler sistem üzerinden onayınız üzerine geçerli olur.",
  },
];

const images = require.context("../../../../assets/img/work", false, /\.svg$/);

const getImg = (imgPath) => {
  return images(imgPath).default;
};
</script>

<template>
  <!-- How It Works -->
  <div class="howworksec">
    <div class="row">
      <div class="col-lg-3" v-for="item in workList" :key="item.id">
        <div class="howwork aos" data-aos="fade-up">
          <div class="iconround">
            <!-- <div class="steps">{{ item.no }}</div> -->
            <img :src="getImg(item.img)" alt="" />
          </div>
          <h3>{{ item.name }}</h3>
          <p style="text-align: center;">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- /How It Works -->
</template>
