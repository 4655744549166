<template>
  <!-- Loader -->
  <div class="page-loading">
    <div class="preloader-inner">
      <div class="preloader-square-swapping">
        <!-- <div class="cssload-square-part cssload-square-green"></div> -->
        <!-- <div class="cssload-square-part cssload-square-pink"></div> -->
        <div class="cssload-square-part cssload-square-purple"></div>
        <div class="cssload-square-part cssload-square-blue"></div>
        <div class="cssload-square-blend"></div>
      </div>
    </div>
  </div>
  <!-- /Loader -->
</template>

<script>
export default {
  name: "App",
  beforeCreate() {
    $(window).on("load", function() {
      $(".preloader-inner")
        .delay(100)
        .fadeOut("slow");
      $(".page-loading")
        .delay(500)
        .fadeOut("slow");
      $("body")
        .delay(500)
        .css({ overflow: "visible" });
    });
  },
};
</script>

<style scoped>
.page-loading {
  background-color: #fff;
  content: "";
  height: 100%;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 99999;
}
.page-loading > img {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.page-loading > span {
  color: #b3b3b3;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  margin-top: 30px;
  text-decoration: underline;
}
.preloader-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
  display: flex;
  align-items: center;
}
.preloader-square-swapping {
  margin: 30px auto;
  width: 19px;
  height: 19px;
  transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}
.preloader-square-swapping .cssload-square-part {
  position: absolute;
  width: 19px;
  height: 19px;
  z-index: 1;
  animation: cssload-part-anim 0.92s cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite alternate;
  -o-animation: cssload-part-anim 0.92s cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite alternate;
  -ms-animation: cssload-part-anim 0.92s cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite alternate;
  -webkit-animation: cssload-part-anim 0.92s
    cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
  -moz-animation: cssload-part-anim 0.92s cubic-bezier(0.445, 0.05, 0.55, 0.95)
    infinite alternate;
}
.preloader-square-swapping .cssload-square-green {
  background: #ff0080;
  right: 0;
  bottom: 0;
  animation-direction: alternate-reverse;
  -o-animation-direction: alternate-reverse;
  -ms-animation-direction: alternate-reverse;
  -webkit-animation-direction: alternate-reverse;
  -moz-animation-direction: alternate-reverse;
}
.preloader-square-swapping .cssload-square-pink {
  background: #d9c505;
  left: 0;
  top: 0;
}
.preloader-square-swapping .cssload-square-purple {
  background: #20d7d1;
  right: 0;
  bottom: 0;
  animation-direction: alternate-reverse;
  -o-animation-direction: alternate-reverse;
  -ms-animation-direction: alternate-reverse;
  -webkit-animation-direction: alternate-reverse;
  -moz-animation-direction: alternate-reverse;
}
.preloader-square-swapping .cssload-square-blue {
  background: #572a86;
  left: 0;
  top: 0;
}
.preloader-square-swapping .cssload-square-blend {
  background: rgb(117, 81, 125);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  animation: blend-anim 0.92s ease-in infinite;
  -o-animation: blend-anim 0.92s ease-in infinite;
  -ms-animation: blend-anim 0.92s ease-in infinite;
  -webkit-animation: blend-anim 0.92s ease-in infinite;
  -moz-animation: blend-anim 0.92s ease-in infinite;
}

@keyframes blend-anim {
  0% {
    transform: scale(0.01, 0.01) rotateY(0);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    transform: scale(1, 1) rotateY(0);
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    transform: scale(0.01, 0.01) rotateY(0);
  }
}

@-o-keyframes blend-anim {
  0% {
    -o-transform: scale(0.01, 0.01) rotateY(0);
    -o-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    -o-transform: scale(1, 1) rotateY(0);
    -o-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    -o-transform: scale(0.01, 0.01) rotateY(0);
  }
}

@-ms-keyframes blend-anim {
  0% {
    -ms-transform: scale(0.01, 0.01) rotateY(0);
    -ms-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    -ms-transform: scale(1, 1) rotateY(0);
    -ms-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    -ms-transform: scale(0.01, 0.01) rotateY(0);
  }
}

@-webkit-keyframes blend-anim {
  0% {
    -webkit-transform: scale(0.01, 0.01) rotateY(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    -webkit-transform: scale(1, 1) rotateY(0);
    -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    -webkit-transform: scale(0.01, 0.01) rotateY(0);
  }
}

@-moz-keyframes blend-anim {
  0% {
    -moz-transform: scale(0.01, 0.01) rotateY(0);
    -moz-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    -moz-transform: scale(1, 1) rotateY(0);
    -moz-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    -moz-transform: scale(0.01, 0.01) rotateY(0);
  }
}

@keyframes cssload-part-anim {
  0% {
    transform: translate3d(-10px, -10px, 0);
  }
  100% {
    transform: translate3d(10px, 10px, 0);
  }
}

@-o-keyframes cssload-part-anim {
  0% {
    -o-transform: translate3d(-10px, -10px, 0);
  }
  100% {
    -o-transform: translate3d(10px, 10px, 0);
  }
}

@-ms-keyframes cssload-part-anim {
  0% {
    -ms-transform: translate3d(-10px, -10px, 0);
  }
  100% {
    -ms-transform: translate3d(10px, 10px, 0);
  }
}

@-webkit-keyframes cssload-part-anim {
  0% {
    -webkit-transform: translate3d(-10px, -10px, 0);
  }
  100% {
    -webkit-transform: translate3d(10px, 10px, 0);
  }
}

@-moz-keyframes cssload-part-anim {
  0% {
    -moz-transform: translate3d(-10px, -10px, 0);
  }
  100% {
    -moz-transform: translate3d(10px, 10px, 0);
  }
}
</style>
