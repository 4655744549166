<script setup>
import { computed, onMounted, reactive, watch } from "vue";
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { convertToLowerCase, convertToUpperCase } from "../../../../api/base";

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const { currentRoute, push } = useRouter();

const searchData = reactive({
  showSearch: true,
  showOnlyMobile: false,
});
// const searchModel = reactive({
//   search: "",
//   cityID: 0,
//   townID: 0,
//   categoryID: 0,
//   subCategoryID: 0,
//   // companyID: 0,
//   popular: 0,
//   path: "",
// });

onBeforeRouteLeave((to, from) => {
  // searchModel.search =
  //   to.params?.search == undefined ? "" : t(`slug.${to.params.search}`);

  store.commit("customercompany/updateSearchModel", {
    ...searchModel.value,
    search: to.params?.search == undefined ? "" : t(`slug.${to.params.search}`),
    path: "",
  });

  searchCustomerCompanies();
});
onBeforeRouteUpdate((to) => {
  // searchModel.search =
  //   to.params?.search == undefined ? "" : t(`slug.${to.params.search}`);
  // searchModel.search = to.params.search;

  store.commit("customercompany/updateSearchModel", {
    ...searchModel.value,
    search: to.params?.search == undefined ? "" : t(`slug.${to.params.search}`),
  });
  searchCustomerCompanies();
});
onMounted(() => {
  searchData.showOnlyMobile = window.screen.width < 1366;
  // const currentRoute = router.currentRoute.value;

  setTimeout(() => {
    searchData.showSearch = searchData.showOnlyMobile ? false : true;
  }, 1);

  fetchCompanyCategories();
  fetchCompanyCities();

  // searchModel.search =
  //   currentRoute.value.params?.search == (undefined || "")
  //     ? ""
  //     : currentRoute.value.name == "search"
  //     ? currentRoute.value.params.search ?? ""
  //     : t(`slug.${currentRoute.value.params.search}`) ==
  //         (undefined || "" || null) ||
  //       t(`slug.${currentRoute.value.params.search}`).includes("slug.")
  //     ? currentRoute.value.params.search ?? ""
  //     : t(`slug.${currentRoute.value.params.search}`);
  // searchModel.search =
  //   currentRoute.params?.search == undefined
  //     ? ""
  //     : currentRoute.params?.search == ""
  //     ? ""
  //     : t(`slug.${currentRoute.params.search}`) == ""
  //     ? ""
  //     : t(`slug.${currentRoute.params.search}`);
  // searchModel.search = currentRoute.params.search ?? "";
  // searchModel.categoryID = currentRoute.value.params.categoryID ?? 0;
  // searchModel.cityID = currentRoute.value.params.cityID ?? 0;

  store.commit("customercompany/updateSearchModel", {
    ...searchModel.value,
    search:
      currentRoute.value.params?.search == (undefined || "")
        ? ""
        : currentRoute.value.name == "search"
        ? currentRoute.value.params.search ?? ""
        : t(`slug.${currentRoute.value.params.search}`) ==
            (undefined || "" || null) ||
          t(`slug.${currentRoute.value.params.search}`).includes("slug.")
        ? currentRoute.value.params.search ?? ""
        : t(`slug.${currentRoute.value.params.search}`),
    categoryID: currentRoute.value.params.categoryID ?? 0,
    cityID: currentRoute.value.params.cityID ?? 0,
  });
  if (searchModel.value.cityID != 0) {
    fetchCompanyTowns();
    searchModel.value.townID = currentRoute.value.params.id ?? 0;
  }
  searchCustomerCompanies();
});

const searchModel = computed({
  // return store.state["customercompany"].searchModel;
  get() {
    return store.state["customercompany"].searchModel;
  },
  set(value) {
    store.commit("customercompany/updateSearchModel", value);
  },
});

const loadedSearchPrams = computed(() => {
  return store.state["customercompany"].loadedSearchPrams;
});
const searchPrams = computed(() => {
  return store.state["customercompany"].paramsValues;
});
const categoryList = computed(() => {
  const list = store.state["companycategory"].companyCategories?.map(
    (category) => {
      return {
        title: category.title,
        id: category.id,
      };
    }
  );
  return [{ title: "Seçiniz..", id: 0 }, ...list];
});
const subCategoryList = computed(() => {
  const list = store.state["companycategory"].companySubCategories?.map(
    (category) => {
      return {
        title: category.subTitle,
        id: category.id,
      };
    }
  );
  return [{ title: "Seçiniz..", id: 0 }, ...(list || [])];
});
const cityList = computed(() => {
  const list = store.state["companycity"].allCities?.map((city) => {
    return {
      title: city.title,
      id: city.id,
    };
  });
  return [{ title: "Seçiniz..", id: 0 }, ...list];
});
const townList = computed(() => {
  const list = store.state["companycity"].allCounties?.map((town) => {
    return {
      title: town.title,
      id: town.id,
    };
  });
  return [{ title: "Seçiniz..", id: 0 }, ...(list || [])];
});

watch(loadedSearchPrams, (newVal) => {
  newVal
    ? setTimeout(() => {
        assigmentSearchArgs();
      }, 10)
    : "";
});

const fetchCompanyCategories = async () => {
  await store.dispatch("companycategory/getCompanyCategories");
};
const fetchCompanySubCategories = async () => {
  await store.dispatch("companycategory/getCompanySubCategories", {
    categoryID: searchModel.value.categoryID,
  });
};
const fetchCompanyCities = async () => {
  await store.dispatch("companycity/getCities");
};
const fetchCompanyTowns = async (provinceID) => {
  await store.dispatch("companycity/getTowns", {
    cityID: provinceID ?? searchModel.value.cityID,
  });
};

const assigmentSearchArgs = () => {
  setTimeout(() => {
    const categoryID =
      categoryList.value?.find(
        (item) => item.title == searchPrams.value.category
      )?.id ?? 0;
    const provinceID =
      cityList.value?.find(
        (item) =>
          item.title == searchPrams.value.city?.toLocaleUpperCase("tr-TR")
      )?.id ?? 0;

    provinceID != 0 ? fetchCompanyTowns(provinceID) : "";

    setTimeout(() => {
      const districtID =
        townList.value?.find((item) => item.title == searchPrams.value.town)
          ?.id ?? 0;

      store.commit("customercompany/updateSearchModel", {
        ...searchModel.value,
        categoryID: categoryID,
        cityID: provinceID,
        townID: districtID,
      });
    }, 150);
  }, 100);
};
const onChangeProvince = () => {
  store.commit("customercompany/updateSearchModel", {
    ...searchModel.value,
    townID: 0,
  });
  fetchCompanyTowns();
};
const searchCustomerCompanies = async (data) => {
  searchData.showSearch =
    data == false && searchData.showOnlyMobile ? false : true;
  if (
    currentRoute.value.name == "optional-search" &&
    currentRoute.value.params?.category
  ) {
    searchModel.value.path = currentRoute?.value.fullPath.slice(1);
  }

  store.commit("customercompany/loaded", false);
  await store.dispatch(
    "customercompany/getCustomerCompanies",
    searchModel.value
  );
};
const searchFromSidebar = async (data) => {
  searchData.showSearch =
    data == false && searchData.showOnlyMobile ? false : true;
  if (
    currentRoute.value.name == "optional-search" &&
    currentRoute.value.params?.category
  ) {
    searchModel.value.path = currentRoute?.value.fullPath.slice(1);
    const { categoryID, cityID, townID } = searchModel.value;
    const { params } = currentRoute.value;

    const paramArgs = {
      category:
        categoryID == 0
          ? params?.category
          : t(
              `categorySlug.${
                categoryList.value?.find((item) => item.id == categoryID).id
              }`
            ),
      province:
        cityID == 0
          ? params?.province ?? null
          : convertToLowerCase(
              convertToUpperCase(
                cityList.value?.find((item) => item.id == cityID).title
              )
            ),
      district:
        townID == 0
          ? params?.district ?? null
          : convertToLowerCase(
              convertToUpperCase(
                townList.value?.find((item) => item.id == townID).title
              )
            ),
    };
    if (categoryID != 0 || cityID != 0 || townID != 0) {
      store.commit("customercompany/updateSearchModel", {
        ...searchModel.value,
        path: paramArgs.category,
      });
      push({
        name: "optional-search",
        params: {
          category: paramArgs.category,
          province: paramArgs.province,
          district: paramArgs.district,
        },
      }).then(() => {
        window.location.reload();
      });

      // setTimeout(() => {
      //   window.refresh();
      // }, 300);
    }
    return;
  }

  store.commit("customercompany/loaded", false);
  await store.dispatch(
    "customercompany/getCustomerCompanies",
    searchModel.value
  );
};
</script>
<template>
  <div
    id="search-sidebar"
    class="col-lg-3 theiaStickySidebar"
    style="position: sticky;"
  >
    <div class="stickyside">
      <div class="card filter-card" v-if="searchData.showSearch">
        <div class="card-body">
          <!-- <h4 class="card-title">Arama Filtreleri</h4> -->
          <form id="search_form">
            <div class="filter-widget">
              <div class="filter-list">
                <h4 class="filter-title">İçeriğe Göre Ara</h4>
                <input
                  type="text"
                  v-model="searchModel.search"
                  class="form-control"
                  placeholder="Hizmet, Salon Türü, Salon Adı..."
                  @keydown.enter.prevent="searchFromSidebar"
                />
              </div>

              <div class="filter-list">
                <h4 class="filter-title">Ana Kategoriler</h4>
                <select
                  class="form-control selectbox select form-select"
                  v-model="searchModel.categoryID"
                  @change="fetchCompanySubCategories"
                >
                  <!-- <template #first>
                    <option :value="0" style="color: rgba(177, 187, 196, 1)">{{
                      searchModel.categoryID == 0
                        ? "Kategori Seçin"
                        : "Kategori Değiştirin"
                    }}</option>
                  </template> -->
                  <option
                    v-for="category in categoryList"
                    :value="category.id"
                    >{{ category.title }}</option
                  >
                </select>
              </div>

              <div class="filter-list">
                <h4 class="filter-title">Alt Kategoriler</h4>
                <select
                  class="form-control selectbox select form-select"
                  v-model="searchModel.subCategoryID"
                >
                  <option
                    v-for="subCategory in subCategoryList"
                    :value="subCategory.id"
                    >{{ subCategory.title }}</option
                  >
                </select>
              </div>

              <div class="filter-list">
                <h4 class="filter-title">Şehirler</h4>
                <select
                  class="form-control selectbox select form-select"
                  v-model="searchModel.cityID"
                  @change="onChangeProvince"
                >
                  <option v-for="city in cityList" :value="city.id">{{
                    city.title
                  }}</option>
                </select>
              </div>

              <div class="filter-list">
                <h4 class="filter-title">İlçeler</h4>
                <select
                  class="form-control selectbox select form-select"
                  v-model="searchModel.townID"
                >
                  <option v-for="town in townList" :value="town.id">{{
                    town.title
                  }}</option>
                </select>
              </div>

              <!-- <div class="filter-list">
                <h4 class="filter-title">Şehirler</h4>
                <input
                  class="form-control"
                  type="text"
                  v-model="searchModel.cityID"
                  placeholder=""
                />
              </div> -->
            </div>
            <button
              class="btn bg-dark-purple pl-5 pr-5 pt-2 pb-2 btn-block get_services w-100"
              type="button"
              @click="searchFromSidebar(false)"
            >
              Ara
            </button>
          </form>
        </div>
      </div>

      <div
        v-else
        class="search-box mb-3 mt-0"
        style="border: 1px solid rgba(0,0,0,.125); border-radius: 50px; padding: 12px 24px;"
        @click="searchData.showSearch = !searchData.showSearch"
      >
        <form>
          <div class="d-inline-flex align-items-center">
            <span class="material-icons-round color-black bficon">
              tune
            </span>
            <div class="ms-3 d-flex align-items-center">
              <span class="ms-0 color-black" style="font-size: 18px;"
                >Filtreleme için tıklayın..</span
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-dark-purple {
  border-radius: 30px;
}
.stickyside {
  margin-right: 60px;
}
</style>
