<script setup></script>

<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="font-size-14">
        <h4>MADDE 1 - Giriş</h4>

        <p>
          İşbu sözleşmede ixirlife Teknoloji A.Ş. (“Hizmet Sağlayıcı”)
          tarafından https://www.ixirlife.com/ web sitesi (“Web Sitesi”)
          kanalıyla Hizmet Alıcılara sunulan hizmetlerin Hizmet Alıcılar
          tarafından Web Sitesi kanalıyla sipariş edilmesi ve bunların satışına
          ilişkin hüküm ve şartları belirlenmektedir.
        </p>

        <p>
          Hizmet Sağlayıcı ve Hizmet Alıcı bundan böyle tek başına “Taraf” ve
          birlikte “Taraflar” olarak anılacaktır.
        </p>

        <p>
          <span class="font-weight-bold">“Başlangıç Paketi”</span> adı altında
          verilen ixirlife
          <span class="font-weight-bold"
            >Güzellik Merkezi(neden Güzellik Merkezi)</span
          >
          yönetim yazılımının içeriği aşağıdaki gibidir:
        </p>

        <ol>
          <li>Randevu yönetim sistemi (100 Ad./AY),</li>
          <li>Müşteri kayıt sistemi,</li>
          <li>Sms yönetimi</li>
          <li>Ürün / Hizmet yönetimi</li>
          <li>Tek kullanıcı</li>
        </ol>

        <p>
          <span class="font-weight-bold">“Standart Paket”</span> adı altında,
          “Başlangıç Paketi”’ndekilere ek olarak verilen yazılımın içeriği
          aşağıdaki gibidir:
        </p>

        <ol>
          <li>Sınırsız kullanıcı</li>
          <li>Kasa (Gelir/Gider) Yönetimi</li>
          <li>Paket / Seans Yönetimi</li>
          <li>Personel Prim ve Ciro Yönetimi</li>
          <li>Fatura ve Stok Takibi</li>
          <li>Çoklu Şube Yönetimi</li>
          <li>Gelişmiş Raporlama</li>
        </ol>

        <p>
          <span class="font-weight-bold">“Profesyonel Paket”</span> adı altında,
          “Standart Paket”’tekilere ek olarak verilen yazılımın içeriği
          aşağıdaki gibidir:
        </p>

        <ol>
          <li>Sınırsız kullanıcı</li>
          <li>Kasa (Gelir/Gider) Yönetimi</li>
          <li>Paket / Seans Yönetimi</li>
          <li>Personel Prim ve Ciro Yönetimi</li>
          <li>Fatura ve Stok Takibi</li>
          <li>Çoklu Şube Yönetimi</li>
          <li>Gelişmiş Raporlama</li>
        </ol>
      </div>

      <div class="font-size-14">
        <h4>MADDE 2 - Tanımlar</h4>

        <p>Bu sözleşmede aşağıda belirtilen terimler kullanılmaktadır:</p>

        <ol>
          <li>
            Hizmet Sağlayıcı – Muallimköy Mah. Deniz Cad. No:143/5 İç Kapı No:40
            Gebze / KOCAELİ adresinde mukim ixirlife Teknoloji A.Ş.’yi ifade
            eder.
          </li>
          <li>
            Hizmet Alıcı – Hizmet Sağlayıcının paket hizmetlerini kullanan ve
            işbu sözleşmedeki şartlar çerçevesinde bu hizmetleri sipariş edecek
            olan, söz konusu hizmetlerin verilmesi için imzalanacak sözleşmenin
            tarafı olan ve işbu sözleşmeye uymakla yükümlü olan her türlü gerçek
            kişi, işletme veya tüzel kişiyi ifade eder.
          </li>
          <li>
            ixirlife (“Yazılım” veya “Sistem”) -
            <a href="http://www.ixirlife.com/" target="_blank"
              >www.ixirlife.com</a
            >
            web sitesi ve/veya ixirlife mobil uygulamaları üzerinden,
            işletmelerin randevu, işlem, personel özlük, müşteri ve finansal
            verilerini yönetmesi maksadıyla sunulan işletme yönetim uygulamasını
            ifade eder.
          </li>
          <li>
            Kullanıcı – Profesyoneller dışında ixirlife.com sitesine hizmet
            alıcı tarafından kaydedilen müşterilerdir.
          </li>
          <li>
            Hizmet/Hizmetler – Her türlü yasal hakları Hizmet Sağlayıcıya ait
            olan ve Kullanıcı’ya ixirlife.com adresi üzerinden sunulan Güzellik
            Merkezi yönetim yazılımıdır.
          </li>
          <li>
            Profesyonel – Herhangi bir güzellik dalında uzmanlaşmış kuaför,
            stilist, estetisyen, berber, makyöz, masör, manikürist ve yukarıda
            belirtilmeyen ve güzellik ve bakım faaliyetinde bulunan her türlü
            diğer güzellik çalışanını ifade eder.
          </li>
          <li>
            Profil – Hizmet Alıcı, Kullanıcılar ve Profesyonel’e ilişkin bilgi
            ve yorumları ifade eder.
          </li>
          <li>
            Paket/Paketler – Hizmet Alıcı'nın somut ihtiyaçları dikkate alınarak
            öngörülen ve işbu sözleşmede belirtilen ödeme koşullarının
            gerçekleşmesini müteakip belirli bir süre için Hizmet Alıcı’nın
            Profilinin kullanımına sunulan ek fonksiyonlar grubunu ifade eder.
            İşbu fonksiyonlar grubuna Başlangıç Paketi/Standart Paket/
            Profesyonel Paket adı da verilir. Başlangıç Paket, Standart Paket ve
            Profesyonel Paket ile birlikte “Paket” veya “Paketler” olarak
            anılacaktır.
          </li>
          <li>
            Randevu Sistemi – Her türlü yasal hakları Hizmet Sağlayıcıya ait
            olan ve kullanım hakkının bu Sözleşme ile belirlenen şartlar
            çerçevesinde Hizmet Alıcıya verdiği bilgisayar yazılımını ifade
            eder. Hizmet Alıcı Randevu Sistemi’ne Web Sitesi üzerinden
            ulaşabilir. Randevu Sistemi, Kullanıcı’nın Web Sitesi üzerinden
            belirli bir Profesyonel’den randevu almasını veya Profesyonel’in
            Randevu Sistemi’ne bağımsız olarak müşteri eklemesini sağlayan, bu
            kapsamda Hizmet Alıcı’nın ve Profesyonel’in kendi Randevu Sistemi’ni
            yönetebilmesine imkan veren bir sistemdir.
          </li>
          <li>
            Sipariş – Paket siparişi vermek için doldurulan, Hizmet Alıcı
            tarafından imzalanan, Paket alma koşullarını tanımlayan formu ifade
            eder. Hizmet Alıcı’nın Sipariş’i geçmesi bu sözleşmenin
            uygulanmasının kabulü anlamına gelir.
          </li>
          <li>
            Ödeme Dönemi – Hizmet Alıcı’nın kendisine sağlanan hizmetler için
            ödeme yaptığı dönemi ifade eder. Ödeme Dönemi Hizmet Sağlayıcı
            tarafından belirlenecektir.
          </li>
          <li>
            Sözleşme – Taraflar arasında Sipariş verme yoluyla işbu sözleşmede
            belirtilen şartlar dahilinde Paket hizmeti sağlanması için imzalanan
            sözleşmeyi ifade eder.
          </li>
          <li>
            Sms - Hizmet Alıcı tarafından ixirlife arayüzü kullanılarak, metni
            hizmet alıcı tarafından belirlenmiş ve yasal yükümlülüğü tamamen
            Hizmet Alıcıya ait olan, gönderim altyapısı ve faturalandırılması
            Gold Telekomünikasyon İletişim San. ve Tic. Ltd. Şti. tarafından
            sağlanan, ixirlife’'nın hiçbir yasal aracılık statüsü taşımadığı sms
            gönderim sistemidir.
          </li>
          <li>
            Kullanıcı, Uygulama’dan faydalanmak için Şirket tarafından talep
            edilen bilgileri tam, doğru ve güncel bir şekilde sağlayarak işbu
            Sözleşme’yi onaylaması gerektiğini bildiğini beyan eder. Kullanıcı
            statüsünün tesisi sırasında sağlanan bilgilerde herhangi bir
            değişiklik olması halinde, söz konusu bilgiler derhal
            güncellenecektir. Bu bilgilerin eksik veya gerçeğe aykırı olarak
            verilmesi ya da güncel olmaması nedeniyle Site ya da Uygulama’ya
            erişim sağlanamamasından ve bunlardan faydalanılamamasından Şirket
            sorumlu değildir.
          </li>
          <li>
            Kullanıcı, 18 yaşını doldurmuş olduğunu ve işbu Sözleşme’yi akdetmek
            için gereken yasal ehliyete sahip bulunduğunu beyan eder.
            Kullanıcı’nın Site’ye bir işletme adına erişiyor olması halinde,
            Kullanıcı buna ilişkin gerekli yetkiyi haiz olduğunu kabul ve beyan
            eder. Bu durumda Kullanıcı statüsü ile hak ve yükümlülükler söz
            konusu işletmeye ait olacaktır.
          </li>
        </ol>
      </div>

      <div class="font-size-14">
        <h4>MADDE 3 - Sözleşmeleri İmzalama ve Sonlandırma Prosedürü</h4>

        <ol>
          <li>
            Taraflar arasında Sözleşme akdedilmeden önce Hizmet Alıcı işbu
            sözleşmeye göre bir Sipariş verip aşağıdaki yollardan biriyle Hizmet
            Sağlayıcıya imzalı siparişi iletir ve Hizmet Sağlayıcı da teslim
            aldığını teyit eder.<br />
            Siparişin iletilme şekilleri;
            <ol>
              <li>
                imzalı siparişin Hizmet Sağlayıcı tarafından belirtilen faks
                numarasına gönderilmesi ve akabinde imzalı Sipariş aslının
                Hizmet Sağlayıcı tarafından belirtilen adresine iletilmesi veya
              </li>
              <li>
                imzalı siparişin bilgi@ixirlife.com e-mail adresine gönderilmesi
                ve akabinde imzalı Sipariş aslının Hizmet Sağlayıcı tarafından
                belirtilen adresine iletilmesi veya
              </li>
              <li>
                imzalı siparişin Hizmet Sağlayıcının ticari temsilcisine
                doğrudan gönderilmesi veya elden teslim edilmesi veya
              </li>
              <li>
                siparişin
                <a href="http://www.ixirlife.com/" target="_blank"
                  >www.ixirlife.com</a
                >
                ve/veya ixirlife mobil uygulaması üzeriden Hizmet Alıcı
                tarafından dijital ortamda, kayıt işlemi sırasında veya
                sonrasında “sözleşmeyi okudum, kabul ediyorum” bölümünde
                belirtilen şartları okuyup onaylamak suretiyle, işlemi
                gerçekleştirdiği IP adresi Hizmet Sağlayıcı tarafından kayıt
                altına alınarak kabul edilmesi
              </li>
            </ol>
            <br />
          </li>
          <li>
            Hizmet Alıcı sistemi kullanmaya devam ettiği süre boyunca,
            sözleşmenin en güncel halindeki şartları sağlamaya devam ettiğini,
            sisteme kendi hesabı veya oluşturduğu alt hesaplar üzerinden giriş
            yapılmak suretiyle kullanmaya devam edilmesinin sözleşmenin en
            güncel halindeki şartları kabul ettiği anlamına geleceğini taahhüt
            eder.
          </li>
          <li>
            Hizmet Alıcı Profilini oluştururken sağladığı ad soyad, adres,
            telefon numarası, e-posta adresi, firma adı, firma bilgileri vb.
            bilgilerin doğruluğundan sorumludur.
          </li>
          <li>
            Paketler’in güncel fiyatları ixirlife.com adresindeki fiyat
            listesinden temin edilebilir. Hizmet Sağlayıcı fiyat listesini
            herhangi bir zamanda tek taraflı olarak değiştirme hakkını saklı
            tutar. Fiyat listesindeki değişiklikler Hizmet Alıcı için güncel
            sözleşmenin uzatılmasıyla geçerli olacaktır.
          </li>
          <li>
            Sözleşmenin geçerlilik süresi 12 aydır. sözleşmenin geçerlilik
            süresinin sona ermesi üzerine, Taraflar’dan biri sözleşmeyi uzatmak
            istemediğini diğer Taraf’a bildirmediği sürece Sözleşme, aksi
            Taraflarca veya bu Sözleşme kapsamında belirlenmediği sürece, aynı
            koşullarla ve fesih bildirimi yapılana kadar bir aylık sürelerle
            yenilenir. Bildirim en geç sözleşmenin bitimine 1 ay kala
            yapılmalıdır.
          </li>
          <li>
            Siparişin alınmasından sonra Hizmet Sağlayıcı, Siparişi yerine
            getirmek için gerekli teknik ve insan kaynaklarını kullanarak
            hizmetleri vermeye başlar. Hizmet Sağlayıcı tarafından hizmetlerin
            devreye alınması, Hizmet Alıcı’nın Siparişinin kabul edildiği
            anlamına gelir.
          </li>
          <li>
            Hizmet Alıcı her zaman için sözleşmeyi Hizmet Sağlayıcıya bildirim
            yaparak tek taraflı feshetme hakkına sahiptir. Sözleşme, Hizmet
            Sağlayıcının fesih bildirimini aldığı ilgili hizmet ayının sonunda
            sonlanacaktır.
          </li>
          <li>
            Hizmet Alıcı’nın sözleşmede öngörülen yükümlülüklerini yerine
            getirmemesi durumunda Hizmet Sağlayıcı mahkemeye başvurmaya ve ihbar
            süresinin uygulanmasına gerek olmaksızın Sözleşmeyi derhal tek
            taraflı olarak ve tazminatsız sonlandırma hakkına sahip olacaktır.
            Böyle bir durumda Hizmet Sağlayıcı Hizmet Alıcı’dan Madde 9’da
            tanımlanan cezai tazminat ile ilgili hükümler uygulanacaktır.
          </li>
          <li>
            İşbu Sözleşme kapsamında belirtilen bildirimlerin alındığı tarih
            bildirimin Hizmet Sağlayıcının veya Hizmet Alıcının aşağıda
            belirtilen adreslerinde teslim alındığı tarih olarak kabul
            edilecektir. Bildirimlerin yazılı olması gerekir, aksi halde
            bildirimler geçersiz sayılacaktır. Bir belgenin Hizmet Alıcı
            tarafından Siparişte belirtilen adreste ve Hizmet Sağlayıcı
            tarafından ise sözleşmede belirtilen adreste teslim alınması
            durumunda Taraflar söz konusu belgenin yazılı olarak gönderildiğini
            kabul edecektir. Şu kadar ki TTK 18/3 hükümleri saklıdır. Adres
            değişikliklerinin diğer Taraf’a 10 gün içerisinde bildirilmemesi
            durumunda bu madde kapsamında belirtilen adreslere yapılan
            tebligatlar usulüne uygun olarak yapılmış sayılacaktır.
          </li>
          <li>
            Hizmet Alıcı siparişte belirtilen Paket hizmetinin ilgili olduğu
            Profilde yer alan bilgi ve materyallerin doğruluğunu ve bu
            bilgilerin hiçbir yasa hükmünü ve/veya üçüncü şahısların haklarını
            ihlal etmediğini beyan ve taahhüt eder. İşbu beyan ve taahhüte
            aykırı bir durumun ortaya çıkması halinde 3. kişilerden gelecekler
            dahil her türlü talepten bizzat Hizmet Alıcı sorumlu olup Hizmet
            Sağlayıcının rücu hakkı saklıdır.
          </li>
          <li>
            Hizmet Alıcı, Profil oluşturmak veya fonksiyonları üzerindeki
            blokajı kaldırmak için gerekli materyalleri zamanında temin
            edecektir. Hizmet Alıcı’nın yukarıda belirtilen materyali siparişin
            imza tarihinden itibaren 7 iş günü içerisinde temin etmemesi Hizmet
            Sağlayıcı tarafından hizmetlerin verilmesini durdurmayacaktır.
            Profil Sipariş geçildiğinde alınan bilgilere veya materyallere
            dayanarak yayınlanacaktır.Hizmet Alıcı hizmetin bu durumda usulüne
            uygun olarak verildiğini kabul edeceğini ve bu konuda Hizmet
            Sağlayıcıya herhangi bir talepte bulunma hakkına sahip olmayacağını
            kabul eder.
          </li>
          <li>
            Hizmet Alıcı birden fazla Profil için “Başlangıç Paket”, “Standart
            Paket” ve “Profesyonel Paket” satın aldığı takdirde, Paket
            hizmetinin verilmeye başlama zamanı satın alınan Profiller’den biri
            için bu hizmetlerin verilmeye başlandığı tarih olarak kabul
            edilecektir.
          </li>
          <li>
            Taraflar, Hizmet Sağlayıcının hizmet sunumunu gerçekleştireceği web
            sitesinin (alan adı da dahil) tasarımını, arayüzlerini, panellerini,
            görsellerini ve fonksiyonlarını Hizmet Sağlayıcının
            değiştirebileceği konusunda mutabıktırlar. Taraflar, web sitesindeki
            tasarım ve görsellerin tüm fikri mülkiyet haklarına Hizmet
            Sağlayıcının sahip olacağı konusunda mutabıktırlar. Hizmet Alıcıya
            ilişkin sunulan bilgilerin Hizmet Sağlayıcı veya bağlı kişi veya
            şirketleri (ana ortakları veya hakim ortakları) tarafından arşiv,
            yedekleme, bilgi saklama ve istatistiki sebeplerle işlenebileceğini
            ve bunların iş geliştirme amacıyla Hizmet Sağlayıcı veya bağlı kişi
            veya şirketleri (ana ortakları veya hakim ortakları) tarafından iş
            ortakları ve üçüncü kişiler ile paylaşabileceğini Hizmet Alıcı kabul
            eder. Taraflar Hizmet Sağlayıcının, hizmet sunumunu
            gerçekleştireceği web sitesinde yer alan mecralara Hizmet
            Sağlayıcının faaliyetleri ile ilgili ve mevzuata uygun tanıtım,
            reklamlar alabileceği ve verebileceği, blog kanalları açabileceği,
            forumlara erişim sağlayabileceği konusunda mutabıktırlar.
          </li>
          <li>
            Hizmet, Profiller, yazılım sistemi ve “Başlangıç Paket”, “Standart
            Paket”, “Profesyonel Paket” ile ilgili fonksiyonlara ilişkin telif
            hakları da dahil her türlü fikri mülkiyet hakları Hizmet Sağlayıcıya
            aittir.
          </li>
          <li>
            Hizmet Sağlayıcı tarafından yapılan tüm işlere ilişkin telif hakları
            ile fikri mülkiyet hakları ile korunan diğer işlerin ve
            materyallerin bağımsız parçaları olan unsurlara ilişkin telif
            hakları oluşturuldukları tarihten itibaren Hizmet Sağlayıcıya
            aittir.
          </li>
          <li>
            Hizmet Alıcı hizmetlerde sergilenen grafik tasarımlarla ve
            Hizmetlerle ilgili herhangi bir hak sahibi olmayacaktır.
          </li>
          <li>
            Hizmet Alıcı girişimsel faaliyetlerin yürütülmesi ile ilgili
            Hizmetleri kullandığını teyid eder. Tüketicinin korunmasına ilişkin
            mevzuat uygulanmaz.
          </li>
        </ol>
      </div>

      <div class="font-size-14">
        <h4>MADDE 4 - Ödeme Şartları</h4>

        <ol>
          <li>
            Paket hizmetler için Hizmet Alıcı tarafından ödenecek bedel her bir
            Siparişte belirtilecektir.
          </li>
          <li>
            İşbu hizmetlerin temini için ilk fatura Hizmet Alıcı’nın hizmetleri
            almaya başladığı tarihten itibaren düzenlenecek olup, müteakip
            faturalar da İlk Fatura Tarihini takip eden 1 er ay aralıklarla
            düzenlenecektir. Hizmet Alıcı düzenlenen her bir fatura bedelini
            fatura düzenlenmesini takip eden 14 gün içerisinde ödemekle yükümlü
            olacaktır. Hizmet aktivasyonu, ilk faturanın ödenmesine müteakiben
            yapılacaktır.
          </li>
          <li>
            Hizmet Alıcı’nın Hizmet Sağlayıcı tarafından lisanslı ödeme aracı
            kuruluşları veya mukim bankaların altyapısını kullanarak oluşturulan
            online ödeme sistemi ve/veya mail order yoluyla sağladığı kredi
            kartı bilgileri ile işbu Sözleşme kapsamında Hizmetin yürürlükte
            olduğu süre boyunca ya da sözleşmenin ilgili hükümleri gereği
            Sözleşmenin sona ermesi akabinde düzenleyeceği her bir faturanın
            tahsilatı otomatik olarak gerçekleştirilecektir. sözleşmenin iptali
            ile birlikte Hizmet Alıcı’nın tahakkuk etmiş başka bir borcu
            kalmaması halinde otomatik tahsilat sistemi başka bir bildirime
            gerek kalmaksızın kendiliğinden iptal edilir. Hizmet Alıcı’nın
            hesabından mükerrer veya sehven bir tahsilat işleminin
            gerçekleştirilmesi halinde, Hizmet Sağlayıcının Vergi Usul Kanunu
            uyarınca tutmakla mükellef olduğu hesap kayıtlarının incelenmesine
            müteakip sehven tahsil edilen bu bedel Hizmet Alıcıya iade edilir.
          </li>
          <li>
            Hizmet Sağlayıcı, Hizmet Alıcıya kendi takdirinde olmak üzere
            indirim sağlayabilir (bu indirimin tutarı ve şartları Paket
            hizmetlerine ilişkin faturada veya başka bir belgede belirtilir).
            İndirimin temini ve tutarı Hizmet Sağlayıcı tarafından önerilen ek
            fonksiyonlara bağlıdır. Özellikle bu indirim Profesyonel’in Hizmet
            Sağlayıcının kendi web sitesinde (varsa) önerdiği ek fonksiyonları
            kurmasıyla bağlantılı olabilir. Şüpheye mahal vermemek adına
            belirtilmelidir ki Hizmet Sağlayıcı tarafından yapılan indirim bunun
            devamlılık arz edeceği anlamına gelmez. Hizmet Sağlayıcı kendi
            takdirine bağlı olmak üzere daha önce uyguladığı indirimi her zaman
            sonlandırma hakkına sahiptir. Böyle bir durumda Hizmet Alıcı’nın
            hiçbir talep hakkı bulunmamaktadır.
          </li>
          <li>
            Hizmet Sağlayıcının faturalarında belirtilen Paket hizmetlerinin
            fiyatı, sağlanan indirimler hariç, herhangi bir stopaj veya kesinti
            yapılmadan KDV dahil bedel üzerinden tam olarak ödenmelidir.
          </li>
          <li>
            Hizmetler için belirtilen ödemenin vadesinde gerçekleştirilmemesi
            halinde Madde § 9 dördüncü bentte tanımlanan cezai tazminata ilişkin
            hükümler aynen uygulanır. Hizmet Sağlayıcının cezai tazminatı aşan
            zararlarını talep etme hakkı saklıdır.
          </li>
          <li>
            Kullanıcı’nın hesabının 3 (üç) ay boyunca pasif olması halinde
            Şirket işbu Sözleşme’yi feshedebilecektir.
          </li>
        </ol>
      </div>

      <div class="font-size-14">
        <h4>MADDE 5 - Verilen Hizmetlerin Türü ve Kapsamı</h4>

        <ol>
          <li>
            Paket hizmeti, siparişte gösterilen Hizmet Alıcıların Profillerine
            yüklenir.
          </li>
          <li>
            Profiller’inde Paket hizmetinin aktif hale getirilmesi gereken
            Hizmet Alıcı’lar web sitesine kaydedilmeli ve ayrıca bu kişiler
            Hizmet Sağlayıcı tarafından belirtilen şartlar doğrultusunda yetki
            almalıdır.
          </li>
        </ol>
      </div>

      <div class="font-size-14">
        <h4>MADDE 6 - Paket Hizmetlerinin Verildiği Şartlar</h4>

        <p>
          Hizmet Sağlayıcı Paket hizmetlerini Hizmet Alıcıya Siparişte ve bu
          Sözleşmede belirtilen kapsamda ve şartlarda temin edecektir.
        </p>

        <ol>
          <li>
            Hizmet Sağlayıcı:
            <ol>
              <li>
                Sözleşmenin geçerlilik süresi içerisinde Hizmet Alıcı tarafından
                gösterilen Profiller’deki hizmetlerini aktive edecek ve
                tutacaktır;
              </li>
              <li>
                Hizmet Alıcı’ nın Profilindeki verilerin düzeltmesine ilişkin
                paneli aktive edecektir;
              </li>
              <li>
                “Başlangıç”, “Standart” ve“Profesyonel” Paketlerinin aktif
                duruma getirildiği Profilin taslağının hazırlanmasında, paketin
                aktivasyon tarihini izleyen 21 gün içinde yardım sağlayacaktır.
              </li>
            </ol>
          </li>
          <br />
          <li>
            Hizmet Alıcı:
            <ol>
              <li>Ödemelerini zamanında yapacak;</li>
              <li>Profilde tam ve doğru bilgiler tutacak;</li>
              <li>
                Profilde tuttuğu Kullanıcı kişisel verileri için Kullanıcılardan
                gerekli izinleri alacak, gerekli izinleri almadan kayıt ettiği
                kişisel verilerden kaynaklı Hizmet Sağlayıcıya gelebilecek her
                türlü yasal ve cezai yaptırımdan tamamen ve yalnızca Hizmet
                Alıcı olarak kendisinin sorumlu olduğunu kabul etmiş sayılacak;
              </li>
              <li>Güncel liste fiyatlarından yüksek fiyat yazmayacak;</li>
              <li>
                Hizmetlerin doğru verilmesi ve özellikle Profil’lerin Hizmet
                Sağlayıcı tarafından gösterilen şekilde tamamlanması için Hizmet
                Sağlayıcı ile yakından işbirliği yapacaktır.
              </li>
              <li>
                SMS hizmeti kapsamında gönderim yapacağı SMS lerin yasal olarak
                belirtilen şartları sağladığına emin olacak ve yasal
                yükümlülüğün kendinde olacağını kabul edecektir.
              </li>
            </ol>
          </li>
          <br />
          <li>
            Taraflardan her biri diğer Taraf hakkında bu Sözleşmenin ifası
            dolayısıyla öğrendiği, üçüncü kişilerce yasal yollarla zaten
            bilinenler dışındaki tüm bilgileri sır olarak kabul etmeyi ve bu
            bilgileri diğer Taraf’ın yazılı izni olmadan üçüncü kişi ya da
            kuruluşlara vermemeyi, açıklamamayı, kamuya duyurmamayı ya da bu
            şekilde sonuçlanacak davranışlardan kaçınmayı taahhüt ederler.
            Taraflar’ın bu Sözleşmenin ifası dolayısıyla birbirleri ve Sözleşme
            konusu hakkında öğrendikleri ve üçüncü kişilerce yasal yollarla
            zaten bilinenler dışındaki her türlü bilgi bu madde kapsamındadır.
            Gizliliğin ihlali halinde Taraflar diğer Taraf’ın her türlü zarar ve
            ziyanını tazmin etmeyi peşinen kabul ve taahhüt eder. İşbu
            Sözleşmenin herhangi bir nedenle sona ermesi hali bu maddede yer
            alan yükümlülüklerin sona ermesine sebep olmaz.
          </li>
          <li>
            Bu sözleşmeden kaynaklanan bilgilerin gerek Hizmet Alıcı ve gerekse
            3. kişiler tarafından kullanılması veya açıklanması ve sözleşmenin
            ifası ile ilgili olmayan amaçlar için alınması durumunda Hizmet
            Alıcı her türlü zarar ve talepten bizzat sorumludur.
          </li>
          <li>
            Hizmet Alıcı, Hizmet Sağlayıcının IT sistemini bu Sözleşme kapsamı
            dışında kullanamaz. Bu kapsamda 3. kişilerden gelebilecek her türlü
            talepten Hizmet Alıcı sorumlu olup Hizmet Sağlayıcının Hizmet
            Alıcıya rücu hakkı saklıdır. Hizmet Alıcı’nın Hizmet Sağlayıcının IT
            sistemine bağlantı için gerekli ekipmanına ilişkin teknik şartlar
            şunlardır:
            <ol>
              <li>internet erişimi,</li>
              <li>cep telefonu numarası,</li>
              <li>e-posta adresi,</li>
              <li>web tarayıcısı.</li>
              <li>
                internet erişimi olan bilgisayar, tablet veya akıllı telefon
              </li>
            </ol>
          </li>
          <br />
          <li>
            Paket hizmeti süreklilik esasına göre temin edilir. Hizmet Alıcı bu
            hizmetlerin verilişinin askıya alınmasını talep etme hakkına sahip
            değildir.
          </li>
          <li>
            Hizmet Sağlayıcı Paket hizmeti bedellerinin tamamı veya bir kısmının
            Hizmet Alıcı tarafından geç ödenmesi durumunda hizmetlere erişimi
            durdurmak hakkına sahip olacaktır.
          </li>
          <li>
            Hizmet Sağlayıcının görüşüne göre Profilin Hizmet Sağlayıcının
            işiyle rekabet etme amacıyla veya kabul edilemez başka bir amaçla
            kullanılması halinde, Hizmet Sağlayıcı hizmetlerin teminini
            durdurmak veya hizmetlerin verilmesini herhangi bir zamanda
            reddetmek hakkına sahip olacaktır. İşbu maddeye aykırılık sebebiyle
            Hizmet Sağlayıcı nezdinde doğan her türlü zarardan da bizzat Hizmet
            Alıcı sorumludur.
          </li>
        </ol>
      </div>

      <div class="font-size-14">
        <h4>MADDE 7 - ixirlife Güzellik Merkezi Yönetim Yazılımı</h4>

        <ol>
          <li>
            Güzellik Merkezi Yönetim Yazılımı’nin aktive edilmesi üzerine Hizmet
            Sağlayıcı Hizmet Alıcıya, sözleşmenin geçerlilik süresi ile sınırlı,
            gayrimünhasır ve yalnızca 7.2 maddesinde belirtilen kullanımlara
            ilişkin olmak üzere web sitesinde kullanmak üzere bir lisans
            vermektedir. Bu lisansın amacı Hizmet Alıcı’nın web sitesi ve özel
            mobil uygulamalar üzerinden Güzellik Merkezi Yönetim Yazılımı’na
            erişmesini sağlamaktadır. Bu lisans Güzellik Merkezi Yönetim
            Yazılımı’nın aktivasyon tarihindeki mevcut versiyonunu ve Hizmet
            Sağlayıcı tarafından Hizmet Alıcıya erişme izni verilmişse, Hizmet
            Sağlayıcı tarafından değiştirilen versiyonlarını yukarıda belirtilen
            zaman sınırı ve kullanma amacı ile sınırlı olmak üzere kullanma
            hakkını kapsar. Lisansın işbu maddede belirtilen kapsam dışında
            kullanması sonucunda 3. kişilerden gelebilecek her türlü talepten
            Hizmet Alıcı sorumlu olup Hizmet Sağlayıcının Hizmet Alıcıya rücu
            hakkı saklıdır.
          </li>
          <li>
            Bu lisans yalnızca aşağıda belirtilen kullanımlar için
            verilmektedir:
            <ol>
              <li>
                Güzellik Merkezi Yönetim Yazılımı’nın dağıtılması – Hizmet Alıcı
                tarafından seçilen yerde ve zamanda İnternet üzerinden kullanım
              </li>
              <li>
                Güzellik Merkezi Yönetim Yazılımı’nın çoğaltılması – Güzellik
                Merkezi Yönetim Yazılımı’nın düzgün çalışması için gerekli
                bilgisayar belleğinde veya bir başka analog cihazda geçici
                çoğaltımı (örn. tarama).
              </li>
            </ol>
          </li>
          <br />
          <li>
            Lisans Hizmet Sağlayıcı tarafından Hizmet Alıcıya erişme izni
            verildiği andan Sözleşme sona erinceye veya iptal edilinceye kadar
            sınırlı süreli olarak düzenlenmiştir.
          </li>
          <li>
            Aşağıdakiler de dahil olmak üzere Güzellik Merkezi Yönetim Yazılımı
            ile ilgili olarak Hizmet Alıcı’nın uygunsuz veya bu sözleşmeyi ihlal
            eden davranışları olması durumunda Hizmet Sağlayıcı Güzellik Merkezi
            Yönetim Yazılımı’na erişimi bloke etme hakkına sahip olacaktır.
            Ayrıca aşağıdaki durumların gerçekleşmesi halinde 3. kişilerden
            gelebilecek her türlü talepten Hizmet Alıcı sorumlu olup Hizmet
            Sağlayıcının Hizmet Alıcıya rücu hakkı saklıdır:
            <ol>
              <li>
                Güzellik Merkezi Yönetim Yazılımı’nın başka kullanıcıların
                internet kullanımına zarar verecek şekilde kullanılması,
              </li>
              <li>Spam mail gönderilmesi,</li>
              <li>
                Kullanıcılar ve/veya Profesyoneller’in bilgileriyle oynanması,
              </li>
              <li>
                Güzellik Merkezi Yönetim Yazılımı’nın kaynak kodu ile oynanması,
              </li>
              <li>
                Güzellik Merkezi Yönetim Yazılımı’nı yetkisiz şekilde çoğaltma
                girişimleri,
              </li>
              <li>Hizmet Sağlayıcının kişisel çıkarlarının ihlali,</li>
              <li>Hizmet Sağlayıcıya karşı haksız rekabet girişimleri,</li>
              <li>Sözleşmenin ihlali,</li>
            </ol>
          </li>
        </ol>

        <p>
          Hizmet Sağlayıcının lisansı iptal etme kararı Hizmet Alıcı tarafından
          siparişte belirtilen e-posta adresine bildirilecektir.
        </p>
      </div>

      <div class="font-size-14">
        <h4>MADDE 8 - Kişisel Bilgiler ve Veriler</h4>

        <ol>
          <li>
            Hizmet Alıcı sisteme girdiği ad soyad, telefon numarası, e-posta
            adresi, adres, doğum tarihi, yazılı ve görsel içerik vb. her türlü
            kişisel bilgilerin tutulması, depolanması ve işlenmesi için ilgili
            bilgilerin sahiplerinden gerekli izinleri almış olmak
            yükümlülüğündedir.
          </li>
          <li>
            Hizmet Alıcı sisteme girdiği kişisel bilgilerle alakalı Hizmet
            Sağlayıcı'nın yalnızca bir altyapı sağlayıcısı olarak rol aldığını
            ve Hizmet Sağlayıcı'nın Veri Sorumluluğu bağlamında hiçbir
            yükümlülüğü bulunmadığını, bu kişisel bilgilerden doğabilecek her
            türlü aykırılık için itiraz etme hakkı olmaksızın tüm sorumluluğun
            kendine ait olduğunu ve oluşabilecek cezai yaptırımların
            muhattabının kendisi olduğunu kabul eder.
          </li>
          <li>
            Hizmet Alıcı profili altında oluşturduğu hesaplar aracılığıyla
            ortakları, çalışanları veya herhangi bir üçüncü kişi veya kişiler
            tarafından sisteme girilen kişisel veriler konusunda tamamen ve
            yalnızca kendisi sorumlu olduğunu kabul eder.
          </li>
          <li>
            Hizmet Alıcı Profiline topluca eklenmek üzere Hizmet Sağlayıcıya
            dijital ortamda gönderdiği kişisel bilgileri içeren müşteri
            listelerinde bulunan tüm kişilerden gerekli veri izinlerinin
            alındığını, bu verilerin sisteme eklenmesinden doğacak her türlü
            sorumluluğun kendine ait olduğunu kabul eder.
          </li>
          <li>
            Hizmet Alıcı sisteme giriş yaptığı kendi hesabının ve Profili
            altında oluşturduğu, ortakları, çalışanları ve diğer üçüncü kişi
            veya kişiler tarafından kullanılan diğer kullanıcı hesaplarının
            giriş bilgilerinin güvenliğinden tamamen ve yalnızca kendisi
            sorumludur. Bu hesaplara yapılacak izinsiz girişler konusunda tüm
            sorumluluğun kendisinde olduğunu ve izinsiz girişin yaşanması
            durumunda bunu derhal Hizmet Sağlayıcı'ya bildireceğini kabul ve
            taahhüt eder.
          </li>
          <li>
            Hizmet Sağlayıcı kendisine Hizmet Alıcı tarafından verilen kişisel
            bilgilerin işlenmesini Hizmet Alıcı ile ayrı bir sözleşme
            yapılmasına gerek olmaksızın üçüncü şahıslara devredebilir ve Hizmet
            Alıcı bu hususu peşinen kabul eder. Bu sözleşmede belirtilen,
            kişisel bilgilerin işlenmesine ilişkin hükümler Hizmet Sağlayıcının
            kişisel bilgilerin işlenmesini devrettiği üçüncü şahıs için de
            bağlayıcı olacaktır.
          </li>
        </ol>
      </div>

      <div class="font-size-14">
        <h4>
          MADDE 9 - Sözleşme Çerçevesinde Sorumluluk, Sözleşmenin Erken Feshi
        </h4>

        <ol>
          <li>
            Hizmet Sağlayıcının bu Sözleşme kapsamındaki Paket hizmetlerinin
            verilememesi veya gerektiği gibi verilememesinden kaynaklanan
            zararlardan sorumlu olmayacaktır.
          </li>
          <li>
            Hizmet Sağlayıcı, Hizmet Alıcı’nın uğrayacağı kar kaybı veya dolaylı
            zararlardan hiçbir şekilde sorumlu olmayacaktır.
          </li>
          <li>
            İşbu sözleşmenin Madde 3 § 3’te belirtilen süresinin sonundan önce
            ve Hizmet Sağlayıcının sorumlu olmadığı nedenlerle feshedilmesi
            durumunda, Hizmet Sağlayıcı Hizmet Alıcıya Kurulum Bedeli’ni ve
            fesih tarihine kadar olan üyelik kapsamında yapılan indirimleri
            fatura eder. Hizmet alıcı bu sözleşmeyi imzalamış olmak ile birlikte
            başka hiçbir ekstra bildirime ve cezai takibe gerek kalmaksızın
            düzenlenen bütün faturaları kabul eder.
          </li>
          <li>
            Hizmet Alıcı, işbu Sözleşme kapsamında aldığı Hizmetler kapsamında
            Hizmet Alıcı tarafından düzenlenen faturalardan birini 14 (ondört)
            günlük ödeme vadesinin dolmasına müteakip 29 (yirmi dokuz) gün
            içerisinde ödemediği takdirde, herhangi bir ihtara gerek olmaksızın
            temerrüde düşecektir. Hizmet Sağlayıcı bu durumda Hizmetler’i tek
            taraflı olarak durdurur ve/veya sözleşmeyi herhangi bir bildirime
            gerek olmaksızın fesheder ve Kurulum Bedeli’ni ve fesih tarihine
            kadar olan üyelik kapsamında yapılan indirimleri Hizmet Alıcıya
            fatura eder. Hizmet Alıcı işbu sözleşmeyi imzalamış olmak ile
            birlikte hiçbir bildirime ve cezai takibe gerek kalmaksızın
            düzenlenen bütün faturaları ve bu faturaları ödemeyi peşinen kabul,
            beyan ve taahhüt eder. Hizmet Alıcı işbu fatura tutarını ve
            sözleşmenin fesih tarihine kadar tahakkuk eden faturaların tamamını
            sözleşmenin feshi akabinde Hizmetin Hizmet Sağlayıcı tarafından
            durdurulduğu tarihten itibaren en geç 14 (ondört) gün içerisinde
            telefon ile sözlü veya elektronik posta, sms, internet ve mobil
            uygulamalar ya da posta yolu ile yazılı olarak Hizmet Alıcıya
            bildirilen Hizmet Sağlayıcının banka hesabına ödemekle yükümlü
            olacaktır. Aksi halde Hizmet Sağlayıcı ayrıca herhangi bir bildirime
            gerek olmaksızın yasal takibat sürecini başlatma hakkına sahiptir.
          </li>
          <li>
            İşbu Sözleşme kapsamında uygulanan ceza Hizmet Sağlayıcının Hizmet
            Alıcıya verdiği hizmetler nedeniyle uğradığı zararın tamamını
            karşılamıyorsa, Hizmet Sağlayıcı, cezai tazminatı aşan zararlarını
            talep etmek hakkına sahip olacaktır.
          </li>
        </ol>
      </div>

      <div class="font-size-14">
        <h4>MADDE 10 - Son hükümler</h4>

        <ol>
          <li>
            Hizmet Sağlayıcı işbu sözleşmede belirtilen hizmetlerin veriliş
            şekliyle ilgili olarak tek taraflı değişiklik yapma hakkına
            sahiptir. Bu sözleşmeye göre yürürlüğe girecek olan fiyat listesi
            değişiklikleri hariç değişiklikler, yayınlandıkları tarihi izleyen
            ödeme döneminin sona ermesinden sonra geçerli olacaktır.
          </li>
          <li>
            Hizmet Sağlayıcı, IT ekipmanı üzerinde koruyucu bakım ve onarım
            işlemleri yapmak hakkına sahiptir. Bu işlemler Paket hizmetlerde
            aksaklığa veya bu hizmetlerin kullanılamamasına yol açabilir. Hizmet
            Sağlayıcı koruyucu bakım ve onarım çalışmalarının geceleri veya
            hafta sonunda yapılması için elinden gelen çabayı gösterecektir.
          </li>
          <li>
            İşbu sözleşmenin imzalandığı tarihte var olmayan veya öngörülemeyen,
            Taraflar’ın veya tek bir Taraf’ın çalışma imkânlarını kısmen veya
            tamamen, geçici veya daimi olarak durduracak şekilde ve derecede
            meydana gelen beşeri ve doğal afetler, harp, seferberlik, yangın,
            grev, lokavt vb. gibi haller ile Hizmet Sağlayıcının sunucusunda
            meydana gelen bir kaza vb. Taraflar’ın kontrolü haricinde zuhur eden
            sair haller mücbir sebep sayılır. Mücbir sebebe maruz kalan Taraf
            durumu derhal diğer Taraf’a yazılı olarak bildirir ve mücbir sebep
            süresi boyunca Taraflar’ın edimleri askıya alınır. Mücbir sebepler
            ortadan kalkınca işbu Sözleşme kaldığı yerden devam eder. Mücbir
            sebep süresi boyunca hakları ifa edilmeyen Taraf’ın yükümlülükleri
            de askıya alınacaktır. Mücbir sebep halinin 10 günden fazla sürmesi
            halinde, Taraflar iyi niyetle durumu değerlendirerek birlikte veya
            ayrı ayrı fesih kararı alabileceklerdir.
          </li>
          <li>
            Bu sözleşmede düzenlenmemiş olan hususlar Türkiye Cumhuriyeti’nin
            ilgili yasalarına tabi olacaktır.
          </li>
          <li>
            Taraflar arasındaki işbu sözleşmeden kaynaklanan her türlü ihtilaf
            Kocaeli Merkez Mahkemeleri ve Kocaeli Merkez İcra Daireleri
            tarafından çözümlenecektir.
          </li>
          <li>
            Feragat beyanı yazılı olarak verilip, feragat eden ve lehine feragat
            verilen tarafından imzalanmadıkça verilmiş feragatname geçerli
            sayılmayacaktır. Taraflar’dan birinin bu sözleşmeden doğan
            haklarından herhangi birini yerine getirmede ihmali veya gecikmesi
            feragat anlamına gelmeyeceği gibi herhangi bir hak veya yükümlülüğün
            kısmen kullanılması geri kalan hak veya yükümlülüğün sonradan
            kullanılmasını engellemez.
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>
