// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp, defineAsyncComponent } from "vue";
import { router } from "./router";
import App from "./App.vue";
import store from "./state/store";
import axios from "axios";

/******** Frontend Layouts ********/
import VueCarousel from "./components/vue-carousel.vue";

/******** Frontend Layouts ********/
import LayoutHeader from "./views/frontend/Header";
import LayoutFooter from "./views/frontend/Footer";
import LayoutHeadercustomer from "./views/frontend/customer/header";
import Customersidebar from "./views/frontend/customer/customersidebar";

/******** Home *********/
import Work from "./views/frontend/dashboard/home/work";
import PopularCompanies from "./views/frontend/dashboard/home/popular-companies";
import PopularCategories from "./views/frontend/dashboard/home/popular-categories";
import Hero from "./views/frontend/dashboard/home/hero";
import WebApp1 from "./views/frontend/dashboard/home/web-app";
import MobileApp1 from "./views/frontend/dashboard/home/mobile-app";

/******** Chat *********/
import Chatleft from "./views/frontend/pages/chat/chatleft";
import Chatright from "./views/frontend/pages/chat/chatright";
/******** Service *********/
import Myservicemodal from "./views/frontend/pages/service/myservicemodal";
/******* Search **********/
import CompanySearchsidebar from "./views/frontend/pages/search/company-search-sidebar";
import CompanySearchTable from "./views/frontend/pages/search/company-search-table";
import CompanySearchGridTable from "./views/frontend/pages/search/company-search-grid-table";
import CompanySearchListTable from "./views/frontend/pages/search/company-search-list-table";
/******* Service detail ********/
// import Servicesidebar from "./views/frontend/pages/servicedetail/servicesidebar";
import PopularService from "./views/frontend/pages/servicedetail/popular-service";
import ServiceView from "./views/frontend/pages/servicedetail/service-view";
/******* Booking *******/
import Bookinglist from "./views/frontend/customer/booking/bookinglist";
/******* Setting ********/
import CustomerInformations from "./views/frontend/customer/setting/profile-settings-customer-informations.vue";
import AddressInformations from "./views/frontend/customer/setting/profile-settings-address-informations.vue";
// import SettingTable from "./views/frontend/customer/setting/setting-table";
/******* Wallet customer *********/
import Addwallet from "./views/frontend/customer/wallet/addwallet";
import Wallettable1 from "./views/frontend/customer/wallet/wallettable";
/*********Breadcrumb ********/
import breadcrumb1 from "./views/frontend/breadcrumb/about";
import breadcrumb2 from "./views/frontend/breadcrumb/categories";
import breadcrumb3 from "./views/frontend/breadcrumb/contactus";
import breadcrumb4 from "./views/frontend/breadcrumb/faq";
import breadcrumb5 from "./views/frontend/breadcrumb/search";
import loader from "./views/loader";
import "./assets/css/bootstrap/css/bootstrap.min.css";
import "./assets/css/bootstrap/js/bootstrap.bundle.min.js";
import "./assets/css/aos.css";
import "./assets/css/aos.js";
import "./assets/fontawesome/css/fontawesome.min.css";
import "./assets/fontawesome/css/all.min.css";
import VueSelect from "vue3-select2-component";
import DatePicker from "vue3-datepicker";
import "moment";
import jquery from "jquery";
import { vMaska } from "maska";
import VueAwesomePaginate from "vue-awesome-paginate";
import { createMetaManager } from "vue-meta";
import { i18n } from "./i18n.js";

import "material-icons/iconfont/material-icons.css";

window.$ = jquery;
if (window.location.href.includes("template/admin")) {
  require("./assets/css/bootstrap-datetimepicker.min.css");
  require("./assets/css/bootstrap-datetimepicker.min.js");
  require("./assets/css/slick.js");
  require("./assets/css/admin.css");
  require("./assets/css/bootstrap4.min.css");
  require("./assets/css/dataTables.bootstrap4.min.css");
  require("./assets/css/jquery.dataTables.min.js");
  require("./assets/css/dataTables.bootstrap4.min.js");
  require("./assets/css/feather.css");
  require("./assets/css/feather.min.js");
} else {
  require("./assets/css/slick.js");
  require("./assets/css/style.css");
  require("./assets/css/bootstrap4.min.css");
  require("./assets/css/dataTables.bootstrap4.min.css");
  require("./assets/css/jquery.dataTables.min.js");
  require("./assets/css/dataTables.bootstrap4.min.js");
}

// const i18n = createI18n({
//   legacy: false, // you must set `false`, to use Composition API
//   locale: "tr", // set locale
//   fallbackLocale: "en", // set fallback locale
//   messages: {
//     tr,
//     en,
//   },
// });
const app = createApp(App);
/*************Frontend Layout globally ************/
app.component("vue-carousel", VueCarousel);

/*************Frontend Layout globally ************/
app.component("layout-header", LayoutHeader);
app.component("layout-footer", LayoutFooter);
app.component("layout-headercustomer", LayoutHeadercustomer);
app.component("customersidebar", Customersidebar);

/*************Home globally **************/
app.component("work", Work);
app.component(
  "popular-companies",
  defineAsyncComponent(() =>
    import("./views/frontend/dashboard/home/popular-companies")
  )
);
// app.component("popular-companies", PopularCompanies);
app.component(
  "popular-categories",
  defineAsyncComponent(() =>
    import("./views/frontend/dashboard/home/popular-categories")
  )
);
// app.component("popular-categories", PopularCategories);
app.component("hero", Hero);
app.component("web-app", WebApp1);
app.component("mobile-app", MobileApp1);
app.component(
  "seo-categories",
  defineAsyncComponent(() => import("./components/seo-categories.vue"))
);

/*************Chat globally *************/
app.component("chatleft", Chatleft);
app.component("chatright", Chatright);
/*************Service globally **********/
app.component("myservicemodal", Myservicemodal);
/*************Search ***********/
app.component("company-search-sidebar", CompanySearchsidebar);
// app.component("company-search-table", CompanySearchTable);
app.component(
  "company-search-table",
  defineAsyncComponent(() =>
    import("./views/frontend/pages/search/company-search-table")
  )
);
app.component("company-search-grid-table", CompanySearchGridTable);
app.component("company-search-list-table", CompanySearchListTable);

app.component(
  "popular-service",
  defineAsyncComponent(() =>
    import("./views/frontend/pages/servicedetail/popular-service")
  )
);
app.component("service-view", ServiceView);
/**********Customer **********/
app.component("bookinglist", Bookinglist);
/**********Setting *********/
app.component("customer-informations", CustomerInformations);
app.component("address-informations", AddressInformations);
/**********Wallet customer *********/
app.component("addwallet", Addwallet);
app.component("wallettable1", Wallettable1);
/*************Breadcrumb globally **********/
app.component("breadcrumb1", breadcrumb1);
app.component("breadcrumb2", breadcrumb2);
app.component("breadcrumb3", breadcrumb3);
app.component("breadcrumb4", breadcrumb4);
app.component("breadcrumb5", breadcrumb5);
/************Plugin globally ***********/
app.component("Loader", loader);
app.component("vue-select", VueSelect);
app.component("datepicker", DatePicker);

app.directive("maska", vMaska);
app.use(VueAwesomePaginate);
app.use(createMetaManager());
app.use(i18n);
app.use(store);
app.use(router);
app.provide("appAxios", axios);
app.mount("#app");
