<script setup>
import { useStore } from "vuex";
import { computed, onMounted, reactive, watch } from "vue";

import conditionsModal from "../../../../components/condition-of-use/conditions-of-use-modal.vue";

const store = useStore();

const modalElements = reactive({
  registerEl: null,
  registerObj: null,
  registerIns: null,
});
const registerModel = reactive({
  email: "",
  name: "",
  surname: "",
  phone: "",
  smsCode: "",
});
const registerData = reactive({
  conditionsOfUse: true,
});
const options = reactive({
  mask: "0###-###-##-##",
  eager: false,
});

onMounted(() => {});

const registerModal = computed(() => {
  return store.state["auth"].userRegisterModal;
});
const isUserRegistered = computed(() => {
  return store.state["auth"].isUserRegistered;
});
const isUserLoginPhoneValidated = computed(() => {
  return store.state["auth"].isUserLoginPhoneValidated;
});
const isUserLoginSmsValidated = computed(() => {
  return store.state["auth"].isUserLoginSmsValidated;
});

watch(registerModal, (newValue, oldValue) => {
  if (newValue) {
    if (modalElements.registerObj == null) {
      modalElements.registerIns = bootstrap.Modal.getOrCreateInstance(
        document.getElementById("user-register"),
        {
          backdrop: true,
          keyboard: true,
          focus: true,
        }
      );
    }
    modalElements.registerEl = document.querySelector("#user-register");
    modalElements.registerObj = bootstrap.Modal.getInstance(
      modalElements.registerEl
    );
    modalElements.registerEl.addEventListener("hidden.bs.modal", function(
      event
    ) {
      store.commit("auth/userRegisterModal", false);
    });

    modalElements.registerObj.show();
  } else {
    Object.keys(registerModel).forEach((key) => {
      registerModel[key] = "";
    });

    store.commit("auth/resetModal");
    modalElements.registerObj.hide();
  }
});

const closeRegisterModal = () => {
  store.commit("auth/userRegisterModal", false);
};

const changeModal = () => {
  store.commit("auth/userRegisterModal", false);
  store.commit("auth/userLoginModal", true);
};

watch(isUserRegistered, (newVal, oldVal) => {
  if (newVal) {
    store.commit("auth/userRegisterModal", false);
  }
});

const sendSmsForUserRegister = async () => {
  const model = {
    phone: registerModel.phone.split("-").join(""),
    token: "190720191452?*DePOProje*?",
  };

  if (model.phone.length == 0 || model.phone.length < 11) {
    Swal.fire({
      title: "UYARI",
      text: "Lütfen telefon numaranızı kontrol ediniz.",
      icon: "warning",
      confirmButtonText: "Tamam",
    });

    return;
  }
  await store.dispatch("auth/sendSmsForUserRegister", model);
};

const validateSmsForUserRegister = async () => {
  const model = {
    phone: registerModel.phone.split("-").join(""),
    smsCode: registerModel.smsCode,
  };

  await store.dispatch("auth/validateSmsForUserLogin", model);
};

const registerUser = async () => {
  const model = {
    name: registerModel.name,
    surname: registerModel.surname,
    phone: registerModel.phone.split("-").join(""),
    email: registerModel.email,
  };

  await store.dispatch("auth/sendRegisterRequest", model);
};

const openConditionsModal = () => {
  store.commit("auth/conditionsOfUseModal", true);
};
</script>

<template>
  <div
    class="modal account-modal fade multi-step"
    id="user-register"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header p-0 border-0">
          <button type="button" class="close" @click="closeRegisterModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="login-header">
            <h3>Kayıt Ol</h3>
          </div>
          <form v-if="!isUserLoginPhoneValidated && !isUserLoginSmsValidated">
            <div class="form-group form-focus">
              <label class="focus-label">Telefon Numaranızı Yazınız</label>
              <input
                type="text"
                v-maska:[options]
                class="form-control"
                placeholder="(0)___ ___ __ __"
                v-model="registerModel.phone"
              />
            </div>

            <div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="registerData.conditionsOfUse"
                  id="conditions"
                />
                <label class="form-check-label" for="conditions">
                  <p class="mb-0">
                    <a
                      @click="openConditionsModal"
                      class="text- cursor-pointer"
                      style="color: #2a3042"
                    >
                      <span style="font-size: 12px; font-weight: 600"
                        >Kullanım koşullarını ve Gizlilik & KVKK politikasını
                      </span>
                    </a>
                    <span style="font-size: 12px">
                      okudum ve kabul ediyorum.
                    </span>
                  </p>
                </label>
              </div>
            </div>

            <router-link to="/">
              <div class="d-grid">
                <button
                  class="btn app-confirm-btn btn-block btn-lg login-btn"
                  type="submit"
                  :disabled="!registerData.conditionsOfUse"
                  @click="sendSmsForUserRegister"
                >
                  Doğrulama Kodu Gönder
                </button>
              </div>
            </router-link>

            <div class="text-center dont-have mt-5">
              Zaten bir hesabınız var mı?
              <a href="javascript:void(0);" @click="changeModal">Giriş Yapın</a>
            </div>
          </form>

          <form v-if="isUserLoginPhoneValidated && !isUserLoginSmsValidated">
            <div class="form-group form-focus">
              <label class="focus-label"
                >Telefonunuza Gelen Onay Kodunu Giriniz</label
              >
              <input
                type="text"
                class="form-control"
                v-model="registerModel.smsCode"
              />
            </div>

            <router-link to="/">
              <div class="d-grid">
                <button
                  class="btn app-confirm-btn btn-block btn-lg login-btn"
                  type="submit"
                  @click="validateSmsForUserRegister"
                >
                  Sms Kodunu Doğrula
                </button>
              </div>
            </router-link>

            <div class="text-center dont-have mt-5">
              Zaten bir hesabınız var mı?
              <a href="javascript:void(0);" @click="changeModal">Giriş Yapın</a>
            </div>
          </form>
          <!-- Register Form -->
          <form v-if="isUserLoginSmsValidated">
            <div class="form-group form-focus">
              <label class="focus-label"
                >Adınız <span class="color-red"> *</span></label
              >
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="registerModel.name"
              />
            </div>

            <div class="form-group form-focus">
              <label class="focus-label"
                >Soyadınız <span class="color-red"> *</span></label
              >
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="registerModel.surname"
              />
            </div>

            <div class="form-group form-focus">
              <label class="focus-label">Telefon</label>
              <input
                type="text"
                class="form-control"
                v-model="registerModel.phone"
                v-maska:[options]
                placeholder="(0)___ ___ __ __"
              />
            </div>
            <div class="form-group form-focus mb-5">
              <label class="focus-label">E-Posta</label>
              <input
                type="email"
                class="form-control"
                placeholder=""
                v-model="registerModel.email"
              />
            </div>
            <router-link to="/">
              <div class="d-grid">
                <button
                  class="btn app-confirm-btn btn-block btn-lg login-btn"
                  type="submit"
                  @click="registerUser"
                >
                  Kayıt Ol
                </button>
              </div>
            </router-link>
            <div class="text-center dont-have mt-5">
              Zaten bir hesabınız var mı?
              <a href="javascript:void(0);" @click="changeModal">Giriş Yapın</a>
            </div>
          </form>
          <!-- /Register Form -->
        </div>
      </div>
    </div>
  </div>
  <conditions-modal></conditions-modal>
</template>
