<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  isGridView: Boolean,
  isListView: Boolean,
});

onMounted(() => {
  store.commit("customercompany/loadedSC", false);
  setTimeout(() => {
    store.commit("customercompany/loadedSlugCompany", false);
  }, 300);
});
</script>

<template>
  <company-search-grid-table
    v-if="props.isGridView"
  ></company-search-grid-table>
  <company-search-list-table
    v-if="props.isListView"
  ></company-search-list-table>
</template>
