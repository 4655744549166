import Swal from "sweetalert2";
import { reactive } from "vue";
import { getCompanyWorkingHours } from "../../api/companyworkinghour.service";

const defaultWorkingHourState = () => {
  return {};
};

export const state = reactive({
  loaded: false,
  companyWorkingHourOperationsModal: false,
  companyWorkingHourSaved: false,
  companyWorkingHours: [],
  companyWorkingHour: defaultWorkingHourState(),
});

export const actions = {
  async getCompanyWorkingHours({ commit }, data) {
    const responseObj = await getCompanyWorkingHours(data);

    commit("companyWorkingHours", responseObj);
    commit("loaded", responseObj.length >= 0);

    return responseObj;
  },
};

export const mutations = {
  loaded(state, data) {
    state.loaded = data;
  },
  companyWorkingHourSaved(state, data) {
    state.companyWorkingHourSaved = data;
  },
  companyWorkingHourOperationsModal(state, data) {
    state.companyWorkingHourOperationsModal = data;
  },
  companyWorkingHour(state, data) {
    state.companyWorkingHour = data;
  },
  companyWorkingHours(state, data) {
    state.companyWorkingHours = data;
  },
  resetCompanyWorkingHour(state) {
    state.companyWorkingHour = defaultWorkingHourState();
  },
};
