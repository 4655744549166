import { baseUrl, tokenString } from "./base.js";
import { serialize } from "../helpers/formatters.js";

const getCustomerProfileInformations = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-profile`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: tokenString(),
    },
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const updateProfileInformations = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-profile`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};
export { getCustomerProfileInformations, updateProfileInformations };
