import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getCustomerNotifications,
  readAllNotifications,
} from "../../api/customernotification.service";

const defaultState = () => {
  return {};
};

export const state = reactive({
  loaded: false,
  notificationModal: false,
  notificationSaved: false,
  notification: [],
  customerNotifications: [],
});

export const actions = {
  async getCustomerNotifications({ commit }, data) {
    const responseObj = await getCustomerNotifications(data);

    commit("customerNotifications", responseObj);
    commit("loaded", responseObj.length >= 0);

    return responseObj;
  },

  async readAllNotifications({ commit }, data) {
    const responseObj = await readAllNotifications(data);

    const { success, message } = responseObj;

    // const { success, message } = response;

    if (success) {
      commit("notificationSaved", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },
};

export const mutations = {
  loaded(state, data) {
    state.loaded = data;
  },
  notificationSaved(state, data) {
    state.notificationSaved = data;
  },
  notificationModal(state, data) {
    state.notificationModal = data;
  },
  notification(state, data) {
    state.notification = data;
  },
  customerNotifications(state, data) {
    state.customerNotifications = data;
  },
  resetCompanyProduct(state) {},
};
