<script setup></script>

<template>
  <div class="row mt-4">
    <div class="col-12">
      <div>
        <h4>Gizlilik ve Kişisel Verilerin Korunması Politikasının Amacı</h4>

        <p class="font-size-14">
          Bu Gizlilik ve Kişisel Verilerin Korunması Politikası ixirlife
          Teknoloji A.Ş.'nin (ixirlife Teknoloji) işlettiği ixirlife.com adresli
          internet hizmetinin ne gibi kişisel veriler topladığını, bu bilgilerin
          nasıl kullanıldığını, gerekmesi halinde ixirlife Teknoloji'nin bu
          bilgileri kimlerle paylaşabileceğini, ixirlife Teknoloji'nin
          topladığı/işlediği/sakladığı kişisel verilerinize ilişkin haklarınızın
          ne olduğunu, bu hakları nasıl kullanabileceğinizi ve elektronik ticari
          iletiler konusundaki tercihlerinizi nasıl değiştirebileceğinizi
          açıklamaktadır.
        </p>
      </div>

      <div>
        <h4>Kişisel verilerin işlenmesine ilişkin hukuki dayanak</h4>

        <p class="font-size-14">
          ixirlife Teknoloji'nin sunduğu internet hizmeti olan ixirlife.com,
          5651 Sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu
          Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun ve
          ilgili ikincil mevzuat, 6563 Sayılı Elektronik Ticaretin Düzenlenmesi
          Hakkında Kanun ve ilgili ikincil mevzuat, 5237 Sayılı Türk Ceza Kanunu
          ve 6698 Sayılı Kişisel Verilerin Korunması Kanunu ve benzeri kanun ve
          yönetmeliklerden kaynaklanan yükümlülüklerini yerine getirebilmek için
          kişisel verilerinizi (ad, soyad, doğum tarihi, cep telefonu numarası,
          e-posta, cinsiyet, adres ve kişiyi doğrudan veya dolaylı olarak
          tanımlamaya yönelik bazı kişisel bilgileri) toplamaktadır.
        </p>

        <p class="font-size-14">
          Bu veriler açık rızanıza istinaden, burada belirtilen amaçlar dışında
          kullanılmamak kaydı ile her türlü tedbir alınarak toplanacak,
          işlenecek ve saklanacaktır.
        </p>

        <p class="font-size-14">
          İşlenen kişisel verilerinizin neler olduğunu, yurt içi veya yurt
          dışında kişisel verilerin aktarıldığı üçüncü kişileri öğrenmek,
          verilerinizi değiştirmek, güncellemek ve/veya silmek ixirlife.com web
          sitesi, mobil uygulaması ve mobil sitesi üzerinden hesabınıza
          erişebilir veya bilgi@ixirlife.com e-posta adresi üzerinden bizimle
          irtibata geçebilirsiniz. 6698 Sayılı Kişisel Verilerin Korunması
          Kanunu madde 11 uyarınca sahip olduğunuz diğer haklar saklıdır.
        </p>

        <p class="font-size-14">
          6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun uyarınca;
          onayın geri alındığına ilişkin kayıtlar bu tarihten itibaren 1 yıl;
          ticari elektronik iletinin içeriği ve gönderiye ilişkin diğer her
          türlü kayıt ise gerektiğinde ilgili bakanlığa sunulmak üzere 3 yıl
          saklanacaktır. Süre geçtikten sonra kişisel verileriniz şirketimiz
          tarafından silinir, yok edilir veya anonim hale getirilir.
        </p>
      </div>

      <div>
        <h4>Çerez (Cookie) Kullanımı</h4>

        <p class="font-size-14">
          ixirlife.com yasal yükümlülüğü çerçevesinde, işbu Gizlilik ve Kişisel
          Verilerin Korunması Politikası ile belirlenen amaçlar dışında
          kullanılmamak şartı ile gezinme bilgilerinizi toplayacak, işleyecek,
          üçüncü kişilerle paylaşacak ve güvenli olarak saklayacaktır. Gezinme
          bilgilerinizi toplama amacıyla kullandığımız çerezler (cookie)
          hakkında bilgi, ixirlife.com web ve mobil sitesine ilk girişinizde
          veya mobil uygulamasını telefonunuza veya tabletinize yüklediğinizde
          “açılır pencere (pop-up ekran)” ile verilecektir.
        </p>

        <p class="font-size-14">
          Çerezler, ixirlife.com'u ziyaret ettiğinizde veya mobil uygulamayı cep
          telefonunuza yüklediğinizde veya mobil sitesi’nden bağlandığınızda,
          internet tarayıcınız tarafından yüklenen ve bilgisayarınız, cep
          telefonunuz veya tabletinizde saklanan küçük bilgi parçacıklarını
          içeren metin dosyalarıdır.
        </p>

        <p class="font-size-14">
          ixirlife.com, size özel tanıtım yapmak, promosyonlar ve pazarlama
          teklifleri sunmak, web sitesinin veya mobil uygulamanın içeriğini size
          göre iyileştirmek ve/veya tercihlerinizi belirlemek amacıyla; site
          üzerinde gezinme bilgilerinizi ve/veya site üzerindeki üyelik kullanım
          geçmişinizi izlemektedir. ixirlife.com farklı yöntemlerle veya farklı
          zamanlarda site üzerinde toplanan bilgileri eşleştirebilir ve bu
          bilgileri üçüncü taraflar gibi başka kaynaklardan alınan bilgilerle
          birlikte kullanabilir. Söz konusu eşleştirme ve kullanma yalnızca işbu
          Gizlilik ve Kişisel Verilerin Korunması Politikası ile belirlenen
          amaçlar ve kapsam dahilinde kalacaktır.
        </p>
      </div>

      <div>
        <h4>Çerez Çeşitleri</h4>

        <p class="font-size-14">
          ixirlife.com web sitesinde, mobil uygulamasında ve mobil sitesinde
          oturum çerezleri ve kalıcı çerezler kullanmaktadır. Oturum kimliği
          çerezi, tarayıcınızı kapattığınızda sona erer. Kalıcı çerez ise sabit
          diskinizde uzun bir süre kalır. İnternet tarayıcınızın "yardım"
          dosyasında verilen talimatları izleyerek veya
          “www.allaboutcookies.org” veya “www.youronlinechoices.eu” adresini
          ziyaret ederek kalıcı çerezleri kaldırabilir ve hem oturum çerezlerini
          hem de kalıcı çerezleri reddedebilirsiniz. Kalıcı çerezleri veya
          oturum çerezlerini reddederseniz, web sitesini, mobil uygulamayı ve
          mobil sitesini kullanmaya devam edebilirsiniz, fakat web sitesinin,
          mobil uygulamanın ve mobil sitesinin tüm işlevlerine
          erişemeyebilirsiniz veya erişiminiz sınırlı olabilir. Mobil uygulamada
          söz konusu durum değişkenlik gösterebilmektedir.
        </p>

        <p class="font-size-14">
          ixirlife.com çerezleri yaptığınız tercihleri hatırlamak ve web
          sitesi/mobil uygulama/mobil sitesi kullanımınızı kişiselleştirmek için
          kullanır (örneğin parolanızı kaydeden ve oturumunuzun sürekli açık
          kalmasını sağlayan, daha sonraki ziyaretlerinizde sizi tanıyan
          çerezler). ixirlife.com çerezleri ayrıca ixirlife.com’a nereden
          bağlandığınız, hangi içeriği görüntülediğiniz ve ziyaretinizin süresi
          gibi ixirlife.com'u nasıl kullandığınızı takip etmemizi sağlar. Buna
          ek olarak, ilgi alanlarınıza ve size daha uygun içerik ve kampanyalar
          sunmak için hedeflenmiş reklam/tanıtım amacıyla kullanır. ixirlife.com
          çerezler yoluyla elde edilen bilgileri kullanıcılardan toplanan
          kişisel verilerle eşleştirir.
        </p>

        <h5 class="mb-2">
          ixirlife.com üçüncü taraf çerezlerini reklam ve yeniden hedefleme için
          nasıl kullanmaktadır?
        </h5>

        <p class="font-size-14">
          ixirlife.com çerezleri ayrıca; arama motorlarını, ixirlife.com'u ve
          ixirlife.com’un reklam verdiği web sitelerini ziyaret ettiğinizde
          ilginizi çekebileceğini düşündüğü reklamları size sunabilmek için
          kullanabilir. Bu reklamları sunarken, ixirlife.com’un sizi
          tanıyabilmesi amacıyla tarayıcınıza benzersiz bir üçüncü taraf çerezi
          yerleştirilebilir.
        </p>

        <p class="font-size-14">
          ixirlife.com’un web sitesi/mobil uygulaması/mobil sitesi ayrıca;
          Google, Inc. ("Google") tarafından sağlanan bir web analizi hizmeti
          olan Google Analytics kullanmaktadır. Google Analytics, çerezleri
          ziyaretçilerin web sitesini/mobil uygulamayı/mobi sitesini nasıl
          kullandıklarını analiz etmek amacıyla kullanır. Bu web sitesini/mobil
          uygulamayı/mobil sitesini kullanımınızla ilgili bilgiler (IP adresiniz
          dahil) Google'a aktarılarak Google tarafından ABD'deki sunucularda
          saklanmaktadır. Google bu bilgileri web sitesini/mobil
          uygulamayı/mobil sitesini kullanımınızı değerlendirmek, ixirlife.com
          web sitesi/mobil uygulama/mobil site faaliyetini derlemek ve web
          sitesi/mobil uygulama/mobil sitesifaaliyeti ve internet kullanımıyla
          ilgili başka hizmetler sağlamak amacıyla kullanacaktır. Fakat IP
          adresinizi Google tarafından depolanan diğer verilerle
          eşleştirmeyecektir. Google Analytics kullanımı hakkında daha fazla
          bilgi için (reddetme seçenekleri dahil), şu adresi ziyaret
          edebilirsiniz:
          <a
            href="https://policies.google.com/privacy?hl=tr#infocollect"
            target="_blank"
            >https://policies.google.com/privacy?hl=tr#infocollect</a
          >
        </p>

        <p class="font-size-14">
          Bunun yanı sıra sizler için ilginizi çekebilecek reklamları size
          sunabilmek amacıyla, Facebook ile de özel hedef kitle oluşturmak
          amacıyla, işbu Gizlilik/Kişisel Verilerin Korunması Politikası ve
          İletişim İzni’ni kabul etmekle, ixirlife.com ile paylaşmış olduğunuz
          kişisel verilerinizden e-posta adresiniz Facebook ile
          paylaşılmaktadır.
        </p>

        <p class="font-size-14">
          e-Posta adresinizi Facebook'a yüklemeden ve iletmeden önce, hash
          yöntemiyle Facebook tarafından yerel olarak sistemimizde şifrelenir.
          Facebook ile paylaşılan Hash Yöntemiyle Şifrelenen e-posta adresiniz,
          yalnızca eşleştirme işlemi için kullanılacaktır. Üçüncü taraflarla
          veya diğer reklam verenlerle paylaşılmaz ve eşleştirme işlemi
          tamamlandıktan sonra mümkün olan en kısa sürede Facebook
          sistemlerinden silinirler. Facebook, (a) kişisel veriniz Facebook
          sistemlerinde bulunduğu sürece verilerin güvenliğini ve bütünlüğünü
          korumak ve (b) Facebook sistemlerinde bulunan kişisel verinize
          yanlışlıkla veya yetkisiz olarak erişilmesine ve verinizin yanlışlıkla
          veya yetkisiz olarak kullanılmasına, değiştirilmesine veya ifşa
          edilmesine karşı korumak için geliştirilen teknik ve fiziksel güvenlik
          önlemlerini de içerecek şekilde, özel hedef kitlenizi ("özel hedef
          kitleniz") oluşturan Hash Yöntemiyle Şifrelenen Verilerin ve Facebook
          Kullanıcı Kimliği koleksiyonunun gizliliğini ve güvenliğini
          sağlayacaktır. Ayrıca, izniniz olmadan veya yasalar gerektirmediği
          sürece, Facebook üçüncü taraflara veya diğer reklam verenlere özel
          hedef kitleniz için erişim veya bilgi vermez, özel hedef kitle
          bilgilerinizi kullanıcılarımız hakkındaki bilgilere eklemez veya ilgi
          alanına dayalı profiller oluşturmaz ya da özel hedef kitlenizi size
          hizmet sunmanın haricinde kullanmaz. Facebook özel hedef kitleler
          koşulları için
          <a
            href="https://www.facebook.com/ads/manage/customaudiences/tos.php"
            target="_blank"
            >https://www.facebook.com/ads/manage/customaudiences/tos.php</a
          >
          Gizlilik İlkeleri için
          <a href="https://www.facebook.com/privacy/explanation" target="_blank"
            >https://www.facebook.com/privacy/explanation</a
          >
          adresini ziyaret edebilirsiniz.
        </p>
      </div>

      <div>
        <h4>Resmi Makamlarla Kişisel Veri Paylaşımı</h4>

        <p class="font-size-14">
          ixirlife.com ziyaretinize veya üyeliğe ilişkin kişisel verilerinizi ve
          gezinme bilgileriniz gibi trafik bilgilerinizi; güvenliğiniz ve
          ixirlife Teknoloji'nin yasalar karşısındaki yükümlülüğünü ifa etmesi
          amacıyla yasal olarak bu bilgileri talep etmeye yetkili olan kamu
          kurum ve kuruluşları ile paylaşabilecektir.
        </p>
      </div>

      <div>
        <h4>Kişisel Verilerin Korunmasına İlişkin Önlemler</h4>

        <p class="font-size-14">
          ixirlife Teknoloji kişisel verilere yetkisiz erişimi engellemek için
          gerekli önlemleri almaktadır.
        </p>

        <p class="font-size-14">
          ixirlife Teknoloji kişisel verilerinizi gizli tutacağını taahhüt eder.
          Yine de alınan önlemlere rağmen ixirlife.com'a yapılabilecek
          saldırılar nedeniyle verilerin üçüncü kişilerin eline geçmesi
          durumunda, ixirlife Teknoloji bu durumu derhal sizlere ve Kişisel
          Verileri Koruma Kurulu’na bildirir ve gerekli önlemleri alır.
        </p>

        <p class="font-size-14">
          ixirlife Teknoloji işbu Gizlilik ve Kişisel Verilerin Korunması
          Politikası'nda değişiklik yapabilir. İşbu Gizlilik ve Kişisel
          Verilerin Korunması Politikası'ndaki değişikliklerden haberdar olmanız
          için ixirlife.com üyelerine gerekli bilgilendirme yapılacaktır.
          Kullanıcılar, Üyelik/Kişisel bilgilerini ve iletişim tercihlerini her
          zaman sisteme giriş yaparak güncelleyebilirler. Bu konudaki
          taleplerinizi bilgi@ixirlife.com e-posta adresine e-posta göndererek
          iletebilirsiniz.
        </p>
      </div>

      <div>
        <h4>Uygulanacak Hukuk, Yetkili Mahkeme ve İcra Daireleri</h4>

        <p class="font-size-14">
          İşbu Gizlilik/Kişisel Verilerin Korunması Politikası ve İletişim İzni,
          Türkiye Cumhuriyeti kanunlarına tabidir. Gizlilik/Kişisel Verilerin
          Korunması Politikası ve İletişim İzni’nin uygulanmasından doğabilecek
          her türlü uyuşmazlığın çözümünde Kocaeli Gebze Mahkeme ve İcra
          Müdürlükleri yetkilidir.
        </p>
      </div>

      <div>
        <h4>İletişim İzni</h4>

        <p class="font-size-14">
          İşbu Gizlilik/Kişisel Verilerin Korunması Politikası ve İletişim
          İzni’ni kabul etmekle, bizimle paylaşılmasına rıza göstermiş olduğunuz
          kişisel verilerinizin, tarafınıza çeşitli avantajların sağlanıp
          sunulabilmesi ve size özel tanıtım, promosyon, reklam, satış,
          pazarlama, anket ve benzer amaçlı her türlü elektronik iletişimin
          telefon, kısa mesaj (SMS), e-posta ve benzeri yollarla yapılması ve
          diğer iletişim mesajlarının gönderilmesi amacıyla; toplanmasına,
          saklanmasına, işlenmesine, kullanılmasına ve ixirlife Teknoloji'nin
          sözleşme ilişkisi içerisinde olduğu üçüncü kişilere aktarımına izin
          vermiş bulunmaktasınız.
        </p>
      </div>
    </div>
  </div>
</template>
