<script setup>
import { useStore } from "vuex";
import { computed, onMounted, watch } from "vue";

const store = useStore();

const imagesTemp = require.context(
  "../../../../assets/img/ixirlocal",
  false,
  /\.svg$/
);

const defaultNoImgWebp =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/NoImage.webp";

onMounted(() => {
  setTimeout(() => {
    onCalculatedWidth();
  }, 300);
});

const loaded = computed(() => {
  return store.state["customercompany"].loaded;
});
const companyList = computed(() => {
  return store.state["customercompany"].companies;
});

watch(loaded, (newVal) => {
  newVal
    ? setTimeout(() => {
        onCalculatedWidth();
      }, 300)
    : "";
});

const loadTempImg = (path) => {
  // return imagesTemp("./user-01.jpg").default;
  return imagesTemp(path).default;
};
const onCalculatedWidth = () => {
  let starWidth = [];
  starWidth = companyList.value.map((rating) => {
    return {
      width: `${(rating.rating / 5) * 100}%`,
    };
  });

  const starInners = document.querySelectorAll(".stars-inner");
  const starOuters = document.querySelectorAll(".stars-outer .stars-inner");

  starInners.forEach((inner, index) => {
    inner.style.width = starWidth[index].width;
  });
  starOuters.forEach((outer, index) => {
    outer.innerHTML = companyList.value[index].rating;
  });
};
</script>

<template>
  <div class="row" v-if="!loaded">
    <div
      class="col-md-6 col-lg-4 mt-4"
      style="height: auto;"
      v-for="index in 6"
      :key="index"
    >
      <div class="loading-skeleton">
        <div class="service-widget mb-0">
          <!-- <div class="col"> -->
          <!-- <div class="card"> -->
          <img
            :src="defaultNoImgWebp"
            class="card-img-top"
            style="max-width: 100%; height: auto"
            alt="..."
          />
          <div class="mt-3">
            <h5>Company Name</h5>
            <!-- <p>
              Company Name
            </p> -->
            <p>Rating and Phone</p>
            <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
          </div>
          <!-- </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-else>
    <div
      class="col-md-6 col-lg-4 mt-2"
      v-for="company in companyList"
      :key="company.id"
    >
      <div class="service-widget">
        <div class="service-img h-100">
          <router-link
            :to="{
              name: 'service-details-slug',
              params: { slug: company.slug },
              // params: { guid: company.companyGuid },
            }"
            @click="store.commit('customercompany/company', company)"
          >
            <!-- <div
            @click="
              store.commit('customercompany/company', company),
                router.push({
                  name: 'service-details-guid',
                  params: { guid: company.companyGuid },
                })
            "
          > -->
            <img
              class="img-fluid serv-img h-100"
              alt="Service Image"
              :src="company.mainImg?.picture ?? defaultNoImgWebp"
            />
            <!-- <img
              class="img-fluid serv-img"
              alt="Service Image"
              :src="loadTempImg('./rectangle.svg')"
            /> -->
            <!-- </div> -->
          </router-link>
          <!-- <div class="fav-btn">
            <a href="javascript:void(0)" class="fav-icon">
              <i class="fas fa-heart"></i>
            </a>
          </div> -->
          <div class="loc-btn">
            <!-- <a href="javascript:void(0)" class="loc-icon"> -->
            <div class="loc-icon px-2">
              <!-- <i class="fas fa-heart"></i> -->
              <span
                class="material-icons-outlined color-white pe-2"
                style="font-size: 20px;"
              >
                place
              </span>
              <span class="pe-1" style="font-size: 16px;">
                {{ company.cityName }}
              </span>
            </div>
          </div>
          <div class="item-info">
            <!-- <div class="service-user">
              <a href="javascript:void(0);">
                <img :src="loadImg1(company.img1)" alt="" />
              </a>
              <span class="service-price ms-1">{{ company.rate }}</span>
            </div> -->
            <div class="cate-list">
              <div
                v-for="(category, index) in company.categoryList"
                :key="index"
                class="bg-light-blue me-2"
              >
                {{ category }}
              </div>
              <!-- <router-link
                v-for="(category, index) in company.categoryList"
                :key="index"
                class="bg-light-blue me-2"
                :to="{
                  name: 'service-details-slug',
                  params: { slug: company.slug },
                  // params: { guid: company.companyGuid },
                }"
              >
                {{ category }}
              </router-link> -->
            </div>
          </div>
        </div>
        <div class="service-content">
          <h3 class="title">
            <router-link
              :to="{
                name: 'service-details-slug',
                params: { slug: company.slug },
                // params: { guid: company.companyGuid },
              }"
            >
              {{ company.companyName }}
            </router-link>
          </h3>
          <div class="d-flex justify-content-between align-items-center">
            <!-- <div class="rating mapgridrating1">
              <i
                class="fas fa-star"
                v-for="index in 5"
                :class="{ filled: index <= company.rating }"
              ></i>
              <span class="d-inline-block average-rating">{{
                company.rating
              }}</span>
            </div> -->

            <div class="d-flex company-comment-text pb-0 pt-0">
              <div class="rating mapgridrating1">
                <i style="font-style: normal;" class="me-2">
                  <div class="stars-outer">
                    <div class="stars-inner" style="width: 100%;"></div>
                  </div>
                </i>
              </div>
              <span> {{ company.rating }}</span>
            </div>

            <div class="user-info">
              <div class="row">
                <!-- <span class="col-auto ser-contact">
                  <span>{{ company.phoneList[0]?.phone }}</span>
                  <span
                    class="material-icons-round color-black ms-2"
                    v-if="company.phoneList.length"
                  >
                    phone_enabled
                  </span>
                </span> -->

                <!-- <span class="col ser-location">
                <span>{{ company.name2 }}</span>
                <i class="fas fa-map-marker-alt ms-1"></i>
              </span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
