<script setup>
import { computed, inject, onMounted, reactive } from "vue";
import { useStore } from "vuex";

const store = useStore();

const genderList = [
  { id: 0, title: "Seçiniz.." },
  { id: 1, title: "Kadın" },
  { id: 2, title: "Erkek" },
];
const $pv = inject("$v");

const response = reactive({
  model: {},
});

onMounted(() => {
  // store.commit("customerprofile/loaded", false);
  // fetchProfileInformations();
});

const uploadedPicture = computed(() => {
  return store.state["customerprofile"].uploadedPicture;
});

const fetchProfileInformations = async () => {
  await store.dispatch("customerprofile/getCustomerProfileInformations");
  response.model = store.state["customerprofile"].profile;
  // response.model = (
  //   await store.dispatch("customerprofile/getCustomerProfileInformations")
  // )[0];
};

const onChangeFile = (event) => {
  $pv.value.file.$model = event.target.files[0];
  $pv.value.profilePicture.$model = null;

  let reader = new FileReader();
  reader.onload = function(e) {
    // let image = document.createElement("img");
    // console.log(e.target.result);
    // image.src = e.target.result;
    store.commit("customerprofile/uploadedPicture", e.target.result);
    // profileStore.model.uploadedPicture = e.target.result;
    // document.body.appendChild(image);
  };
  reader.readAsDataURL($pv.value.file.$model);
  // response.model.file = event.target.files[0];
  // response.model.profilePicture = null;

  // store.commit("customerprofile/uploadedPicture", e.target.files[0]);
  // profileStore.model.uploadedPicture = e.target.result;
};
const openAttachment = () => {
  document.getElementById("fileInput").click();
};
</script>

<template>
  <div class="row">
    <div class="col-xl-12 mb-4">
      <h5 class="form-title">Kişisel Bilgiler</h5>
      <hr />
    </div>
  </div>

  <div class="row">
    <div class="form-group col-xl-6">
      <label class="me-sm-2">Adı <span class="color-req-red">*</span></label>
      <input
        class="form-control"
        :class="{ 'is-invalid': $pv.name.$error }"
        type="text"
        v-model="$pv.name.$model"
      />
      <span class="error-message" v-if="$pv.name.$error">
        {{ $pv.name.$errors[0].$message }}
      </span>
    </div>

    <div class="form-group col-xl-6">
      <label class="me-sm-2">Soyadı <span class="color-req-red">*</span></label>
      <input
        class="form-control"
        :class="{ 'is-invalid': $pv.surname.$error }"
        type="text"
        v-model="$pv.surname.$model"
      />
      <span class="error-message" v-if="$pv.surname.$error">
        {{ $pv.surname.$errors[0].$message }}
      </span>
    </div>

    <div class="form-group col-xl-6">
      <label class="me-sm-2"
        >Cep Telefonu <span class="color-req-red">*</span></label
      >
      <input
        class="form-control"
        :class="{ 'is-invalid': $pv.phone.$error }"
        type="text"
        required
        v-model="$pv.phone.$model"
      />
      <span class="error-message" v-if="$pv.phone.$error">
        {{ $pv.phone.$errors[0].$message }}
      </span>
    </div>

    <div class="form-group col-xl-6">
      <label class="me-sm-2">Doğum Tarihi</label>
      <input class="form-control" type="date" v-model="$pv.birthday.$model" />
    </div>

    <div class="col-xl-6">
      <div class="form-group col-xl-12">
        <label class="me-sm-2">E-Posta</label>
        <input class="form-control" type="email" v-model="$pv.email.$model" />
      </div>

      <div class="form-group col-xl-12">
        <label for="" class="me-sm-2">Cinsiyet</label>
        <div class="filter-list">
          <select
            class="form-control form-control selectbox select form-select"
            v-model="$pv.genderID.$model"
          >
            <option v-for="gender in genderList" :value="gender.id">{{
              gender.title
            }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group img-upload col-xl-6">
      <label for="" class="me-sm-2">Profil Fotoğrafı</label>
      <div class="card">
        <div v-if="uploadedPicture">
          <img
            :src="uploadedPicture"
            width="100"
            height="100"
            alt=""
            style="border-radius: 8px"
          />
        </div>
        <div class="cloud cursor-pointer" @click="openAttachment" v-else>
          <span class="material-icons-round">
            cloud_upload
          </span>
        </div>
        <div class="upload-detail">
          <!-- <div class="mb-2">
                                <span>Yüklediğiniz fotoğraf 500x500px ve</span>
                              </div>
                              <div>
                                <span>en fazla 1 mb boyutunda olmalıdır</span>
                              </div> -->
          <div>
            <span>Fotoğraf yüklemek için tıklayınız.</span>
          </div>

          <input
            id="fileInput"
            type="file"
            accept=".jpeg, .jpg, .png, .svg"
            @change="onChangeFile"
            style="display:none;"
          />
          <div class="btn mt-3" @click="openAttachment">
            Seç
          </div>
        </div>
      </div>
    </div>

    <!-- <setting-table :infoModel="response.model"></setting-table> -->
  </div>
</template>
