<template>
    <div class="bookings" v-for="item in bookinglist" :key="item.id">
        <div class="booking-list">
            <div class="booking-widget">
                <router-link to="/service-details" class="booking-img">
                    <img :src="loadImg(item.img)" alt="User Image">
                </router-link>
                <div class="booking-det-info">
                    <h3>
                        <router-link to="/service-details">{{item.name}}</router-link>
                    </h3>
                    <ul class="booking-details">
                        <li>
                            <span>{{item.date}}</span> {{item.date1}} <span class="badge badge-pill badge-prof bg-primary">{{item.status}}</span>
                        </li>
                        <li><span>{{item.date2}}</span> {{item.date3}}</li>
                        <li><span>{{item.date4}}</span> {{item.rate}}</li>
                        <li><span>{{item.location}}</span> {{item.location1}}</li>
                        <li><span>{{item.phno}}</span> {{item.no}}</li>
                        <li>
                            <span>{{item.status1}}</span>
                            <div class="avatar avatar-xs me-1">
                                <img class="avatar-img rounded-circle" alt="User Image" :src="loadImg1(item.img1)">
                            </div>
                            {{item.name1}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="booking-action">
                <a href="javascript:void(0);" class="btn btn-sm bg-info-light"><i class="far fa-eye"></i> {{item.button1}}</a>
                <a href="javascript:;" class="btn btn-sm bg-danger-light"><i class="fas fa-times"></i> {{item.button2}}</a>
            </div>
        </div>
    </div>
</template>
<script>
	import bookinglist from '../../../../assets/json/website/bookinglist.json'
	const images = require.context('../../../../assets/img/services', false, /\.png$|\.jpg$/)
    const images1 = require.context('../../../../assets/img/provider', false, /\.png$|\.jpg$/)
	export default {
	mounted() {
    $('.fav-btn .fav-icon').on('click', function () {
    $(this).toggleClass('favourite');
    });
	},
	methods:{
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        loadImg1(img1Path) {
            return images1('./' + img1Path).default
        },
    },
	data() {
        return {
            bookinglist: bookinglist
        }
    }
	}
	</script>