<script setup>
import UserLoginModal from "./modals/user-login-modal.vue";
import userRegisterModal from "./modals/user-register-modal.vue";
import UserNotificationDropdown from "./notification/user-notification-dropdown.vue";

import { user } from "../../../api/auth.service";
import { useStore } from "vuex";
import { computed, onMounted, reactive } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useStore();
const slugify = require("slugify");

const defaultNoImgWebp =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/NoImage.webp";
const profile = reactive({ user: user() });
const headerData = reactive({
  isDev: process.env.NODE_ENV,
  showOnlyMobile: false,
  laserEpilation: {
    laserEpilation: "Lazer Epilasyon",
    wholeBody: "Tüm Vücut",
    armpit: "Koltuk Altı",
    leg: "Bacak",
    arm: "Kol",
    back: "Sırt",
  },
  hair: {
    hair: "Saç",
    blowDry: "Fön",
    hairCut: "Saç Kesim",
    hairColoring: "Saç Boyama",
    hairCare: "Saç Bakımı",
  },
  makeUp: {
    makeUp: "Makyaj",
    professional: "Profesyonel Makyaj",
    bridalMakeUp: "Gelin Makyajı",
    silkEyelash: "İpek Kirpik",
    lashlift: "Kirpik Lifting",
  },
  nail: {
    nail: "Tırnak",
    manicure: "Manikür",
    pedicure: "Pedikür",
    permanentNailPolish: "Kalıcı Oje",
    prostheticNail: "Protez Tırnak",
    nailArt: "Nail Art",
    gelNail: "Jel Tırnak",
  },
  skinCare: {
    skinCare: "Cilt Bakımı",
    classic: "Klasik Cilt Bakımı",
    professional: "Profesyonel Cilt Bakımı",
    faceWaxing: "Yüz Ağda",
    aboveLip: "Dudak Üstü",
    eyeBrowRemoval: "Kaş Alma",
  },
});

onMounted(() => {
  headerData.showOnlyMobile = window.screen.width < 1366;
  fetchCustomerNotifications();
});

const fetchCustomerNotifications = async () => {
  await store.dispatch("customernotification/getCustomerNotifications");
};
</script>

<script>
import AOS from "aos";
export default {
  mounted() {
    if ($(".main-wrapper .aos").length > 0) {
      AOS.init({
        duration: 1200,
        once: true,
      });
    }
    if ($(window).width() <= 991) {
      var Sidemenu = function() {
        this.$menuItem = $(".main-nav a");
      };

      function init() {
        var $this = Sidemenu;
        $(".main-nav a").on("click", function(e) {
          if (
            $(this)
              .parent()
              .hasClass("has-submenu")
          ) {
            e.preventDefault();
          }
          if (!$(this).hasClass("submenu")) {
            $("ul", $(this).parents("ul:first")).slideUp(350);
            $("a", $(this).parents("ul:first")).removeClass("submenu");
            $(this)
              .next("ul")
              .slideDown(350);
            $(this).addClass("submenu");
          } else if ($(this).hasClass("submenu")) {
            $(this).removeClass("submenu");
            $(this)
              .next("ul")
              .slideUp(350);
          }
        });
      }

      // Sidebar Initiate
      init();
    }
    // Mobile menu sidebar overlay
    $(".header-fixed").append('<div class="sidebar-overlay"></div>');
    $(document).on("click", "#mobile_btn", function() {
      $("main-wrapper").toggleClass("slide-nav");
      $(".sidebar-overlay").toggleClass("opened");
      $("html").addClass("menu-opened");
      return false;
    });
    $(document).on("click", ".sidebar-overlay", function() {
      $("html").removeClass("menu-opened");
      $(this).removeClass("opened");
      $("main-wrapper").removeClass("slide-nav");
    });

    $(document).on("click", "#menu_close", function() {
      $("html").removeClass("menu-opened");
      $(".sidebar-overlay").removeClass("opened");
      $("main-wrapper").removeClass("slide-nav");
    });
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    homeMenu() {
      return (
        this.$route.name == "/" ||
        this.$route.name == "index" ||
        this.$route.name == "index-two" ||
        this.$route.name == "index-three" ||
        this.$route.name == "index-four" ||
        this.$route.name == "index-five"
      );
    },
    providerMenu() {
      return (
        this.$route.name == "provider-dashboard" ||
        this.$route.name == "my-services-inactive" ||
        this.$route.name == "my-services" ||
        this.$route.name == "provider-bookings" ||
        this.$route.name == "provider-settings" ||
        this.$route.name == "provider-wallet" ||
        this.$route.name == "provider-subscription" ||
        this.$route.name == "provider-availability" ||
        this.$route.name == "provider-reviews" ||
        this.$route.name == "provider-payment"
      );
    },
    pageMenu() {
      return (
        this.$route.name == "search" ||
        this.$route.name == "service-details" ||
        this.$route.name == "add-service" ||
        this.$route.name == "edit-service" ||
        this.$route.name == "chat" ||
        this.$route.name == "notifications" ||
        this.$route.name == "about-us" ||
        this.$route.name == "contact-us" ||
        this.$route.name == "faq" ||
        this.$route.name == "term-condition" ||
        this.$route.name == "privacy-policy1"
      );
    },
    customerMenu() {
      return (
        this.$route.name == "user-dashboard" ||
        this.$route.name == "favourites" ||
        this.$route.name == "user-bookings" ||
        this.$route.name == "profile-settings" ||
        this.$route.name == "user-wallet" ||
        this.$route.name == "user-reviews" ||
        this.$route.name == "user-payment"
      );
    },
  },
};
</script>

<template>
  <div>
    <div class="main-wrapper">
      <!-- Header -->
      <header class="header">
        <div class="container px-0">
          <div class="row">
            <nav class="navbar navbar-expand-lg header-nav">
              <div class="navbar-header">
                <a id="mobile_btn" href="javascript:void(0);" class="ps-4">
                  <span
                    class="material-icons-round"
                    style="font-size: 40px; color: #572a86;"
                  >
                    menu
                  </span>
                </a>
                <!-- <a id="mobile_btn" href="javascript:void(0);">
              <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
              <span class="material-icons-round">
                menu
              </span>
            </a> -->
                <router-link to="/" class="navbar-brand logo">
                  <img
                    src="../../../assets/img/ixirlocal/ixirlife-purple-text.svg"
                    class="img-fluid"
                    alt="Logo"
                  />
                  <!-- <img
                src="../../../assets/img/logo.png"
                class="img-fluid"
                alt="Logo"
              /> -->
                </router-link>
                <!-- <router-link to="/" class="navbar-brand logo-small">
              <img
                src="../../../assets/img/logo-icon.png"
                class="img-fluid"
                alt="Logo"
              />
            </router-link> -->
              </div>
              <div class="main-menu-wrapper d-flex">
                <div :class="{ 'col-8 bgc-fff': headerData.showOnlyMobile }">
                  <div>
                    <div class="menu-header">
                      <router-link to="/" class="menu-logo">
                        <img
                          src="../../../assets/img/ixirlocal/ixirlife-purple-text.svg"
                          class="img-fluid"
                          alt="Logo"
                        />
                        <!-- <img
                      src="../../assets/img/logo.png"
                      class="img-fluid"
                      alt="Logo"
                    /> -->
                      </router-link>
                      <a
                        id="menu_close"
                        class="menu-close"
                        href="javascript:void(0);"
                      >
                        <i class="fas fa-times"></i
                      ></a>
                    </div>
                    <ul class="main-nav">
                      <!-- <li
                      class="has-submenu"
                      :class="homeMenu ? 'active' : 'notactive'"
                      >
                      <a href="javascript:void(0);"
                        >Home <i class="fas fa-chevron-down"></i
                      ></a>
                      <ul class="submenu">
                        <li :class="currentPath == 'index' ? 'active' : 'notactive'">
                          <router-link to="/">Home</router-link>
                        </li>
                        <li
                          :class="currentPath == 'index-two' ? 'active' : 'notactive'"
                        >
                          <router-link to="/index-two">Home 2</router-link>
                        </li>
                        <li
                          :class="
                            currentPath == 'index-three' ? 'active' : 'notactive'
                          "
                        >
                          <router-link to="/index-three">Home 3</router-link>
                        </li>
                        <li
                          :class="
                            currentPath == 'index-four' ? 'active' : 'notactive'
                          "
                        >
                          <router-link to="/index-four">Home 4</router-link>
                        </li>
                        <li
                          :class="
                            currentPath == 'index-five' ? 'active' : 'notactive'
                          "
                        >
                          <router-link to="/index-five">Home 5</router-link>
                        </li>
                      </ul>
                    </li> -->
                      <li
                        v-bind:class="{ active: currentPath == 'categories' }"
                      >
                        <router-link :to="{ name: 'user-categories' }">{{
                          t("navbar.categories")
                        }}</router-link>
                      </li>
                      <li class="has-submenu">
                        <a href="javascript:void(0);">
                          {{ t("navbar.laserEpilation.laserEpilation") }}
                          <i class="fas fa-chevron-down"></i
                        ></a>
                        <ul class="submenu">
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.laserEpilation.wholeBody,
                                    { lower: true }
                                  ),
                                },
                              }"
                              >{{
                                headerData.laserEpilation.wholeBody
                              }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.laserEpilation.armpit,
                                    { lower: true }
                                  ),
                                },
                              }"
                            >
                              {{ headerData.laserEpilation.armpit }}
                            </router-link>
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.laserEpilation.leg,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                            >
                              {{ headerData.laserEpilation.leg }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.laserEpilation.arm,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                            >
                              {{ headerData.laserEpilation.arm }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.laserEpilation.back,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                            >
                              {{ headerData.laserEpilation.back }}</router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <li class="has-submenu">
                        <a href="javascript:void(0);"
                          >{{ headerData.hair.hair }}
                          <i class="fas fa-chevron-down"></i
                        ></a>
                        <ul class="submenu">
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(headerData.hair.blowDry, {
                                    lower: true,
                                  }),
                                },
                              }"
                              >{{ headerData.hair.blowDry }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(headerData.hair.hairCut, {
                                    lower: true,
                                  }),
                                },
                              }"
                            >
                              {{ headerData.hair.hairCut }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.hair.hairColoring,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                            >
                              {{ headerData.hair.hairColoring }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(headerData.hair.hairCare, {
                                    lower: true,
                                  }),
                                },
                              }"
                            >
                              {{ headerData.hair.hairCare }}</router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <li class="has-submenu">
                        <a href="javascript:void(0);"
                          >{{ headerData.makeUp.makeUp }}
                          <i class="fas fa-chevron-down"></i
                        ></a>
                        <ul class="submenu">
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.makeUp.professional,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                              >{{ headerData.makeUp.professional }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.makeUp.bridalMakeUp,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                            >
                              {{ headerData.makeUp.bridalMakeUp }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.makeUp.silkEyelash,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                            >
                              {{ headerData.makeUp.silkEyelash }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(headerData.makeUp.lashlift, {
                                    lower: true,
                                  }),
                                },
                              }"
                            >
                              {{ headerData.makeUp.lashlift }}</router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <li class="has-submenu">
                        <a href="javascript:void(0);"
                          >{{ headerData.nail.nail }}
                          <i class="fas fa-chevron-down"></i
                        ></a>
                        <ul class="submenu">
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(headerData.nail.manicure, {
                                    lower: true,
                                  }),
                                },
                              }"
                              >{{ headerData.nail.manicure }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(headerData.nail.pedicure, {
                                    lower: true,
                                  }),
                                },
                              }"
                            >
                              {{ headerData.nail.pedicure }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.nail.permanentNailPolish,
                                    { lower: true }
                                  ),
                                },
                              }"
                            >
                              {{
                                headerData.nail.permanentNailPolish
                              }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.nail.prostheticNail,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                            >
                              {{ headerData.nail.prostheticNail }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(headerData.nail.nailArt, {
                                    lower: true,
                                  }),
                                },
                              }"
                            >
                              {{ headerData.nail.nailArt }}</router-link
                            >
                          </li>
                          <li
                            :class="
                              currentPath == 'profile-settings'
                                ? 'active'
                                : 'notactive'
                            "
                          >
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(headerData.nail.gelNail, {
                                    lower: true,
                                  }),
                                },
                              }"
                            >
                              {{ headerData.nail.gelNail }}</router-link
                            >
                          </li>
                        </ul>
                      </li>

                      <li class="has-submenu">
                        <a href="javascript:void(0);"
                          >{{ headerData.skinCare.skinCare }}
                          <i class="fas fa-chevron-down"></i
                        ></a>
                        <ul class="submenu">
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(headerData.skinCare.classic, {
                                    lower: true,
                                  }),
                                },
                              }"
                              >{{ headerData.skinCare.classic }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.skinCare.professional,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                            >
                              {{
                                headerData.skinCare.professional
                              }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.skinCare.faceWaxing,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                            >
                              {{ headerData.skinCare.faceWaxing }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.skinCare.aboveLip,
                                    {
                                      lower: true,
                                    }
                                  ),
                                },
                              }"
                            >
                              {{ headerData.skinCare.aboveLip }}</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              :to="{
                                name: 'search-content',
                                params: {
                                  search: slugify(
                                    headerData.skinCare.eyeBrowRemoval,
                                    { lower: true }
                                  ),
                                },
                              }"
                            >
                              {{
                                headerData.skinCare.eyeBrowRemoval
                              }}</router-link
                            >
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  v-if="headerData.showOnlyMobile"
                  class="col-4 sidebar-outline menu-close"
                  id="menu_close"
                ></div>
              </div>

              <ul class="nav header-navbar-rht">
                <!-- <li class="nav-item desc-list wallet-menu">
                  <router-link to="/user-wallet" class="nav-link header-login">
                    <img
                      src="../../../assets/img/wallet.png"
                      alt=""
                      class="me-2 wallet-img"
                    /><span>Wallet:</span> $1875
                  </router-link>
                </li> -->

                <!-- Notifications -->
                <div class="me-2">
                  <user-notification-dropdown></user-notification-dropdown>
                </div>
                <!-- /Notifications -->

                <!-- chat -->
                <!-- <li class="nav-item logged-item">
                  <router-link to="/chat" class="nav-link">
                    <i class="fa fa-comments" aria-hidden="true"></i>
                  </router-link>
                </li> -->
                <!-- /chat -->

                <!-- User Menu -->
                <li class="nav-item dropdown has-arrow logged-item me-3">
                  <a
                    href="javascript:void(0);"
                    class="dropdown-toggle nav-link pe-0"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span class="user-img">
                      <img
                        class="rounded-circle"
                        :src="profile.user?.profilePicture ?? defaultNoImgWebp"
                        alt=""
                      />
                    </span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <div class="user-header">
                      <div class="avatar avatar-sm">
                        <img
                          class="avatar-img rounded-circle"
                          :src="
                            profile.user?.profilePicture ?? defaultNoImgWebp
                          "
                          alt="profile image"
                        />
                      </div>
                      <div class="user-text">
                        <h6>
                          {{ profile.user.name }} {{ profile.user.surname }}
                        </h6>
                        <!-- <p class="text-muted mb-0">User</p> -->
                      </div>
                    </div>
                    <!-- <router-link class="dropdown-item" to="/user-dashboard"
                      >Dashboard</router-link
                    >
                    <router-link class="dropdown-item" to="/favourites"
                      >Favourites</router-link
                    >
                    <router-link class="dropdown-item" to="/user-bookings"
                      >My Bookings</router-link
                    >
                    <router-link class="dropdown-item" to="/profile-settings"
                      >Profile Settings</router-link
                    >
                    <router-link class="dropdown-item" to="/all-services"
                      >Book Services</router-link
                    >
                    <router-link class="dropdown-item" to="/chat">Chat</router-link> -->
                    <router-link class="dropdown-item" to="/logout">
                      <span class="material-icons-round me-2">
                        logout
                      </span>
                      <span
                        style="
                      color: #2A3042;
                      font-family: Poppins;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;"
                      >
                        Çıkış Yap
                      </span>
                    </router-link>
                  </div>
                </li>
                <!-- /User Menu -->
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <!-- /Header -->
    </div>

    <!-- User Register Modal -->
    <user-register-modal></user-register-modal>
    <!-- /User Register Modal -->

    <!-- Login Modal -->
    <user-login-modal></user-login-modal>
    <!-- /Login Modal -->
  </div>
</template>
