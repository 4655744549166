<script setup></script>

<template>
  <div class="row mt-4">
    <div class="col-12">
      <div>
        <h4>Kişisel Verilerin Korunması Hakkında Aydınlatma Metni</h4>

        <p class="font-size-14">
          ixirlife Teknoloji A.Ş. (Kısaca ixirlife Teknoloji olarak
          anılacaktır.) bünyesindeki kişisel verilerin, 6698 sayılı Kişisel
          Verilerin Korunması Kanunu (Kısaca KVKK olarak anılacaktır.) uyarınca
          güvenli şekilde saklanması, hukuka uygun olarak işlenmesi için gerekli
          teknik ve hukuki önlemler tarafımızca alınmaktadır. Kanun uyarınca
          kişisel verilerinizin toplanması, işlenmesi ve aktarılması hakkında
          bilgilendirmek amacıyla işbu KVKK Aydınlatma Metni düzenlenmiştir.
        </p>

        <p class="font-size-14">
          Kişisel veri, kimliği belirli veya belirlenebilir gerçek kişiye
          ilişkin her türlü bilgiyi; kişisel verilerin işlenmesi, kişisel
          verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri
          kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde
          edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi,
          değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması,
          devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya
          da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen
          her türlü işlemi; veri sorumlusu, kişisel verilerin işleme amaçlarını
          ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve
          yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi; veri işleyen
          ise veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel
          verileri işleyen gerçek veya tüzel kişiyi ifade eder.
        </p>
      </div>

      <div>
        <h4>Kişisel Verilerin Toplanması, İşlenmesi ve İşleme Amaçları</h4>

        <p class="font-size-14">
          Kişisel verileriniz, 6698 sayılı KVKK’nın 4, 5 ve 6.maddelerine uygun
          olarak ixirlife Teknoloji tarafından sağlanan hizmet ve ticari
          faaliyetlere bağlı olarak değişkenlik gösterebilmekte ve otomatik ya
          da otomatik olmayan yöntemlerle, imza, onay veya icazetle tanzim
          edilen işlemlere ilişkin tüm sair belgelerle, ixirlife Teknoloji
          birimleri, internet sitesi ve benzeri her türlü elektronik uygulamalar
          ile sözlü, yazılı ya da elektronik olarak yasal düzenlemelerin
          gerektirdiği veya zorunlu kıldığı şekilde hukuki yükümlülüklerimizin
          yerine getirilmesini sağlamak, ticari faaliyetlerin mevzuata ve şirket
          politikalarına uygun olarak yerine getirilmesi için gerekli
          çalışmaların yapılması ve bu doğrultuda faaliyetlerin yürütülmesi,
          kısa, orta, uzun vadeli ticari politikalarının tespiti, planlanması ve
          uygulanması, ixirlife Teknoloji tarafından sunulan hizmet ürün ve
          projelerin ilgilisinin kişisel tercihlerine göre kişiselleştirilmesi,
          sunulan ürün, hizmet ve projeler hakkında bilgi verilmesi, etkin bir
          müşteri hizmetinin sunulabilmesi, her türlü reklam faaliyetlerinin
          yürütülebilmesi, ziyaretçi profillerinin belirlenebilmesi, istek,
          talep ve şikayetlerin çözümlenmesinin sağlanması, istatistiksel
          çalışmalar yapılabilmesi, iş ortakları ile irtibat sağlanması,
          teknolojik alt yapışların oluşturulması ve yönetilmesi, finans
          işlemlerinin takibi, hukuk işlerinin takibi, verilerin doğru ve güncel
          olmasının sağlanması ve kanunun izin verdiği her türlü iş ve işlem
          için toplanabilecektir.
        </p>
      </div>

      <div>
        <h4>Kişisel Verilerin Aktarılması ve Aktarılma Amaçları:</h4>

        <p class="font-size-14">
          Kişisel verileriniz, 6698 sayılı KVKK’nın 8 ve 9.maddelerine uygun
          olarak ixirlife Teknoloji ile ilişki içerisinde olan kişilerin hukuki,
          teknik ve ticari güvenliğinin temini ve yukarıda belirtilen işleme
          amaçları doğrultusunda üçüncü kişi ve kurumlara aktarabilecektir. Bu
          kişi ve kurumlar ixirlife Teknoloji’nin iş ortakları, şirket
          yetkilileri, danışmanları, denetçileri, hukuken bilgi almaya yetkili
          kamu kurum ve kuruluşları ve hukuken yetkili özel hukuk tüzel
          kişileridir. Kişisel verileriniz kanunun 8 ve 9.maddelerinde
          belirtilen kişisel veri aktarım şartları ve amaçları çerçevesinde yurt
          içindeki üçüncü kişilere aktarabileceği gibi yurt dışına da
          aktarabilecektir.
        </p>
      </div>

      <div>
        <h4>Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi:</h4>

        <p class="font-size-14">
          Kişisel verileriniz, her türlü sözlü, yazılı ya da elektronik ortamda,
          yukarıda belirtilen amaçlar doğrultusunda hizmetlerimizin
          sunulabilmesi ve bu kapsamda ixirlife Teknoloji'nin yükümlülüklerini
          eksiksiz ve hukuka uygun bir şekilde yerine getirebilmesi ve her
          halükârda şirket politikası ve ticari hayatın teamülleri uyarınca
          işlenmesini gerektiren süre ile sınırlı olarak işlenmekte ve sürelerin
          dolması ile silinmekte, yok edilmekte veya anonim hale
          getirilmektedir.
        </p>

        <p class="font-size-14">
          Toplanan kişisel verileriniz, KVKK’nın 4.maddesinde düzenlenen temel
          ilkelere uygun olarak, KVKK’nın 5 ve 6. maddelerinde belirtilen
          kişisel veri işleme şartları ve amaçları kapsamında bu metinde
          belirtilen amaçlarla da işlenebilmekte ve aktarılabilmektedir.
          Bunların yanında kanunlarda açıkça öngörülen hallerde, veri sorumlusu
          sıfatıyla ixirlife Teknoloji'nin yasal yükümlülüklerini yerine
          getirebilmesi için zorunlu olan, ilgili kişinin kendisi tarafından
          alenileştirilen, ilgili kişinin temel hak ve özgürlüklerine zarar
          vermemek kaydıyla veri sorumlusu sıfatını haiz şirketimizin yasal
          menfaatleri için işlenmesi zorunlu olan verileriniz, belirtilen
          sürelerin geçmesi durumunda ancak bu cümlede tahdidi olarak
          belirtilmiş amaçların gerçekleştirilmesi için kullanılabilecektir. Bu
          nedenlerle saklanan kişisel verilerinize başka bir amaç ile
          erişilmesine izin verilmeyecek ve ancak zorunlu hallerde
          kullanılabilecektir. Zorunluluk halinin sona ermesiyle birlikte
          kişisel verileriniz usulüne uygun olarak silinecek, yok edilecek veya
          anonim hale getirilecektir.
        </p>
      </div>

      <div>
        <h4>Kişisel Verilerin Toplanmasında Rıza Aranmayan Haller:</h4>

        <p class="font-size-14">
          KVKK’nın 5.maddesi uyarınca aşağıdaki hallerde kişisel verilerinizin
          açıkça rıza bulunmayan hallerde de saklanması mümkündür:
        </p>

        <ul>
          <li>Kanunlarda açıkça öngörülmesi.</li>
          <li>
            Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan
            veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da
            bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu
            olması.
          </li>
          <li>
            Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
            olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
            işlenmesinin gerekli olması.
          </li>
          <li>
            Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için
            zorunlu olması.
          </li>
          <li>İlgili kişinin kendisi tarafından alenileştirilmiş olması.</li>
          <li>
            Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
            zorunlu olması.
          </li>
          <li>
            İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
            veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
            olması.
          </li>
        </ul>
      </div>

      <div>
        <h4>Kişisel Veri Sahibinin Hakları:</h4>

        <p class="font-size-14">
          KVKK’da belirtilen yöntemlerle taleplerinizi ixirlife Teknoloji'nin
          Muallimköy Mah. Deniz Cad. No:143/5 İç Kapı No:40 Gebze/KOCAELİ
          adresine yazılı olarak iletmeniz durumunda, talebinizi niteliğine göre
          en kısa sürede ve en geç 30 gün içinde ücretsiz olarak
          sonuçlandıracaktır.
        </p>

        <p class="font-size-14">
          Ancak, Kişisel Verileri Koruma Kurulunca bir ücret öngörülmesi
          halinde, belirlenen tarifedeki ücret alınabilir. Başvurularda
          başvurucunun adı-soyadı, Türkiye Cumhuriyeti vatandaşları için TC
          kimlik numarası, yabancılar için uyruğu, ve pasaport numarası,
          tebligata elverişli yerleşim yeri adresi, varsa elektronik posta
          adresi ve telefon numarası ile talep konusu bulunması zorunludur.
        </p>

        <p class="font-size-14">
          Talep konusunun açık ve anlaşılır olması, başvurucyla ilgilisi olması,
          başkası adına hareket edilmekte ise usulüne uygun olarak
          yetkilendirilmiş olması zorunludur. Aksi halde başvuru yapılmamış
          sayılır. KVKK’nın 11.maddesine göre kişisel verisi işlenen gerçek
          kişinin sahip olduğu haklar aşağıda belirtilmiştir.
        </p>

        <ul>
          <li>Kişisel veri işlenip işlenmediğini öğrenme</li>
          <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme</li>
          <li>
            Kişisel verilerin işlenme amacını ve bunların amacına uygun
            kullanılıp kullanılmadığını öğrenme
          </li>
          <li>
            Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
            kişileri bilme
          </li>
          <li>
            Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
            düzeltilmesini isteme
          </li>
          <li>
            KVKK’nın 7.maddesinde öngörülen şartlar çerçevesinde kişisel
            verilerin silinmesini veya yok edilmesini isteme
          </li>
          <li>
            Yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere
            bildirilmesini isteme
          </li>
          <li>
            İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
            edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
            çıkmasına itiraz etme
          </li>
          <li>
            Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
            uğraması hâlinde zararın giderilmesini talep etme
          </li>
        </ul>
      </div>

      <div>
        <h4>Aydınlatma Metnindeki Değişiklikler:</h4>

        <p class="font-size-14">
          İşbu Aydınlatma Metni, Kişisel Verilerin Korunması Kanunu kapsamında
          veri sorumlusu sıfatıyla ixirlife Teknoloji tarafından düzenlenmiş
          olup, kanunun izin verdiği şartlarda her zaman ixirlife Teknoloji
          tarafından değiştirilebilir.
        </p>
      </div>
    </div>
  </div>
</template>
