<template>

	<div class="main-wrapper">

	<layout-headercustomer></layout-headercustomer>
    
    <div class="content">
        <div class="container">
            <div class="row"> 
                <customersidebar></customersidebar>
                <div class="col-xl-9 col-md-8">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Wallet</h4>
                                    <div class="wallet-details">
                                        <span>Wallet Balance</span>
                                        <h3>$872.1</h3>
                                        <div class="d-flex justify-content-between my-4">
                                            <div>
                                                <p class="mb-1">Total Credit</p>
                                                <h4>$29,720.80</h4>
                                            </div>
                                            <div>
                                                <p class="mb-1">Total Debit</p>
                                                <h4>$29,258.00</h4>
                                            </div>
                                        </div>
                                        <div class="wallet-progress-chart">
                                            <div class="d-flex justify-content-between">
                                                <span>$29258</span>
                                                <span>$29,720.80</span>
                                            </div>
                                            <div class="progress mt-1">
                                                <div class="progress-bar bg-theme" role="progressbar" aria-valuenow="41" aria-valuemin="0" aria-valuemax="100" style="width:98%">98.44%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <addwallet></addwallet>
                    </div>
                    <h4 class="mb-4">Wallet Transactions</h4>
                    <wallettable1></wallettable1>
                </div>
            </div>
        </div>
    </div> 
	
	<layout-footer></layout-footer>

	</div>

</template>