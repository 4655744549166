import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getCustomerProfileInformations,
  updateProfileInformations,
} from "../../api/customerprofile.service";

const defaultProfileState = () => {
  return {
    adress: "",
    birthday: null,
    city: "",
    cityID: 0,
    email: "",
    gender: "",
    genderID: 0,
    id: 0,
    name: "",
    notificationToken: "",
    phone: "",
    profilePicture: "",
    registrationDate: "",
    response: null,
    surname: "",
    token: null,
    town: "",
    townID: 0,
  };
};

export const state = reactive({
  loaded: false,
  phoneValidateModal: false,
  isProfileSmsValidated: false,
  profileSaved: false,
  profile: defaultProfileState(),
  profileInformations: [],
  profileModel: [],
  uploadedPicture: null,
});

export const actions = {
  async getCustomerProfileInformations({ commit }, data) {
    const responseObj = await getCustomerProfileInformations(data);

    commit("profileInformations", responseObj[0]);
    commit("profile", responseObj[0]);
    commit("profileModel", responseObj[0]);
    commit("loaded", responseObj.length >= 0);

    return responseObj;
  },

  async updateProfileInformations({ commit }, data) {
    const responseObj = await updateProfileInformations(data);

    const { success, message } = responseObj;

    // const { success, message } = response;

    if (success) {
      commit("profileSaved", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },
};

export const mutations = {
  loaded(state, data) {
    state.loaded = data;
  },
  profileSaved(state, data) {
    state.profileSaved = data;
  },
  phoneValidateModal(state, data) {
    state.phoneValidateModal = data;
  },
  isProfileSmsValidated(state, data) {
    state.isProfileSmsValidated = data;
  },
  uploadedPicture(state, data) {
    state.uploadedPicture = data;
  },
  profile(state, data) {
    state.profile = data;
  },
  profileModel(state, data) {
    state.profileModel = data;
  },
  profileInformations(state, data) {
    state.profileInformations = data;
  },
  resetCompanyProduct(state) {},
  resetValidateModal(state) {
    state.isProfileSmsValidated = false;
  },
};
