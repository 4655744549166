import moment from "moment";

const dateFormat = (date, format) =>
  moment(date).isValid() ? moment(date).format(format) : "";

const customDateFormatTR = (date, format) =>
  moment(date).isValid()
    ? moment(date)
        .locale("tr-TR")
        .format(format)
    : "";

const timeFormat = (time, format) => moment(time, format).format(format);

const moneyFormat = (number) =>
  new Intl.NumberFormat("tr", { style: "currency", currency: "TRY" }).format(
    number
  );

const numberFormatWOC = (number) => {
  // const formatedNumber = number.toLocaleString("en-US");
  // const formatedNumber = number.toLocaleString("tr-TR");
  const formatedNumber = new Intl.NumberFormat({
    style: "currency",
    currency: "TRY",
  }).format(number.toFixed(2));

  return formatedNumber;
};

const calculateDays = (date) => {
  const m = moment(date);
  const today = moment().startOf("day");
  const days = Math.round(moment.duration(today - m).asDays());

  return {
    days: days,
  };
};

const serialize = (obj) =>
  obj
    ? Object.keys(obj)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
        )
        .join("&")
    : "";

const truncateString = (str, length, suffix = "") =>
  str ? str.slice(0, length) + suffix : str;

function passwordGenerate(
  length = 12,
  charset = "!?*_@&abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
) {
  return new Array(length)
    .fill(null)
    .map(() => charset.charAt(Math.floor(Math.random() * charset.length)))
    .join("");
}

function getRandomInt(min = 10000, max = 999999) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const uptimeParser = (seconds, returnStr = false) => {
  const days = Math.floor(seconds / (24 * 60 * 60));
  seconds -= days * (24 * 60 * 60);
  const hours = Math.floor(seconds / (60 * 60));
  seconds -= hours * (60 * 60);
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  let str = "";

  if (days > 0) {
    str += `${days} gün, `;
  }

  if (hours > 0) {
    str += `${hours} saat, `;
  }

  if (minutes > 0) {
    str += `${minutes} dakika, `;
  }

  if (seconds > 0) {
    str += `${seconds} saniye`;
  }

  return returnStr
    ? str
    : {
        days,
        hours,
        minutes,
        seconds,
      };
};

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export {
  dateFormat,
  customDateFormatTR,
  timeFormat,
  moneyFormat,
  numberFormatWOC,
  calculateDays,
  serialize,
  truncateString,
  passwordGenerate,
  getRandomInt,
  uptimeParser,
  formatBytes,
};
