import Cookie from "../helpers/cookie";
import axios from "axios";

const isDev = process.env.NODE_ENV === "development";

const baseUrl = isDev
  ? process.env.VUE_APP_API_DEV_URL
  : process.env.VUE_APP_API_PROD_URL;

const fileUploadBaseUrl = process.env.VUE_APP_FILE_URL;
const ticketUrl = process.env.VUE_APP_TICKET_URL;

const appAxios = axios.create({
  baseURL: baseUrl,
});

const user = () => {
  try {
    // return JSON.parse(sessionStorage.getItem("ixirlifeBireyselPanelUser"));
    return Cookie.getJson("ixirlifeBireyselPanelUser");
  } catch (error) {
    return false;
  }
};

const checkClientAuthorities = (paketAdi, personelYetki) => {
  const kontrol = {
    success: false,
    private: false,
    title: "",
    message: "",
    buyPackage: false,
  };

  let _paket = "";
  try {
    _paket = paketAdi.toLocaleLowerCase();
  } catch (error) {
    return false;
  }

  if (
    _paket.includes("demo") ||
    _paket.includes("standart") ||
    _paket.includes("pro")
  ) {
    kontrol.success = true;
    kontrol.private = true;

    if (!personelYetki.ayarlarGenelAyarIslemleri) {
      kontrol.success = false;
      kontrol.private = false;

      kontrol.title = "UYARI";
      kontrol.message = `Bu sayfayı görüntülemek için yetkiniz bulunmamaktadır`;
    }
  } else if (_paket.includes("baslangic")) {
    kontrol.success = true;
    kontrol.private = false;
  } else {
    kontrol.title = "UYARI";
    kontrol.message = `${paketAdi} kullanıcıları genel ayarları değiştiremez. Dilerseniz Pro Paket satın alabilirsiniz`;
    kontrol.buyPackage = true;
  }

  return kontrol;
};

const setUser = (userJson) => {
  try {
    // return sessionStorage.setItem(
    //   "ixirlifeBireyselPanelUser",
    //   JSON.stringify(userJson)
    // );

    return Cookie.set("ixirlifeBireyselPanelUser", JSON.stringify(userJson), {
      days: 30,
    });
  } catch (error) {
    return false;
  }
};

const tokenString = () => {
  const userJson = user();

  if (userJson) {
    // console.log("userJson >> ", userJson);
    const { token } = userJson;

    return `Bearer ${token}`;
  }
};

const sliceObjectForPagination = (currentPage, perPage, modelComputed) => {
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = currentPage * perPage;
  return modelComputed.value.slice(startIndex, endIndex);
};

const convertToLowerCase = (text) => {
  let string = "";
  let letters = { İ: "i", I: "i", Ş: "s", Ğ: "g", Ü: "u", Ö: "o", Ç: "c" };
  string = text.replace(/(([İIŞĞÜÇÖ]))/g, function(letter) {
    return letters[letter];
  });
  return string.toLocaleLowerCase("tr-TR");
};
const convertToUpperCase = (text) => {
  let string = "";
  let letters = { i: "I", ş: "S", ğ: "G", ü: "U", ö: "O", ç: "C", ı: "I" };
  string = text.replace(/(([iışğüçö]))/g, function(letter) {
    return letters[letter];
  });
  return string.toLocaleUpperCase("tr-TR");
};

export {
  baseUrl,
  fileUploadBaseUrl,
  ticketUrl,
  user,
  tokenString,
  setUser,
  checkClientAuthorities,
  appAxios,
  sliceObjectForPagination,
  convertToLowerCase,
  convertToUpperCase,
};
