<script setup>
import Swal from "sweetalert2";
import { useStore } from "vuex";
import { computed, reactive, watch } from "vue";
import { useRouter } from "vue-router";
import vueQr from "vue-qr/src/packages/vue-qr.vue";

const store = useStore();
const router = useRouter();

const modalData = reactive({
  qrContent: "",
  loadInterval: null,
  logoSrc: "../../../../assets/img/home/qr-logo.svg",
});
const modalElements = reactive({
  loginEl: null,
  loginObj: null,
  loginIns: null,
});
const loginModel = reactive({
  phone: "",
  smsCode: "",
});

const options = reactive({
  mask: "0###-###-##-##",
  // eager: true,
});

const loginModal = computed(() => {
  return store.state["auth"].userLoginModal;
});
const isUserLoginPhoneValidated = computed(() => {
  return store.state["auth"].isUserLoginPhoneValidated;
});
const isUserLoginSmsValidated = computed(() => {
  return store.state["auth"].isUserLoginSmsValidated;
});

const generateRandomlyHash = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
watch(loginModal, (newValue, oldValue) => {
  if (newValue) {
    // modalData.qrContent = Math.random()
    //   .toString(36)
    //   .slice(-10);
    modalData.qrContent =
      /*"https://ixirlife.com/" +
      "ixirlife bireysel uygulamasındaki yan menüden Qr okuyucu ile taratın-" +*/

      "ixirlifebireysel://token/" + generateRandomlyHash(8);

    modalData.loadInterval = setInterval(async () => {
      const responseObj = await store.dispatch("auth/sendQrForUserLogin", {
        // qrCode: "4gRiVDmA",
        qrCode: modalData.qrContent.slice(-8),
      });

      if (responseObj.success) {
        clearInterval(modalData.loadInterval);

        store.commit("auth/userLoginModal", false);
        router.push({ name: "user-appointments" });
      }
    }, 1000);
    // setTimeout(async () => {
    //   const responseObj = await store.dispatch("auth/sendQrForUserLogin", {
    //     // qrCode: "FnLRmuKn",
    //     qrCode: modalData.qrContent,
    //   });
    // }, 1000);

    if (modalElements.loginObj == null) {
      modalElements.loginIns = bootstrap.Modal.getOrCreateInstance(
        document.getElementById("login_modal"),
        {
          backdrop: true,
          keyboard: true,
          focus: true,
        }
      );
    }
    modalElements.loginEl = document.querySelector("#login_modal");
    modalElements.loginObj = bootstrap.Modal.getInstance(modalElements.loginEl);

    modalElements.loginEl.addEventListener("hidden.bs.modal", function(event) {
      store.commit("auth/userLoginModal", false);
    });

    modalElements.loginObj.show();
  } else {
    clearInterval(modalData.loadInterval);
    Object.keys(loginModel).forEach((key) => {
      loginModel[key] = "";
    });

    store.commit("auth/resetModal");
    modalElements.loginObj.hide();
  }
});

const closeLoginModal = () => {
  store.commit("auth/userLoginModal", false);
};

const changeModal = () => {
  store.commit("auth/userLoginModal", false);
  store.commit("auth/userRegisterModal", true);
};

const sendSmsForUserLoginV2 = async () => {
  const model = {
    phone: loginModel.phone.split("-").join(""),
    token: "190720191452?*DePOProje*?",
  };

  if (model.phone.length == 0 || model.phone.length < 11) {
    Swal.fire({
      title: "UYARI",
      text: "Lütfen telefon numaranızı kontrol ediniz.",
      icon: "warning",
      confirmButtonText: "Tamam",
    });

    return;
  }
  await store.dispatch("auth/sendSmsForUserLoginV2", model);
  // if (isUserLoginPhoneValidated) {
  //   await store.dispatch("auth/sendSmsForUserLoginV2", model);
  // }
};

const validateSmsForUserLogin = async () => {
  const model = {
    phone: loginModel.phone.split("-").join(""),
    smsCode: loginModel.smsCode,
    // token: "190720191452?*DePOProje*?",
  };

  const responseObj = await store.dispatch(
    "auth/validateSmsForUserLogin",
    model
  );

  if (responseObj.success) {
    store.commit("auth/userLoginModal", false);
    router.push({ name: "user-appointments" });
  }
};
const onReadHandler = (dataUrl, id) => {
  // console.log(dataUrl, id);
};

const gifs = require.context("../../../../assets/img/home", false, /\.gif$/);
const getGif = (imgPath) => {
  return gifs("./" + imgPath + ".gif").default;
};
const images = require.context("../../../../assets/img/home", false, /\.svg$/);
const getImg = (imgPath) => {
  return images("./" + imgPath + ".svg").default;
};
</script>

<template>
  <div
    class="modal account-modal fade"
    id="login_modal"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header p-0 border-0">
          <button type="button" class="close" @click="closeLoginModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="login-header">
            <h3>Giriş Yap</h3>
          </div>

          <div class="d-flex justify-content-center mb-3">
            <!-- :logo-src="getImg('qr-logo')" -->
            <vue-qr
              :text="modalData.qrContent"
              :size="275"
              :callback="onReadHandler"
              qid="123456"
              color-light="#fff"
              color-dark="#572a86"
            ></vue-qr>
          </div>

          <div class="d-flex justify-content-center mt-3">
            <span style="color: #555A64;font-size: 14px;"
              >Telefonuzun Kamerasıyla QR Kodu Okutarak Giriş Yapın</span
            >
          </div>
          <div
            class="custom-time-daily mt-3 mb-3"
            style="font-weight: 600; color: #555b6d"
          >
            <span style="color: #E9E9E9;font-size: 14px;">ya da</span>
          </div>

          <form v-if="!isUserLoginPhoneValidated">
            <div class="form-group form-focus">
              <label class="focus-label">Telefon Numaranızı Yazınız</label>
              <input
                type="text"
                v-maska:[options]
                class="form-control"
                placeholder="____ ___ __ __"
                v-model="loginModel.phone"
              />
            </div>

            <router-link to="/">
              <div class="d-grid justify-content-center">
                <button
                  class="btn app-confirm-btn btn-lg "
                  style="width: 340px;"
                  type="submit"
                  @click="sendSmsForUserLoginV2"
                >
                  Doğrulama Kodu Gönder
                </button>
              </div>
            </router-link>

            <div class="text-center dont-have mt-5">
              Hesabınız yok mu? Hemen
              <a href="javascript:void(0);" @click="changeModal">Kayıt Olun</a>
            </div>
          </form>

          <form v-if="isUserLoginPhoneValidated">
            <div class="form-group form-focus">
              <label class="focus-label"
                >Telefonunuza Gelen Onay Kodunu Giriniz</label
              >
              <input
                type="text"
                class="form-control"
                v-model="loginModel.smsCode"
              />
            </div>

            <router-link to="/">
              <div class="d-grid">
                <button
                  class="btn app-confirm-btn btn-block btn-lg login-btn"
                  type="submit"
                  @click="validateSmsForUserLogin"
                >
                  Giriş Yap
                </button>
              </div>
            </router-link>

            <div class="text-center dont-have mt-5">
              Hesabınız yok mu? Hemen Kayıt Olun
              <a href="javascript:void(0);" @click="changeModal">Kayıt Olun</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-content {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
/*center the heading and lines*/
.custom-time-daily span {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/*add and adjust the lines*/
.custom-time-daily span:before,
.custom-time-daily span:after {
  content: "";
  border-top: 1px solid;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
  color: #e9e9e9;
}
/*add spacing between heading and lines*/
.custom-time-daily span:after {
  margin: 0 0 0 20px;
}
</style>
