<script setup>
import privacyAndKvkkPolicy from "./privacy-and-kvkk-policy.vue";
import lightingText from "./lighting-text.vue";
import ConditionsOfUse from "./conditions-of-use.vue";

import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();

const modalElements = reactive({
  conditionEl: null,
  conditionObj: null,
  conditionIns: null,
});

const conditionsOfUseModal = computed(() => {
  return store.state["auth"].conditionsOfUseModal;
});

watch(conditionsOfUseModal, (newVal) => {
  if (newVal) {
    if (modalElements.conditionObj == null) {
      modalElements.conditionIns = bootstrap.Modal.getOrCreateInstance(
        document.getElementById("conditions-of-use"),
        {
          backdrop: true,
          keyboard: true,
          focus: true,
        }
      );
    }
    modalElements.conditionEl = document.querySelector("#conditions-of-use");
    modalElements.conditionObj = bootstrap.Modal.getInstance(
      modalElements.conditionEl
    );
    modalElements.conditionEl.addEventListener("hidden.bs.modal", function(
      event
    ) {
      store.commit("auth/conditionsOfUseModal", false);
    });

    modalElements.conditionObj.show();
  } else {
    modalElements.conditionObj.hide();
  }
});

const onClose = () => {
  store.commit("auth/conditionsOfUseModal", false);
};
const modalHidden = () => {
  // authUserStore.conditionsOfUseModal = false;
};
</script>

<template>
  <!-- <b-modal
    id="conditions-of-use"
    size="xl"
    @hidden="modalHidden"
    v-model="modal"
    title="Kullanım Koşulları"
    title-class="font-18"
    ok-title="Tamam"
    hide-header-close
  > -->
  <div
    class="modal account-modal fade multi-step"
    id="conditions-of-use"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header p-0 border-0">
          <button type="button" class="close" @click="onClose">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="login-header">
            <h3>Kullanım Koşulları</h3>
          </div>
          <div class="row">
            <nav>
              <div class="tab-widget nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  class="nav-link active alignment-content default-tab"
                  id="nav-condition-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-condition"
                  type="button"
                  role="tab"
                  aria-controls="nav-condition"
                  aria-selected="true"
                  style="width: 20%"
                >
                  Kullanım Koşulları
                </button>
                <button
                  class="nav-link alignment-content default-tab"
                  id="nav-privacy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-privacy"
                  type="button"
                  role="tab"
                  aria-controls="nav-privacy"
                  aria-selected="false"
                  style="width: 25%"
                >
                  Gizlilik ve KVKK Politikası
                </button>
                <button
                  class="nav-link alignment-content default-tab"
                  id="nav-lighting-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-lighting"
                  type="button"
                  role="tab"
                  aria-controls="nav-lighting"
                  aria-selected="false"
                  style="width: 20%"
                >
                  Aydınlatma Metni
                </button>
                <button
                  class="nav-link default-tab"
                  id="condition-disabled-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#condition-disabled"
                  type="button"
                  role="tab"
                  aria-controls="condition-disabled"
                  aria-selected="false"
                  disabled
                  style="width: 35%"
                ></button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-condition"
                role="tabpanel"
                aria-labelledby="nav-condition-tab"
                tabindex="0"
              >
                <conditions-of-use></conditions-of-use>
              </div>
              <div
                class="tab-pane fade"
                id="nav-privacy"
                role="tabpanel"
                aria-labelledby="nav-privacy-tab"
                tabindex="0"
              >
                <privacy-and-kvkk-policy></privacy-and-kvkk-policy>
              </div>
              <div
                class="tab-pane fade"
                id="nav-lighting"
                role="tabpanel"
                aria-labelledby="nav-lighting-tab"
                tabindex="0"
              >
                <lighting-text></lighting-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <template #modal-footer>
      <b-button @click="onClose" variant="danger">Tamam</b-button>
    </template>
  </b-modal> -->
</template>

<style>
.tab-widget.nav-tabs {
  background-color: #fff !important;
}
.tab-widget.nav-tabs#nav-tab .nav-link.active {
  background-color: #fff;
}
.tab-widget.nav-tabs#nav-tab .nav-link:focus {
  background-color: #fff !important;
}
.tab-widget.nav-tabs#nav-tab .nav-link:hover {
  background-color: #fff !important;
}
.modal-dialog .modal-content {
  background: #fff !important;
}
</style>
