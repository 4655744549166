import axios from "axios";

const isDev = process.env.NODE_ENV === "development";

const baseUrl = isDev
  ? process.env.VUE_APP_API_DEV_URL
  : process.env.VUE_APP_API_PROD_URL;

const instance = axios.create({
  baseURL: baseUrl,
  // withCredentials: true,
});

export default instance;
