<script setup>
import { computed, onMounted } from "vue";
import { user } from "../../../../api/auth.service";

const defaultNoImgWebp =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/NoImage.webp";

onMounted(() => {});

const userCookie = computed(() => {
  return user();
});
</script>

<template>
  <div class="col-md-12 col-lg-12 mb-">
    <div class="loading-skeleton border-0">
      <div class="row">
        <div class="col-2  d-flex align-items-center">
          <img
            :src="defaultNoImgWebp"
            class="bg-black mt-"
            style="border-radius: 6px;"
            width="120"
            height="120"
            alt="..."
          />
        </div>
        <div class="col-8">
          <div class="service-content mt- pt-0 pb-0">
            <h3 class="title">
              Company Name
            </h3>
            <div class="d-inline-flex justify-content- align-items-center mt-2">
              <div class="rating mapgridrating1 me-4">
                <i class="fas fa-star" v-for="index in 5"></i>
                <span class="d-inline-block average-rating">
                  Rating
                </span>
              </div>

              <div class="d-flex">
                <span
                  class="d-inline-flex align-items-center color-cc3370 cursor-pointer"
                  v-if="userCookie"
                >
                  <span class="text-decoration-underline">Favorilere Ekle</span>
                  <span class="material-icons-round ms-">
                    favorite_border
                  </span>
                </span>
              </div>
            </div>

            <div class="d-flex justify-content-start mt-4">
              <div class="cate-list">
                <router-link
                  v-for="index in 3"
                  :key="index"
                  class="bg-light-blue me-2"
                  :to="{}"
                >
                  Category Name
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
