<template>

	<div class="main-wrapper">

	<layout-headerservice></layout-headerservice>
    
    <div class="content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                
                    <div class="row chat-window">
                    
                        <!-- Chat User List -->
                        <chatleft></chatleft>
                        <!-- Chat User List -->
                        
                        <!-- Chat Content -->
                        <chatright></chatright>
                        <!-- Chat Content -->
                        
                    </div>

                </div>
            </div>
        </div>
    </div>

	</div>

</template>
<script>
	export default {
		mounted() {
		},
	}
</script>