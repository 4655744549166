import { baseUrl } from "./base.js";
import { serialize } from "../helpers/formatters.js";

const getCompanyComments = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/company-comments?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

export { getCompanyComments };
