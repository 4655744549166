import { baseUrl, tokenString } from "./base.js";
import { serialize } from "../helpers/formatters.js";

const getAppointmentTitles = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/appointment-title?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getAppointmentTimes = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/appointments-check?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const sendSmsForValidateCustomer = async (data) => {
  const endPoint = `${baseUrl}/genel/send-sms`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      // token:"190720191452?*DePOProje*?"
      // Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const validateSmsCode = async (data) => {
  const endPoint = `${baseUrl}/genel/verify-sms`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      // token:"190720191452?*DePOProje*?"
      // Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const saveCustomerAppointment = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-appointment-request`;

  const method = "POST";

  const body = new FormData();

  // Object.keys(data).forEach((key) => {
  //   if (data[key] == null) data[key] = "";

  //   body.append(`${key}`, data[key]);
  // });
  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";
    if (!["product", "token"].includes(key)) {
      body.append(`${key}`, data[key]);
    } else if (!["token"].includes(key)) {
      Object.keys(data[key]).forEach((itemKey) => {
        if ([null, undefined].includes(data[key][itemKey])) {
          data[key][itemKey] = "";
        }

        body.append(
          `Product[${itemKey}][ProductID]`,
          data[key][itemKey].productID
        );
        // }
      });
    }
  });
  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const saveWithoutValidateCustomerAppointment = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-appointment-request`;

  const method = "POST";

  const body = new FormData();

  // Object.keys(data).forEach((key) => {
  //   if (data[key] == null) data[key] = "";

  //   body.append(`${key}`, data[key]);
  // });
  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";
    if (!["product", "token"].includes(key)) {
      body.append(`${key}`, data[key]);
    } else if (!["token"].includes(key)) {
      Object.keys(data[key]).forEach((itemKey) => {
        if ([null, undefined].includes(data[key][itemKey])) {
          data[key][itemKey] = "";
        }

        body.append(
          `Product[${itemKey}][ProductID]`,
          data[key][itemKey].productID
        );
        // }
      });
    }
  });
  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

export {
  getAppointmentTitles,
  getAppointmentTimes,
  sendSmsForValidateCustomer,
  validateSmsCode,
  saveCustomerAppointment,
  saveWithoutValidateCustomerAppointment,
};
