import { baseUrl } from "./base.js";
import { serialize } from "../helpers/formatters.js";
import axiosHttp from "./axios";

let controller;

const getCustomerCompanies = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/company?${qs}`;

  // const requestOptions = {
  //   method: "GET",
  //   headers: {},
  // };
  controller ? controller.abort() : "";
  controller = new AbortController();

  // return await (await fetch(endPoint, requestOptions)).json();

  const response = await axiosHttp
    .get(endPoint, {
      signal: controller.signal,
      headers: {},
    })
    .catch((error) => {
      //
    });

  return response;
};

const getPopularCompanies = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/company-popular?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getSimilarCompanies = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/company-similar?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getCustomerCompanyWithGuid = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/company-guid?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getCustomerCompanyWithSlug = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/company-slug?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getSearchParamsValues = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/path?${qs}`;

  // const requestOptions = {
  //   method: "GET",
  //   headers: {},
  // };
  // return await (await fetch(endPoint, requestOptions)).json();

  let paramsController;
  paramsController ? paramsController.abort() : "";
  paramsController = new AbortController();

  const response = await axiosHttp
    .get(endPoint, {
      signal: paramsController.signal,
      headers: {},
    })
    .catch((error) => {
      //
    });

  return response;
};
// const getCustomerCompanyWithGuid = async (data) => {
//   const qs = serialize(data);

//   const endPoint = `${baseUrl}/genel/company?${qs}`;

//   const requestOptions = {
//     method: "GET",
//     headers: {},
//   };
//   return await (await fetch(endPoint, requestOptions)).json();
// };

export {
  getCustomerCompanies,
  getCustomerCompanyWithGuid,
  getCustomerCompanyWithSlug,
  getPopularCompanies,
  getSimilarCompanies,
  getSearchParamsValues,
};
