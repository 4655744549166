import { baseUrl, tokenString } from "./base.js";
import { serialize } from "../helpers/formatters.js";

const getCustomerNotifications = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-notification`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: tokenString(),
    },
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const readAllNotifications = async () => {
  const endPoint = `${baseUrl}/randevu/customer-notification-all-read`;

  const method = "POST";

  const body = new FormData();

  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};
export { getCustomerNotifications, readAllNotifications };
