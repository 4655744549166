import Swal from "sweetalert2";
import { reactive } from "vue";

import { user } from "../../api/base.js";
import {
  getAppointmentTitles,
  getAppointmentTimes,
  sendSmsForValidateCustomer,
  validateSmsCode,
  saveCustomerAppointment,
  saveWithoutValidateCustomerAppointment,
} from "../../api/appointment.service";

const defaultInformationState = () => {
  return {
    id: 0,
    appointmentDate: null,
    appointmentID: 0,
    appointmentTitle: "",
    appointmentTitleID: null,
    customerID: 0,
    companyID: null,
    product: "",
    productID: null,
    mainProduct: "",
    mainProductID: null,
    startTime: null,
    // email: "",
    // name: "",
    // note: "",
    // phone: "",
    // surname: "",
    name: user().name ?? "",
    surname: user().surname ?? "",
    phone: user().phone ?? "",
    email: user().email ?? "",
    note: "",
  };
};

const defaultTitleState = () => {
  return {};
};

const defaultTimeState = () => {
  return {};
};

export const state = reactive({
  loadedTitles: false,
  loadedTimes: false,
  isCustomerRegistered: false,
  isSmsCodeValidated: false,
  appointmentInformationModal: false,
  appointmentValidateModal: false,
  appointmentCompleteModal: false,
  validateSmsModal: false,
  appointmentSaved: false,
  appointmentInformation: defaultInformationState(),
  appointmentSaveResponse: [],
  appointmentTitles: [],
  appointmentTitle: defaultTitleState(),
  appointmentTimes: [],
  appointmentTime: defaultTimeState(),
});

export const actions = {
  async getAppointmentTitles({ commit }, data) {
    const responseObj = await getAppointmentTitles(data);

    commit("appointmentTitles", responseObj);
    commit("loadedTitles", responseObj.length >= 0);

    return responseObj;
  },

  async getAppointmentTimes({ commit }, data) {
    const responseObj = await getAppointmentTimes(data);

    commit("appointmentTimes", responseObj);
    commit("loadedTimes", responseObj.length >= 0);

    return responseObj;
  },

  async sendSmsForValidateCustomer({ commit }, data) {
    const responseObj = await sendSmsForValidateCustomer(data);

    const { success, message } = responseObj;

    // const { success, message } = response;

    if (success) {
      commit("isCustomerRegistered", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },

  async validateSmsCode({ commit }, data) {
    const responseObj = await validateSmsCode(data);

    const { success, message } = responseObj;
    // const { success, message } = response;

    if (success) {
      commit("isSmsCodeValidated", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },

  async saveCustomerAppointment({ commit }, data) {
    const responseObj = await saveCustomerAppointment(data);

    const { success, message } = responseObj;

    commit("appointmentSaveResponse", responseObj);

    // const { success, message } = response;

    if (success) {
      commit("appointmentSaved", true);
    }

    // Swal.fire({
    //   title: success ? "BİLGİ" : "UYARI",
    //   text: message,
    //   icon: success ? "success" : "warning",
    //   timer: success ? 2000 : null,
    // });

    return responseObj;
  },

  async saveWithoutValidateCustomerAppointment({ commit }, data) {
    const responseObj = await saveWithoutValidateCustomerAppointment(data);

    const { success, message } = responseObj;

    // const { success, message } = response;

    if (success) {
      commit("appointmentSaved", true);
    }

    // Swal.fire({
    //   title: success ? "BİLGİ" : "UYARI",
    //   text: message,
    //   icon: success ? "success" : "warning",
    //   timer: success ? 2000 : null,
    // });

    return responseObj;
  },
};

export const mutations = {
  loadedTitles(state, data) {
    state.loadedTitles = data;
  },
  loadedTimes(state, data) {
    state.loadedTimes = data;
  },
  isCustomerRegistered(state, data) {
    state.isCustomerRegistered = data;
  },
  isSmsCodeValidated(state, data) {
    state.isSmsCodeValidated = data;
  },
  appointmentSaved(state, data) {
    state.appointmentSaved = data;
  },
  appointmentInformationModal(state, data) {
    state.appointmentInformationModal = data;
  },
  appointmentValidateModal(state, data) {
    state.appointmentValidateModal = data;
  },
  appointmentCompleteModal(state, data) {
    state.appointmentCompleteModal = data;
  },
  validateSmsModal(state, data) {
    state.validateSmsModal = data;
  },
  appointmentInformation(state, data) {
    state.appointmentInformation = data;
  },
  informationCompany(state, data) {
    state.appointmentInformation.companyID = data;
  },
  setMainService(state, data) {
    state.appointmentInformation.mainProduct = data.mainProductTitle;
    state.appointmentInformation.mainProductID = data.id;
  },
  setSubService(state, data) {
    state.appointmentInformation.product = data.productTitle;
    state.appointmentInformation.productID = data.id;
  },
  informationSubProduct(state, data) {
    state.appointmentInformation.product = data.productTitle;
    state.appointmentInformation.productID = data.id;
  },
  informationPersonnel(state, data) {
    state.appointmentInformation.appointmentTitle = data.name;
    state.appointmentInformation.appointmentTitleID = data.id;
  },
  informationPersonal(state, data) {
    state.appointmentInformation.name = data.name;
    state.appointmentInformation.surname = data.surname;
    state.appointmentInformation.phone = data.phone;
    state.appointmentInformation.email = data.email;
    state.appointmentInformation.note = data.note;
  },
  informationTime(state, data) {
    state.appointmentInformation.appointmentDate = data.appointmentDate;
    state.appointmentInformation.startTime = data.startTime;
  },
  appointmentSaveResponse(state, data) {
    state.appointmentSaveResponse = data;
  },
  appointmentTitle(state, data) {
    state.appointmentTitle = data;
  },
  appointmentTitles(state, data) {
    state.appointmentTitles = data;
  },
  appointmentTime(state, data) {
    state.appointmentTime = data;
  },
  appointmentTimes(state, data) {
    state.appointmentTimes = data;
  },
  resetAppointmentInformation(state) {
    state.appointmentInformation = defaultInformationState();
  },
  resetCompanyAppointment(state) {
    state.appointmentTitle = defaultTitleState();
    state.appointmentTime = defaultTimeState();
  },
};
