import { baseUrl } from "./base.js";
import { serialize } from "../helpers/formatters.js";

const getCompanyMainProducts = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/main-product?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getCompanySubProducts = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/product?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

export { getCompanyMainProducts, getCompanySubProducts };
