import { baseUrl } from "./base.js";
import { serialize } from "../helpers/formatters.js";

const getCities = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/city?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getCountiesOfCities = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/city-town?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getTowns = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/genel/town?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

export { getCities, getCountiesOfCities, getTowns };
