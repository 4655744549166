<script setup>
import { useStore } from "vuex";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { computed, onMounted, reactive, watch } from "vue";

import { sliceObjectForPagination } from "../../../../api/base";
import { customDateFormatTR } from "../../../../helpers/formatters";
const store = useStore();
const router = useRouter();

const pagination = reactive({
  model: null,
  currentPage: 1,
  perPage: 5,
});

onBeforeRouteLeave((to, from, next) => {
  store.commit("customercomment/loadedComment", false);
  next();
});
onMounted(() => {
  // fetchCompanyComments();
  setTimeout(() => {
    loadedSlugCompany ? onCalculatedWidth() : "";
  }, 300);
});

const companyCommentList = computed(() => {
  const list = store.state["companycomment"].companyComments?.map((comment) => {
    let temp = comment.customer?.split(" ");
    temp[temp.length - 1] = temp[temp.length - 1].substring(0, 1) + ".";
    temp = temp.join(" ");

    return {
      ...comment,
      customer: temp,
    };
  });

  return list;
});
const totalItems = computed(() => {
  return store.state["companycomment"].companyComments.length || 0;
});

const averageRating = computed(() => {
  return store.state["customercompany"].company.rating;
});
const companyList = computed(() => {
  return store.state["customercompany"].companies;
});
const loadedSlugCompany = computed(() => {
  return store.state["customercompany"].loadedSlugCompany;
});
const loadedComment = computed(() => {
  return store.state["customercomment"].loadedComment;
});

watch(loadedComment, (newVal) => {
  if (newVal) {
    pagination.model = companyCommentList.value.slice(0, pagination.perPage);
  }
});
watch(loadedSlugCompany, (newVal) => {
  if (newVal) {
    fetchCompanyComments();
    onCalculatedWidth();
  }
});

const onHandlePaginate = () => {
  pagination.model = sliceObjectForPagination(
    pagination.currentPage,
    pagination.perPage,
    companyCommentList
  );
};
// const onCalculatedWidth = () => {
//   console.log("calculated >>");
//   let starWidth = `${(averageRating.value / 5) * 100}%`;
//   console.log(starWidth);

//   // let starContent = "\\f005 ".repeat(Math.round(averageRating.value));
//   console.log(document.querySelectorAll(".stars-inner"));
//   document.querySelector(".stars-inner").style.width = starWidth;
//   document.querySelector(`.stars-outer .stars-inner`).innerHTML = averageRating;
// };

const onCalculatedWidth = () => {
  let starWidth = `${(averageRating.value / 5) * 100}%`;

  const container = document.querySelector(".company-comment-inside");

  const starInners = container.querySelectorAll(".stars-inner");
  const starOuters = container.querySelectorAll(".stars-outer .stars-inner");

  starInners.forEach((inner, index) => {
    inner.style.width = starWidth;
  });
  starOuters.forEach((outer, index) => {
    outer.innerHTML = averageRating.value;
  });
};
const fetchCompanyComments = async () => {
  // const { guid } = router.currentRoute.value.params;

  const model = {
    companyGuid: store.state["customercompany"].company.companyGuid,
    // companyGuid: guid,
  };
  // if (guid !== "") {
  await store.dispatch("companycomment/getCompanyComments", model);
  // }

  onHandlePaginate();
};
</script>

<template>
  <div class="row company-card-inside company-comment-inside gx-0">
    <div class="col-12 d-flex justify-content-between">
      <div class="company-header pb-0">
        <span>Müşteri Yorumları ({{ totalItems }})</span>
      </div>

      <div class="d-flex company-comment-text pb-0">
        <span>Ortalama ({{ averageRating }})</span>
        <div class="rating mapgridrating1">
          <!-- <i
            v-for="index in Math.round(3.8)"
            class="fas"
            :class="
              index < Math.round(3.8)
                ? 'fa-star filled'
                : 4.8 - index > 0 && 4.8 - index < 1
                ? 'fa-star percent'
                : ''
            "
            aria-hidden="true"
          ></i> -->
          <i style="font-style: normal;">
            <div class="stars-outer">
              <div class="stars-inner" style="width: 100%;"></div>
            </div>
          </i>
        </div>
      </div>
    </div>
    <hr class="color-hr mt-2 mb-4" style="height: 2px;" />

    <div v-if="totalItems == 0">
      <span class="color-858585">Firma müşteri yorumu bulunmamaktadır.</span>
    </div>
    <div
      v-for="(comment, index) in pagination.model"
      :key="index"
      class="company-comment-area"
    >
      <div class="d-flex justify-content-between pe-5">
        <div class="d-flex align-items-center">
          <div class="company-comment-img me-4">
            <img
              src="../../../../assets/img/ixirlocal/comment-avatar.svg"
              alt
              width="63"
              height="63"
            />
          </div>
          <div>
            <span class="comment-name">{{ comment.customer }}</span>
            <div class="rating mapgridrating1">
              <i
                class="fas fa-star"
                v-for="index in 5"
                :class="{ filled: index <= comment.value }"
              ></i>
            </div>
          </div>
        </div>
        <div>
          <span class="comment-text">
            {{ customDateFormatTR(comment.commentDate, "DD[ ]MMMM[ ]YYYY") }}
          </span>
        </div>
      </div>
      <div class="comment-area mt-">
        <span class="comment-text">
          {{ comment.comment }}
        </span>
      </div>
      <hr class="color-hr mt-4 mb-4" style="height: 2px;" />
    </div>
  </div>
  <div v-if="pagination.perPage < totalItems" class="paginate-center">
    <vue-awesome-paginate
      :total-items="totalItems"
      v-model="pagination.currentPage"
      :items-per-page="pagination.perPage"
      :max-pages-shown="5"
      paginate-buttons-class="btn"
      active-page-class="btn-active"
      back-button-class="back-btn"
      next-button-class="next-btn"
      :on-click="onHandlePaginate"
      :hide-prev-next-when-ends="false"
      :show-breakpoint-buttons="false"
    />
  </div>
</template>

<style scoped>
.stars-outer {
  position: relative;
  display: inline-block;
}
.stars-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0%;
}
.stars-outer::before {
  content: "\f005 \f005 \f005 \f005 \f005";
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  font-weight: 900;
  color: #dedfe0;
}
.stars-inner::before {
  content: "\f005 \f005 \f005 \f005 \f005";
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  font-weight: 900;
  color: #fac330;
}
.rating {
  width: auto;
}
</style>
