import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getCustomerSessions,
  sessionDelete,
  sessionSave,
} from "../../api/customersession.service";

const defaultState = () => {
  return {};
};

export const state = reactive({
  loadedSessions: false,
  sessionSaved: false,
  session: [],
  customerSessions: [],
});

export const actions = {
  async getCustomerSessions({ commit }, data) {
    const responseObj = await getCustomerSessions(data);

    commit("customerSessions", responseObj.data);
    commit("loadedSessions", responseObj.data.length >= 0);

    return responseObj;
  },

  async sessionSave({ commit }, data) {
    const responseObj = await sessionSave(data);

    const { success, message } = responseObj;

    if (success) {
      commit("sessionSaved", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },

  async sessionDelete({ commit }, data) {
    const responseObj = await sessionDelete(data);

    const { response } = responseObj;

    const { success, message } = response;

    if (success) {
      commit("sessionSaved", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },
};

export const mutations = {
  loadedSessions(state, data) {
    state.loadedSessions = data;
  },
  sessionSaved(state, data) {
    state.sessionSaved = data;
  },
  session(state, data) {
    state.session = data;
  },
  customerSessions(state, data) {
    state.customerSessions = data;
  },
  resetCompanyProduct(state) {},
};
