<template>
  <!-- /our app -->
  <section class="app-set" style="padding-bottom: 60px;">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 col-12">
          <div class="col-md-12">
            <div class="heading aos mb-0" data-aos="fade-up">
              <h2>İşletme sahibi misiniz?</h2>
              <span class="ixir-text-color-1" style="font-family: 'Poppins';"
                >En kapsamlı salon yönetimi yazılımı olan ixirlife, kullanımı
                kolay arayüzü ve gelişmiş özellikleri sayesinde, randevularınızı
                eksiksiz yönetmenizi sağlar ve salonunuzun tüm ihtiyaçlarına
                çözümler sunar. Mobil uygulamamız sayesinde tüm bu özellikler,
                dilediğiniz yerde, parmaklarınızın ucunda!
              </span>
            </div>
            <div class="search-cat d-inline-flex align-items-center ml-0 mt-0">
              <i class="fas fa-circle"></i>
              <span class="ms-2 ixir-text-color">Detaylı bilgi için</span>
              <a href="https://www.ixirlife.com/business/" target="_blank">
                <button class="btn bg-dark-purple">
                  Hemen Tıklayın
                </button>
              </a>
            </div>
          </div>
          <!-- <div class="downlaod-set aos" data-aos="fade-up">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <img
                    src="../../../../assets/img/ixirlocal/android-play.svg"
                    alt="img"
                  />
                  <img src="../../../../assets/img/gp.png" alt="img" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <img
                    src="../../../../assets/img/ixirlocal/ios-play.svg"
                    alt="img"
                  />
                  <img src="../../../../assets/img/ap.png" alt="img" />
                </a>
              </li>
            </ul>
          </div> -->
        </div>
        <div class="col-lg-7 col-12">
          <div class="appimg-set aos" data-aos="fade-up">
            <!-- src="../../../../assets/img/home/web-and-mobile.svg" -->
            <img
              src="https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/laptops.webp"
              width="851"
              height="516"
              alt="img"
            />
            <!-- <img src="../../../../assets/img/app.png" alt="img" /> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /our app -->
</template>

<style scoped>
.bg-dark-purple {
  border-radius: 20px;
}
</style>
