import { baseUrl, tokenString } from "./base.js";
import { serialize } from "../helpers/formatters.js";
import axiosHttp from "./axios.js";

const getCustomerSessions = async (data) => {
  const qs = serialize(data);

  const endPoint = `${baseUrl}/randevu/customer-plus-session-web?${qs}`;

  return await axiosHttp
    .get(endPoint, {
      // withCredentials: true,
      headers: { Authorization: tokenString() },
    })
    .catch((error) => {
      //
    });
};

const sessionSave = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-sessions`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const sessionDelete = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-sessions`;

  const method = "DELETE";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

export { getCustomerSessions, sessionSave, sessionDelete };
