import { baseUrl, tokenString } from "./base.js";
import { serialize } from "../helpers/formatters.js";

const getCustomerComments = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-comments`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: tokenString(),
    },
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getCustomerCommentsV2 = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-comments-v2`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: tokenString(),
    },
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const getUnloggedCustomerCommentInfos = async (data) => {
  const qs = serialize(data);
  const endPoint = `${baseUrl}/genel/customer-appointment?${qs}`;

  const requestOptions = {
    method: "GET",
    headers: {
      // Authorization: tokenString(),
    },
  };
  return await (await fetch(endPoint, requestOptions)).json();
};

const makeCommentWithGuid = async (data) => {
  const endPoint = `${baseUrl}/genel/customer-comment`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      // Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const commentSave = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-comments`;

  const method = "POST";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

const commentDelete = async (data) => {
  const endPoint = `${baseUrl}/randevu/customer-comments`;

  const method = "DELETE";

  const body = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key] == null) data[key] = "";

    body.append(`${key}`, data[key]);
  });

  const requestOptions = {
    method,
    body,
    headers: {
      Authorization: tokenString(),
    },
  };

  return await (await fetch(endPoint, requestOptions)).json();
};

export {
  getCustomerComments,
  getCustomerCommentsV2,
  getUnloggedCustomerCommentInfos,
  makeCommentWithGuid,
  commentDelete,
  commentSave,
};
