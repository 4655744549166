import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getCustomerAppointments,
  deleteCustomerAppointment,
} from "../../api/customerappointment.service";

const defaultState = () => {
  return {};
};

/**
 * @param loadedWCA => loadedWaitingCustomerAppointments
 * @param loadedACA => loadedApprovedCustomerAppointments
 * @param loadedRCA => loadedRejectedCustomerAppointments
 */
export const state = reactive({
  loadedWCA: false,
  loadedACA: false,
  loadedRCA: false,
  appointmentDetailModal: false,
  approvedRemoveModal: false,
  rejectedRemoveModal: false,
  customerAppointmentSaved: false,
  appointment: [],
  waitingAppointments: [],
  approvedAppointments: [],
  rejectedAppointments: [],
});

export const actions = {
  async getCustomerAppointments({ commit }, data) {
    const responseObj = await getCustomerAppointments(data);

    commit("waitingAppointments", responseObj.waiting);
    commit("approvedAppointments", responseObj.approved);
    commit("rejectedAppointments", responseObj.rejected);

    commit("loadedWCA", Object.keys(responseObj).length >= 0);
    commit("loadedACA", Object.keys(responseObj).length >= 0);
    commit("loadedRCA", Object.keys(responseObj).length >= 0);

    return responseObj;
  },

  async deleteCustomerAppointment({ commit }, data) {
    const responseObj = await deleteCustomerAppointment(data);

    const { response } = responseObj;

    const { success, message } = response;

    if (success) {
      commit("customerAppointmentSaved", true);
    }

    Swal.fire({
      title: success ? "BİLGİ" : "UYARI",
      text: message,
      icon: success ? "success" : "warning",
      timer: success ? 2000 : null,
    });

    return responseObj;
  },
};

export const mutations = {
  loadedWCA(state, data) {
    state.loadedWCA = data;
  },
  loadedACA(state, data) {
    state.loadedACA = data;
  },
  loadedRCA(state, data) {
    state.loadedRCA = data;
  },
  customerAppointmentSaved(state, data) {
    state.customerAppointmentSaved = data;
  },
  appointmentDetailModal(state, data) {
    state.appointmentDetailModal = data;
  },
  approvedRemoveModal(state, data) {
    state.approvedRemoveModal = data;
  },
  rejectedRemoveModal(state, data) {
    state.rejectedRemoveModal = data;
  },
  appointment(state, data) {
    state.appointment = data;
  },
  waitingAppointments(state, data) {
    state.waitingAppointments = data;
  },
  approvedAppointments(state, data) {
    state.approvedAppointments = data;
  },
  rejectedAppointments(state, data) {
    state.rejectedAppointments = data;
  },
  resetCompanyProduct(state) {},
};
