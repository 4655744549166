<script setup>
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref } from "vue";
import Multiselect from "vue-multiselect";

const store = useStore();

// const selectedModel = ref([]);
const searchCustomerCompanys = async () => {
  store.commit("customercompany/loaded", true);
};

const data = reactive({
  searchText: "",
  location: null,
  filteredCountiesOfCities: [],
  selectedLocation: null,
  selectedModel: [],
});

const countiesOfCities = computed(() => {
  return store.state["companycity"].countiesOfCities;
});

onMounted(() => {
  // searchCustomerCompanys();
});

const selectCountyOfCities = (query) => {
  data.selectedModel = query;
  // this.callSearchModel.personeller = [];
  // data.forEach((personnel) => {
  //   this.callSearchModel.personeller.push(personnel.id);
  // });
};

const filterCountiesOfCities = (query) => {
  data.filteredCountiesOfCities = countiesOfCities.value?.filter((item) => {
    return item.title
      .toLocaleUpperCase("tr-TR")
      .includes(query.toLocaleUpperCase("tr-TR"));
  });
};
</script>

<template>
  <!-- Hero Section -->
  <section class="hero-section">
    <div class="layer">
      <div class="home-banner"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="section-search aos" data-aos="fade-up">
              <h3>Dilediğiniz yerden kolayca <span>randevu alın</span></h3>
              <div class="sub-info">
                <p class="mt-4">
                  Zaman sınırı olmadan, 7/24 randevularınızı yönetin, ister
                  evden ister işten profesyonel hizmet alın
                </p>
              </div>

              <div class="search-box">
                <form>
                  <div class="search-input line d-flex align-items-center">
                    <!-- <i class="fas fa-tv bficon"></i> -->
                    <span class="material-icons-round color-black bficon">
                      search
                    </span>
                    <div
                      class="form-group mb-0 w-100  d-flex align-items-center"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.searchText"
                        placeholder="Hizmet, Salon Türü, Salon Adı..."
                      />
                    </div>
                  </div>
                  <!-- <div class="search-input"> -->
                  <!-- <i class="fas fa-location-arrow bficon"></i> -->
                  <!-- <span class="material-icons-round color-black bficon">
                      place
                    </span> -->
                  <!-- <div class="form-group mb-0"> -->
                  <!-- <input
                        type="text"
                        class="form-control"
                        v-model="data.location"
                        placeholder="Konum, Şehir"
                        @input="filterCountiesOfCities"
                      /> -->

                  <!-- <a
                        class="current-loc-icon current_location"
                        href="javascript:void(0);"
                      >
                        <i class="fas fa-crosshairs"></i>
                      </a> -->
                  <!-- </div> -->
                  <!-- </div> -->
                  <div class="row">
                    <div
                      class="col-2 d-flex align-items-center pe-0"
                      style="width: 50px;height: 55px;"
                    >
                      <span
                        class="material-icons-outlined color-black bficon"
                        style="position: absolute;"
                      >
                        place
                      </span>
                    </div>
                    <div
                      class="col-10 form-group mb-0 d-flex  align-items-center ps- pe-0"
                    >
                      <div class="d-flex align-items-center w-100">
                        <fieldset
                          class="form-control ps-0 pe-0 border-0"
                          style="padding-top: 2px;"
                        >
                          <multiselect
                            v-model="data.selectedModel"
                            :options="data.filteredCountiesOfCities"
                            role="group"
                            style="position: absolute; width: 305px;"
                            :class="{ invalid: true }"
                            track-by="id"
                            label="title"
                            selectLabel="Ekleyin"
                            placeholder="İl, İlçe"
                            selectedLabel="Eklendi"
                            deselectLabel="Çıkarın"
                            :showLabels="true"
                            :allow-empty="true"
                            :multiple="false"
                            :close-on-select="true"
                            :clear-on-select="false"
                            @search-change="filterCountiesOfCities"
                            @select="selectCountyOfCities"
                          >
                          </multiselect>
                        </fieldset>
                      </div>
                    </div>
                  </div>

                  <router-link
                    id="search-btn"
                    :to="{
                      name: 'search',
                      params: {
                        search: data.searchText,
                        id: data.selectedModel.id || 0,
                        cityID: data.selectedModel.cityID || 0,
                      },
                      // query: data.selectedModel,
                    }"
                  >
                    <div class="search-btn">
                      <button class="btn search_service" type="submit">
                        Ara
                      </button>
                    </div>
                  </router-link>
                </form>
              </div>
              <div class="search-cat">
                <!-- <i class="fas fa-circle"></i>
                <span class="ms-1">Popular Searches</span>
                <router-link to="/search">Electrical Works</router-link>
                <router-link to="/search">Cleaning</router-link>
                <router-link to="/search">AC Repair</router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Hero Section -->
</template>

<style>
.multiselect__content span.multiselect__option {
  content: "TEST";
}
/* .multiselect--active.multiselect--above { */
.multiselect.invalid {
  padding-top: 2px;
}
.multiselect--active.multiselect.invalid {
  position: absolute;
  background: #fff;
  width: max-content;
  padding-top: 5px;
  /* overflow: auto; */
  /* border: 1px solid #000 !important; */
  border: none !important;
  border-radius: 10px;
  max-height: 210px !important;
}
.multiselect__single {
  color: #000;
  font-size: 18px;
}

.multiselect__placeholder {
  display: inline-block !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}
.multiselect__placeholder {
  margin-left: 0px !important;
  margin-top: 0px;
  display: flex !important;
  align-items: center !important;
}
.multiselect__placeholder {
  margin-left: 10px;
  margin-top: 2px;
  font-size: 18px;
  color: #000;
}
/* // error class on multiselect */
/* .multiselect.invalid .multiselect__tags {
  border: 1px solid #f86c6b !important;
} */

/* // override default multiselect styles */
/* .multiselect__option--highlight {
  background: #428bca !important;
} */
/* // override default multiselect styles */
.multiselect__option--highlight {
  background: #784ba0 !important;
  color: #fff;
  font-weight: 500;
}

/* .multiselect__option--highlight:after {
  background: #428bca !important;
} */
.multiselect__option--highlight:after {
  background: #784ba0 !important;
  /* background: #428bca !important; */
}
.multiselect__tags input:focus-visible {
  outline: none !important;
}
.multiselect__tags input {
  /* width: 300px !important; */
  border: none !important;
}
/* .multiselect__tags {
  padding: 5px !important;
  min-height: 10px;
} */
.multiselect__tags {
  padding: 5px !important;
  min-height: 10px;
  display: flex !important;
  align-items: center !important;
}
.multiselect__tag {
  background: #f0f0f0 !important;
  border: 1px solid rgba(60, 60, 60, 0.26) !important;
  color: black !important;
  margin-bottom: 0px !important;
  margin-right: 5px !important;
}

.multiselect__tag-icon:after {
  color: rgba(60, 60, 60, 0.5) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #f0f0f0 !important;
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: red !important;
}
.multiselect__content-wrapper {
  overflow: auto;
  max-height: 170px !important;
}
.multiselect__content-wrapper ul {
  width: 100%;
}
.multiselect__content-wrapper ul li span {
  display: flex;
  width: 100% !important;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 3px;
  padding-top: 3px;
}
.multiselect__content-wrapper.multiselect.invalid .multiselect__tags {
  border: 1px solid #f86c6b !important;
}

.multiselect__option.multiselect__option--selected {
  background-color: #572a86 !important;
  color: #fff !important;
}

.multiselect__option.multiselect__option--selected::after {
  color: #fff !important;
}

.multiselect__option.multiselect__option--highlight.multiselect__option--selected {
  background-color: #e23a3a !important;
}

.multiselect__option.multiselect__option--highlight.multiselect__option--selected:after {
  background-color: #f86c6b !important;
}
</style>
