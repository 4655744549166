<script setup>
import { useStore } from "vuex";
import { computed, defineAsyncComponent, onMounted, reactive } from "vue";
import { user } from "../../api/auth.service";
import { useI18n } from "vue-i18n";
// import UserLoginModal from "./customer/modals/user-login-modal.vue";
// import userRegisterModal from "./customer/modals/user-register-modal.vue";

const UserLoginModal = defineAsyncComponent(() =>
  import("./customer/modals/user-login-modal.vue")
);
const userRegisterModal = defineAsyncComponent(() =>
  import("./customer/modals/user-register-modal.vue")
);

const { t } = useI18n();
const store = useStore();
const slugify = require("slugify");

const bannerWeb =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/ixirlife_banner_web.png";
const bannerMobile =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/ixirlife_banner_mobile.png";

const headerData = reactive({
  showOnlyMobile: false,
  isDev: process.env.NODE_ENV,
  laserEpilation: {
    laserEpilation: "Lazer Epilasyon",
    wholeBody: "Tüm Vücut",
    armpit: "Koltuk Altı",
    leg: "Bacak",
    arm: "Kol",
    back: "Sırt",
  },
  hair: {
    hair: "Saç",
    blowDry: "Fön",
    hairCut: "Saç Kesim",
    hairColoring: "Saç Boyama",
    hairCare: "Saç Bakımı",
  },
  makeUp: {
    makeUp: "Makyaj",
    professional: "Profesyonel Makyaj",
    bridalMakeUp: "Gelin Makyajı",
    silkEyelash: "İpek Kirpik",
    lashlift: "Kirpik Lifting",
  },
  nail: {
    nail: "Tırnak",
    manicure: "Manikür",
    pedicure: "Pedikür",
    permanentNailPolish: "Kalıcı Oje",
    prostheticNail: "Protez Tırnak",
    nailArt: "Nail Art",
    gelNail: "Jel Tırnak",
  },
  skinCare: {
    skinCare: "Cilt Bakımı",
    classic: "Klasik Cilt Bakımı",
    professional: "Profesyonel Cilt Bakımı",
    faceWaxing: "Yüz Ağda",
    aboveLip: "Dudak Üstü",
    eyeBrowRemoval: "Kaş Alma",
  },
});
onMounted(() => {
  headerData.showOnlyMobile = window.screen.width < 1366;
  checkIxirlifeBanner();
});

const userCookie = computed(() => {
  return user();
});
const userLoginModal = computed(() => {
  return store.state["auth"].userLoginModal;
});
const ixirlifeBannerInfos = computed(() => {
  return store.state["cdn"].ixirlifeBannerInfos;
});
const openLoginModal = () => {
  store.commit("auth/userLoginModal", true);
};
const openRegisterModal = () => {
  store.commit("auth/userRegisterModal", true);
};
const checkIxirlifeBanner = async () => {
  await store.dispatch("cdn/checkIxirlifeBannerStatus", { type: 1 });
};
</script>

<script>
import AOS from "aos";
export default {
  mounted() {
    if ($(".main-wrapper .aos").length > 0) {
      AOS.init({
        duration: 1200,
        once: true,
      });
    }
    if ($(window).width() <= 991) {
      var Sidemenu = function() {
        this.$menuItem = $(".main-nav a");
      };

      function init() {
        var $this = Sidemenu;
        $(".main-nav a").on("click", function(e) {
          if (
            $(this)
              .parent()
              .hasClass("has-submenu")
          ) {
            e.preventDefault();
          }
          if (!$(this).hasClass("submenu")) {
            $("ul", $(this).parents("ul:first")).slideUp(350);
            $("a", $(this).parents("ul:first")).removeClass("submenu");
            $(this)
              .next("ul")
              .slideDown(350);
            $(this).addClass("submenu");
          } else if ($(this).hasClass("submenu")) {
            $(this).removeClass("submenu");
            $(this)
              .next("ul")
              .slideUp(350);
          }
        });
      }

      // Sidebar Initiate
      init();
    }
    // Mobile menu sidebar overlay
    $(".header-fixed").append('<div class="sidebar-overlay"></div>');
    $(document).on("click", "#mobile_btn", function() {
      $("main-wrapper").toggleClass("slide-nav");
      $(".sidebar-overlay").toggleClass("opened");
      $("html").addClass("menu-opened");
      return false;
    });
    $(document).on("click", ".sidebar-overlay", function() {
      $("html").removeClass("menu-opened");
      $(this).removeClass("opened");
      $("main-wrapper").removeClass("slide-nav");
    });

    $(document).on("click", "#menu_close", function() {
      $("html").removeClass("menu-opened");
      $(".sidebar-overlay").removeClass("opened");
      $("main-wrapper").removeClass("slide-nav");
    });
    $(".fav-btn .fav-icon").on("click", function() {
      $(this).toggleClass("favourite");
    });
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    homeMenu() {
      return (
        this.$route.name == "/" ||
        this.$route.name == "index" ||
        this.$route.name == "index-two" ||
        this.$route.name == "index-three" ||
        this.$route.name == "index-four" ||
        this.$route.name == "index-five"
      );
    },
    providerMenu() {
      return (
        this.$route.name == "provider-dashboard" ||
        this.$route.name == "my-services-inactive" ||
        this.$route.name == "my-services" ||
        this.$route.name == "provider-bookings" ||
        this.$route.name == "provider-settings" ||
        this.$route.name == "provider-wallet" ||
        this.$route.name == "provider-subscription" ||
        this.$route.name == "provider-availability" ||
        this.$route.name == "provider-reviews" ||
        this.$route.name == "provider-payment"
      );
    },
    pageMenu() {
      return (
        this.$route.name == "search" ||
        this.$route.name == "service-details" ||
        this.$route.name == "add-service" ||
        this.$route.name == "edit-service" ||
        this.$route.name == "chat" ||
        this.$route.name == "notifications" ||
        this.$route.name == "about-us" ||
        this.$route.name == "contact-us" ||
        this.$route.name == "faq" ||
        this.$route.name == "term-condition" ||
        this.$route.name == "privacy-policy1"
      );
    },
    customerMenu() {
      return (
        this.$route.name == "user-dashboard" ||
        this.$route.name == "favourites" ||
        this.$route.name == "user-bookings" ||
        this.$route.name == "profile-settings" ||
        this.$route.name == "user-wallet" ||
        this.$route.name == "user-reviews" ||
        this.$route.name == "user-payment"
      );
    },
  },
};
</script>

<template>
  <div>
    <!-- Header -->
    <header class="header">
      <div class="container px-0">
        <div class="row">
          <nav class="navbar navbar-expand-lg header-nav">
            <div class="navbar-header">
              <a id="mobile_btn" href="javascript:void(0);" class="ps-4">
                <span
                  class="material-icons-round"
                  style="font-size: 40px; color: #572a86;"
                >
                  menu
                </span>
              </a>
              <!-- <a id="mobile_btn" href="javascript:void(0);">
                <span class="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </a> -->
              <router-link :to="{ name: 'index' }" class="navbar-brand logo ">
                <img
                  src="../../assets/img/ixirlocal/ixirlife-purple-text.svg"
                  class="img-fluid"
                  alt="Logo"
                />
                <!-- <img src="../../assets/img/logo.png" class="img-fluid" alt="Logo" /> -->
              </router-link>
              <!-- <router-link to="/" class="navbar-brand logo-small">
                <img
                  src="../../assets/img/logo-icon.png"
                  class="img-fluid"
                  alt="Logo"
                />
              </router-link> -->
            </div>
            <div class="main-menu-wrapper d-flex">
              <div :class="{ 'col-8 bgc-fff': headerData.showOnlyMobile }">
                <div class="menu-header">
                  <router-link to="/" class="menu-logo">
                    <img
                      src="../../assets/img/ixirlocal/ixirlife-purple-text.svg"
                      class="img-fluid"
                      alt="Logo"
                    />
                    <!-- <img
                      src="../../assets/img/logo.png"
                      class="img-fluid"
                      alt="Logo"
                    /> -->
                  </router-link>
                  <a
                    id="menu_close"
                    class="menu-close"
                    href="javascript:void(0);"
                  >
                    <i class="fas fa-times"></i
                  ></a>
                </div>
                <ul class="main-nav">
                  <!-- <li class="has-submenu" :class=" homeMenu ? 'active' : 'notactive'">
                  <a href="javascript:void(0);">Home <i class="fas fa-chevron-down"></i></a>
                  <ul class="submenu">
                    <li :class="currentPath == 'index' ? 'active' : 'notactive'"><router-link to="/">Home</router-link></li>
                    <li :class="currentPath == 'index-two' ? 'active' : 'notactive'"><router-link to="/index-two">Home 2</router-link></li>
                    <li :class="currentPath == 'index-three' ? 'active' : 'notactive'"><router-link to="/index-three">Home 3</router-link></li>
                    <li :class="currentPath == 'index-four' ? 'active' : 'notactive'"><router-link to="/index-four">Home 4</router-link></li>
                    <li :class="currentPath == 'index-five' ? 'active' : 'notactive'"><router-link to="/index-five">Home 5</router-link></li>
                  </ul>
                </li> -->
                  <li v-bind:class="{ active: currentPath == 'categories' }">
                    <router-link to="/categories">{{
                      t("navbar.categories")
                    }}</router-link>
                  </li>
                  <li class="has-submenu">
                    <a href="javascript:void(0);"
                      >{{ headerData.laserEpilation.laserEpilation
                      }}<i class="fas fa-chevron-down"></i
                    ></a>
                    <ul class="submenu">
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(
                                headerData.laserEpilation.wholeBody,
                                { lower: true }
                              ),
                            },
                          }"
                          >{{
                            headerData.laserEpilation.wholeBody
                          }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(
                                headerData.laserEpilation.armpit,
                                {
                                  lower: true,
                                }
                              ),
                            },
                          }"
                        >
                          {{ headerData.laserEpilation.armpit }}
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.laserEpilation.leg, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.laserEpilation.leg }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.laserEpilation.arm, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.laserEpilation.arm }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.laserEpilation.back, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.laserEpilation.back }}</router-link
                        >
                      </li>
                    </ul>
                  </li>

                  <li class="has-submenu">
                    <a href="javascript:void(0);"
                      >{{ headerData.hair.hair }}
                      <i class="fas fa-chevron-down"></i
                    ></a>
                    <ul class="submenu">
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.hair.blowDry, {
                                lower: true,
                              }),
                            },
                          }"
                          >{{ headerData.hair.blowDry }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.hair.hairCut, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.hair.hairCut }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.hair.hairColoring, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.hair.hairColoring }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.hair.hairCare, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.hair.hairCare }}</router-link
                        >
                      </li>
                    </ul>
                  </li>

                  <li class="has-submenu">
                    <a href="javascript:void(0);"
                      >{{ headerData.makeUp.makeUp }}
                      <i class="fas fa-chevron-down"></i
                    ></a>
                    <ul class="submenu">
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.makeUp.professional, {
                                lower: true,
                              }),
                            },
                          }"
                          >{{ headerData.makeUp.professional }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.makeUp.bridalMakeUp, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.makeUp.bridalMakeUp }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.makeUp.silkEyelash, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.makeUp.silkEyelash }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.makeUp.lashlift, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.makeUp.lashlift }}</router-link
                        >
                      </li>
                    </ul>
                  </li>

                  <li class="has-submenu">
                    <a href="javascript:void(0);"
                      >{{ headerData.nail.nail }}
                      <i class="fas fa-chevron-down"></i
                    ></a>
                    <ul class="submenu">
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.nail.manicure, {
                                lower: true,
                              }),
                            },
                          }"
                          >{{ headerData.nail.manicure }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.nail.pedicure, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.nail.pedicure }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(
                                headerData.nail.permanentNailPolish,
                                { lower: true }
                              ),
                            },
                          }"
                        >
                          {{ headerData.nail.permanentNailPolish }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.nail.prostheticNail, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.nail.prostheticNail }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.nail.nailArt, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.nail.nailArt }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.nail.gelNail, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.nail.gelNail }}</router-link
                        >
                      </li>
                    </ul>
                  </li>

                  <li class="has-submenu">
                    <a href="javascript:void(0);"
                      >{{ headerData.skinCare.skinCare }}
                      <i class="fas fa-chevron-down"></i
                    ></a>
                    <ul class="submenu">
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.skinCare.classic, {
                                lower: true,
                              }),
                            },
                          }"
                          >{{ headerData.skinCare.classic }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(
                                headerData.skinCare.professional,
                                {
                                  lower: true,
                                }
                              ),
                            },
                          }"
                        >
                          {{ headerData.skinCare.professional }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.skinCare.faceWaxing, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.skinCare.faceWaxing }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(headerData.skinCare.aboveLip, {
                                lower: true,
                              }),
                            },
                          }"
                        >
                          {{ headerData.skinCare.aboveLip }}</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'search-content',
                            params: {
                              search: slugify(
                                headerData.skinCare.eyeBrowRemoval,
                                { lower: true }
                              ),
                            },
                          }"
                        >
                          {{ headerData.skinCare.eyeBrowRemoval }}</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div
                v-if="headerData.showOnlyMobile"
                class="col-4 sidebar-outline menu-close"
                id="menu_close"
              ></div>
            </div>

            <!-- <div> -->
            <a
              href="http://ixirlife.com/business"
              target="blank"
              v-if="!userCookie"
            >
              <button class="btn nav-plus-btn">
                {{
                  headerData.showOnlyMobile
                    ? t("text.businesses")
                    : t("text.forBusinesses")
                }}
              </button>
            </a>
            <!-- </div> -->
            <div v-if="userCookie">
              <ul class="nav header-navbar-rht dashboard-navbar">
                <!-- User Menu -->
                <li class="nav-item dropdown has-arrow logged-item">
                  <a
                    href="javascript:void(0);"
                    class="dropdown-toggle nav-link pe-0"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span class="user-img">
                      <img
                        class="rounded-circle"
                        :src="userCookie?.profilePicture ?? defaultNoImgWebp"
                        alt=""
                      />
                    </span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <div class="user-header">
                      <div class="avatar avatar-sm">
                        <img
                          class="avatar-img rounded-circle"
                          :src="userCookie?.profilePicture ?? defaultNoImgWebp"
                          alt="profile image"
                        />
                      </div>
                      <div class="user-text">
                        <h6>{{ userCookie.name }} {{ userCookie.surname }}</h6>
                        <!-- <p class="text-muted mb-0">User</p> -->
                      </div>
                    </div>

                    <router-link class="dropdown-item" to="/user-appointments">
                      <span class="material-icons-round me-2">
                        face_unlock
                      </span>
                      <span
                        style="
                      color: #2A3042;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;"
                      >
                        Profilim
                      </span>
                    </router-link>

                    <router-link class="dropdown-item" to="/logout">
                      <span class="material-icons-round me-2">
                        logout
                      </span>
                      <span
                        style="
                      color: #2A3042;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;"
                      >
                        Çıkış Yap
                      </span>
                    </router-link>
                  </div>
                </li>
                <!-- /User Menu -->
              </ul>
            </div>
            <div class="dropdown me-2" v-else>
              <button
                v-if="!headerData.showOnlyMobile"
                class="btn btn-secondary dropdown-toggle"
                style="display: flex; "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Giriş Yap
              </button>

              <button
                v-else
                class="btn btn-secondary dropdown-toggle"
                style="display: flex; padding: 6px 10px 6px 4px;"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="material-icons-round" style="font-size: 28px;">
                  login
                </span>
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    @click="openLoginModal"
                  >
                    <span class="material-icons-round">
                      person_outline
                    </span>
                    Müşteri Girişi
                  </a>
                </li>
                <hr class="mt-2 mb-2" />
                <li>
                  <a
                    class="dropdown-item"
                    :href="
                      headerData.isDev === 'development'
                        ? 'http://localhost:8081/bireysel-yonetim/'
                        : 'https://ixirlife.com/bireysel-yonetim/#/'
                    "
                    target="_blank"
                  >
                    <span class="material-icons-round"> apartment </span>
                    Firma Girişi
                  </a>
                </li>
              </ul>
            </div>
            <!-- <ul class="nav header-navbar-rht">
              <li class="nav-item">
                <a
                  class="nav-link header-login"
                  href="javascript:void(0);"
                  @click="openLoginModal"
                  ><span lang="tr">Giriş Yap</span></a
                >
              </li>
            </ul> -->
          </nav>
        </div>
      </div>
    </header>
    <!-- /Header -->
    <!-- Fonangels Banner-->
    <div v-if="ixirlifeBannerInfos?.id">
      <a :href="ixirlifeBannerInfos?.link" target="_blank">
        <img
          v-if="!headerData.showOnlyMobile"
          :src="ixirlifeBannerInfos?.bannerWeb"
          alt="fonangels"
          style="width: 100%; height: auto;"
        />
        <img
          v-if="headerData.showOnlyMobile"
          :src="ixirlifeBannerInfos?.bannerMobile"
          alt="fonangels"
          style="width: 100%; height: auto;"
        />
      </a>
    </div>

    <!-- User Register Modal -->
    <!-- <user-register-modal></user-register-modal> -->
    <Suspense>
      <template #default>
        <userRegisterModal />
        <!-- <component :is="UserRegisterModal" /> -->
      </template>
      <template #fallback>
        Loading...
      </template>
    </Suspense>
    <!-- /User Register Modal -->

    <!-- Login Modal -->
    <!-- <user-login-modal></user-login-modal> -->

    <Suspense>
      <template #default>
        <UserLoginModal />
        <!-- <component :is="UserLoginModal" /> -->
      </template>
      <template #fallback>
        Loading...
      </template>
    </Suspense>

    <!-- <div class="modal account-modal fade" id="login_modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header p-0 border-0">
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="login-header">
              <h3>Login <span>Truelysell</span></h3>
            </div>
            <form>
              <div class="form-group form-focus">
                <label class="focus-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="truelysell@example.com"
                />
              </div>
              <div class="form-group form-focus">
                <label class="focus-label">Password</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="********"
                />
              </div>
              <div class="text-end"></div>
              <router-link to="/">
                <div class="d-grid">
                  <button
                    class="btn btn-primary btn-block btn-lg login-btn"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </router-link>
              <div class="login-or">
                <span class="or-line"></span>
                <span class="span-or">or</span>
              </div>
              <div class="row form-row social-login">
                <div class="col-6 d-grid">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-facebook btn-block"
                    ><i class="fab fa-facebook-f me-1"></i> Login</a
                  >
                </div>
                <div class="col-6 d-grid">
                  <a href="javascript:void(0);" class="btn btn-google btn-block"
                    ><i class="fab fa-google me-1"></i> Login</a
                  >
                </div>
              </div>
              <div class="text-center dont-have">
                Don’t have an account?
                <a href="javascript:void(0);">Register</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /Login Modal -->
  </div>
</template>
