<template>

	<div class="main-wrapper">

	<layout-header></layout-header>
    
    <!-- Breadcrumb -->
	<breadcrumb3></breadcrumb3>
	<!-- /Breadcrumb -->
		
		<section class="contact-us">
			<div class="content">
				<div class="container">
					<div class="row">
						<div class="col-lg-8">
							<div class="contact-queries">
								<h4 class="mb-4">Drop your Queries</h4>
								<div class="row">
									<div class="form-group col-xl-6">
										<label class="me-sm-2">First Name</label>
										<input class="form-control" type="text" >
									</div>
									<div class="form-group col-xl-6">
										<label class="me-sm-2">Last Name</label>
										<input class="form-control" type="text" >
									</div>
									<div class="form-group col-xl-6">
										<label class="me-sm-2">Email</label>
										<input class="form-control" type="email" >
									</div>
									<div class="form-group col-xl-6">
										<label class="me-sm-2">Mobile Number</label>
										<input class="form-control" type="text" >
									</div>
									<div class="form-group col-xl-12">
										<label class="me-sm-2">Message</label>
										<textarea class="form-control" rows="5"></textarea>
									</div>
									<div class="col-xl-12 mb-4">
										<button class="btn btn-primary btn-lg ps-5 pe-5" type="submit">Update</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="contact-details">
								<div class="contact-info">
									<i class="fas fa-map-marker-alt"></i>
									<div class="contact-data">
										<h4>Address</h4>
										<p>367 Hillcrest Lane, Irvine, California, United States</p>
									</div>
								</div>
								<hr>
								<div class="contact-info">
									<i class="fas fa-phone-alt"></i>
									<div class="contact-data">
										<h4>Phone</h4>
										<p>+21 256 259 8796</p>
										<p>+21 895 158 6545</p>
									</div>
								</div>
								<hr>
								<div class="contact-info">
									<i class="fab fa-skype"></i>
									<div class="contact-data">
										<h4>Skype</h4>
										<p>truelysell</p>
									</div>
								</div>
								<hr>
								<div class="contact-info">
									<i class="far fa-envelope"></i>
									<div class="contact-data">
										<h4>Email</h4>
										<p>truelysell@example.com</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="map-grid">
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250646.68136328788!2d76.82714556974858!3d11.012014523817232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859af2f971cb5%3A0x2fc1c81e183ed282!2sCoimbatore%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1596472179383!5m2!1sen!2sin" allowfullscreen="" aria-hidden="false" tabindex="0" class="contact-map"></iframe>
		</div>
	
	<layout-footer></layout-footer>

	</div>

</template>