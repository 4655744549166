<script setup>
import Swal from "sweetalert2";
import { useStore } from "vuex";
import { computed } from "vue";
import { dateFormat } from "../../../../helpers/formatters";

const store = useStore();

const defaultNoLogo = require("../../../../assets/img/ixirlocal/no-logo.svg")
  .default;

const notificationList = computed(() => {
  return store.state["customernotification"].customerNotifications;
});
const notificationCount = computed(() => {
  return store.state["customernotification"].customerNotifications.length || 0;
});

const clearAllNotification = async () => {
  await Swal.fire({
    title: "UYARI",
    text: `Tüm bildirimleri silmek istediğinize emin misiniz ?`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Evet",
    cancelButtonText: "Hayır",
  }).then(async (response) => {
    if (response.value) {
      await store.dispatch("customernotification/readAllNotifications");
    }
  });
};
</script>

<template>
  <div>
    <li class="nav-item dropdown logged-item">
      <a
        href="javascript:void(0);"
        class="dropdown-toggle nav-link"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="fas fa-bell"></i>
        <span class="badge badge-pill alignment-content">
          {{ notificationCount }}
        </span>
      </a>
      <div class="dropdown-menu notify-blk dropdown-menu-end notifications">
        <div class="topnav-dropdown-header">
          <span class="notification-title">Bildirimler</span>
          <a
            href="javascript:void(0)"
            class="clear-noti"
            @click="clearAllNotification"
            >Tümünü temizle
          </a>
        </div>
        <div class="noti-content">
          <ul class="notification-list">
            <li
              class="notification-message"
              v-for="notification in notificationList"
              :key="`un-${notification.id}`"
            >
              <router-link to="/user-notifications">
                <div class="media d-flex">
                  <span class="avatar avatar-sm flex-shrink-0">
                    <img
                      class="avatar-img rounded-circle"
                      alt="User Image"
                      :src="notification?.companyLogo ?? defaultNoLogo"
                    />
                  </span>
                  <div class="media-body flex-grow-1">
                    <p class="noti-details">
                      <span class="noti-title">{{ notification.title }}</span>
                    </p>
                    <p class="noti-time">
                      <span class="notification-time">
                        <!-- {{ dateFormat(notification.date, "DD[ ]MMMM[ ]YYYY") }} -->
                        {{ notification.note }}
                      </span>
                    </p>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="topnav-dropdown-footer">
          <router-link to="/user-notifications">Tümünü Görüntüle</router-link>
        </div>
      </div>
    </li>
  </div>
</template>
