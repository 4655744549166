<script setup>
import { useStore } from "vuex";
import { computed, onMounted, watch } from "vue";

const store = useStore();

const defaultNoImgWebp =
  "https://ixirlife.aybitcdn.com/AybitIxirlife/SaaSBireysel/Image/NoImage.webp";

onMounted(() => {
  const $jq = jQuery.noConflict();
  if ($jq(".service-carouselindex").length > 0) {
    $jq(document).ready(function() {
      $jq(".service-carouselindex").slick({
        dots: true,
        autoplay: false,
        // infinite: true,
        infinite: false,
        prevArrow: false,
        nextArrow: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  }
  setTimeout(() => {
    loadedPC ? onCalculatedWidth() : "";
  }, 300);
});

const loadedPC = computed(() => {
  return store.state["customercompany"].loadedPC;
});
const popularCompanylist = computed(() => {
  return store.state["customercompany"].popularCompanies;
});

watch(loadedPC, (newVal) => {
  newVal
    ? setTimeout(() => {
        onCalculatedWidth();
      }, 300)
    : "";
});

const getImg = (picture) => {
  return picture ?? defaultNoImgWebp;
};
const onCalculatedWidth = () => {
  let starWidth = [];
  starWidth = popularCompanylist.value.map((rating) => {
    return {
      width: `${(rating.rating / 5) * 100}%`,
    };
  });

  const starInners = document.querySelectorAll(".stars-inner");
  const starOuters = document.querySelectorAll(".stars-outer .stars-inner");

  starInners.forEach((inner, index) => {
    inner.style.width = starWidth[index].width;
  });
  starOuters.forEach((outer, index) => {
    outer.innerHTML = popularCompanylist.value[index].rating;
  });
};
</script>

<template>
  <!-- Popular Servides -->
  <section class="popular-services">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-md-6">
              <div class="heading aos" data-aos="fade-up">
                <h2>Popüler Salonlar</h2>
                <span>En popüler salonlar arasından seçimini yap</span>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="viewall aos" data-aos="fade-up">
                <h4>
                  <router-link to="/categories"
                    >Tümü <i class="fas fa-angle-right"></i
                  ></router-link>
                </h4>
                <span>Firmalar</span>
              </div>
            </div> -->
          </div>
          <div class="service-carouselindex slider">
            <div
              class="service-slider aos"
              data-aos="fade-up"
              v-for="popularCompany in popularCompanylist"
              :key="`pc-${popularCompany.id}`"
            >
              <div class="service-widget">
                <div class="service-img">
                  <router-link
                    :to="{
                      name: 'service-details-slug',
                      params: { slug: popularCompany.slug },
                      // params: { guid: popularCompany.companyGuid },
                    }"
                    @click="
                      store.commit('customercompany/company', popularCompany)
                    "
                  >
                    <img
                      class="img-fluid serv-img"
                      alt="Service Image"
                      :src="popularCompany.mainImg?.picture ?? defaultNoImgWebp"
                    />
                  </router-link>
                  <div class="loc-btn">
                    <!-- <a href="javascript:void(0)" class="loc-icon"> -->
                    <div class="loc-icon px-2">
                      <!-- <i class="fas fa-heart"></i> -->
                      <span
                        class="material-icons-outlined color-white pe-2"
                        style="font-size: 20px;"
                      >
                        place
                      </span>
                      <span class="pe-1" style="font-size: 16px;">
                        {{ popularCompany.cityName }}
                      </span>
                    </div>
                  </div>
                  <!-- <div class="fav-btn">
                    <a href="javascript:void(0)" class="fav-icon">
                      <i class="fas fa-heart"></i>
                    </a>
                  </div> -->
                  <div class="item-info">
                    <!-- <div class="service-user">
                      <a href="javascript:void(0);">
                        <img
                          :src="
                            getImg(
                              popularCompany.mainImg?.picture ?? defaultNoImgWebp
                            )
                          "
                          alt=""
                        />
                      </a>
                      <span class="service-price">{{
                        popularCompany.rate
                      }}</span>
                    </div> -->
                    <!-- <div id="wrapper">
                      <router-link
                        id="inner1"
                        class="bg-light- me-2"
                        :to="{
                          name: 'service-details-guid',
                          params: { guid: popularCompany.companyGuid },
                        }"
                        v-for="(category, index) in popularCompany.categoryList"
                        :key="index"
                        >{{ category }}</router-link
                      >
                    </div> -->
                    <div class="cate-list">
                      <router-link
                        class="bg-light-blue me-2"
                        :to="{
                          name: 'service-details-slug',
                          params: { slug: popularCompany.slug },
                          // params: { guid: popularCompany.companyGuid },
                        }"
                        v-for="(category, index) in popularCompany.categoryList"
                        :key="index"
                        >{{ category }}</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="service-content">
                  <h3 class="title">
                    <router-link
                      :to="{
                        name: 'service-details-slug',
                        params: { slug: popularCompany.slug },
                        // params: { guid: popularCompany.companyGuid },
                      }"
                    >
                      {{ popularCompany.companyName }}
                    </router-link>
                  </h3>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <!-- <div class="rating mapgridrating1">
                      <i
                        class="fas fa-star"
                        v-for="index in 5"
                        :class="{ filled: index <= popularCompany.rating }"
                      ></i>
                      <span class="d-inline-block average-rating">{{
                        popularCompany.rating
                      }}</span>
                    </div> -->
                    <div class="d-flex company-comment-text pb-0 pt-0 me-4">
                      <div class="rating mapgridrating1">
                        <i style="font-style: normal;" class="me-2">
                          <div class="stars-outer">
                            <div class="stars-inner" style="width: 100%;"></div>
                          </div>
                        </i>
                      </div>
                      <span>{{ popularCompany.rating }}</span>
                    </div>

                    <div class="user-info">
                      <div class="row">
                        <!-- <span class="col-auto ser-contact"> 
                          <span>{{ popularCompany.phoneList[0]?.phone }}</span>
                          <span
                            class="material-icons-round color-black ms-2"
                            v-if="popularCompany.phoneList.length"
                          >
                            phone_enabled
                          </span>
                        </span> -->

                        <!-- <span class="col ser-location">
                          <span>{{ popularCompany.name2 }}</span>
                          <i class="fas fa-map-marker-alt ms-1"></i>
                        </span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Popular Servides -->
</template>

<style>
.main-wrapper .popular-services .slick-slider .slick-list .slick-track {
  width: 2000px !important;
}
</style>

<style scoped>
#wrapper {
  overflow: hidden;
  width: 100%;
  height: 26px;
  background: #fa6558;
}

#inner1 {
  width: max-content;
  height: 26px;
  float: left;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding: 2px 15px;
  gap: 10px;
  font-size: 13px;
  font-weight: normal;
  color: #fff;
  background: #1da1f2;
  border-radius: 20px;
}

#inner2 {
  width: max-content;
  height: 26px;
  float: left;
  background: red;
}
</style>
