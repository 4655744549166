import Swal from "sweetalert2";
import { reactive } from "vue";
import {
  getCities,
  getCountiesOfCities,
  getTowns,
} from "../../api/companycity.service";

const defaultCityState = () => {
  return {};
};
const defaultTownState = () => {
  return {};
};

export const state = reactive({
  loadedCities: false,
  loadedTowns: false,
  // companyCityOperationsModal: false,
  // companyCitySaved: false,
  allCities: [],
  countiesOfCities: [],
  allCounties: [],
  city: defaultCityState(),
  county: defaultTownState(),
});

export const actions = {
  async getCities({ commit }, data) {
    const responseObj = await getCities(data);

    commit("allCities", responseObj);
    commit("loadedCities", responseObj.length >= 0);

    return responseObj;
  },

  async getCountiesOfCities({ commit }, data) {
    const responseObj = await getCountiesOfCities(data);

    commit("countiesOfCities", responseObj);
    commit("loadedCities", responseObj.length >= 0);

    return responseObj;
  },

  async getTowns({ commit }, data) {
    const responseObj = await getTowns(data);

    commit("allCounties", responseObj);
    commit("loadedTowns", responseObj.length >= 0);

    return responseObj;
  },
};

export const mutations = {
  loadedCities(state, data) {
    state.loadedCities = data;
  },
  loadedTowns(state, data) {
    state.loadedTowns = data;
  },
  // companyCitySaved(state, data) {
  //   state.companyCitySaved = data;
  // },
  // companyCityOperationsModal(state, data) {
  //   state.companyCityOperationsModal = data;
  // },
  city(state, data) {
    state.city = data;
  },
  county(state, data) {
    state.county = data;
  },
  allCities(state, data) {
    state.allCities = data;
  },
  countiesOfCities(state, data) {
    state.countiesOfCities = data;
  },
  allCounties(state, data) {
    state.allCounties = data;
  },
  companySubCategories(state, data) {
    state.companySubCategories = data;
  },
  resetCompany(state) {
    state.city = defaultCityState();
    state.county = defaultTownState();
  },
};
